import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text} from 'react-native';
import Modal from "../../../assets/component/modal";
import ModalDebit from "./modal/debits";
import ModalCredit from "./modal/credits";
import Button from "../../../assets/component/button";
import COLORS from "../../../assets/component/colors";



export default function Footer(props) {

    const {add,tab,current} = props


    const [modalVisible, setModalVisible] = useState(false);




    function Success(data){
        add(data)
        setModalVisible(false)
    }





    const Total = () => {
        if(!current){return <View style={{flex:1}}/>}
        var total = tab == 'debit'?current.total_debits:current.total_credits
        if(!total){
            total = 0
        }
        return (
            <View style={{flex:1}}>
                <View style={{flexDirection:'row',alignItems:'baseline'}}><Text>Total : </Text><Text style={{fontSize:16,fontWeight:'bold',color:tab == 'credit'?COLORS.Primary:'#426ff5'}}>RM{numberWithCommas(total.toFixed(2))}</Text></View>
                <Text style={{fontSize:12}}>12 transcation</Text>
            </View>
        )
    }

    
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }





    const Popup = () => {
        if(tab == 'debit'){
            return (
                <Modal title={'Add Debit'} icon={'cash'} visible={modalVisible} setVisible={setModalVisible} headColor={'#426ff5'}>
                    <ModalDebit success={Success}/>
                </Modal>
            )
        }
        return (
                <Modal title={'Add Credit'} icon={'ios-receipt'} visible={modalVisible} setVisible={setModalVisible} headColor={COLORS.Primary}>
                    <ModalCredit success={Success}/>
                </Modal>
        )
    }
    


    return(
        <View style={styles.container}>
                <Total/>
                <Button title={'ADD '+tab.toUpperCase()} icon={'md-add-circle'} onPress={() => setModalVisible(true)} color={tab == 'credit'?null:'#426ff5'}/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        padding:5,
        flexDirection:'row',
        backgroundColor:'#eee'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },

    orderSelect:{
        flexDirection:'row',
        borderWidth:1,
        borderRadius:4,
        padding:5,
        borderColor:'#ddd',
        shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
    },
    item:{
        fontSize:14
    }
  });
  