import React from "react";
import { Page, Text, Image, Document, StyleSheet,View ,Font} from "@react-pdf/renderer";
import moment from 'moment';
import Helvetica from "../../../../assets/fonts/Helvetica.ttf";

export default function PdfStatement(props) {

  const {user,details,month,type} = props


  
  Font.register({ family: 'Helvetica', src: Helvetica });




  const Address = () => {
    return  (
      <View style={styles.address}>
              <Text style={{color:'#111',fontWeight:'700',fontSize:12}}>{user.name}</Text>
              <Text style={styles.stext}>({user.ssm})</Text>
              <Text style={styles.text}>E-01-09 Jalan SM2</Text>
              <Text style={styles.text}>Taman Subang Mas 47620,</Text>
              <Text style={styles.text}>Subang Jaya,Selangor</Text>
              {Additional('website',user.website)}
              {Additional('H/P',user.tel)}
      </View>
    )
  }

  const Additional = (val,data) => {
    if(!data) return null
    return <Text style={styles.text}>{val} : {data}</Text>
  }

  const TableTh = () => {
    return(
      <View style={{flexDirection:'row',borderBottomWidth:0.5,borderTopWidth:0.5,paddingVertical:5}}>
        <View style={[{flex:1}]}><Text style={styles.text}>NO</Text></View>
        <View style={[{flex:1}]}><Text style={styles.text}>ID</Text></View>
        <View style={[{width:70}]}><Text style={styles.text}>DATE</Text></View>
        <View style={{flex:6}}><Text style={styles.text}>DESCRIPTION</Text></View>
        <View style={[styles.end,{flex:2}]}><Text style={styles.text}>AMOUNT</Text></View>
      </View>
    )
  }


  const ListItem = (data) => {

    const CREDIT = type == 'credit'?true:false
    

    return(
        <View style={{paddingVertical:5}}>
            {data.map((item,key) => {

              const Amount  = parseFloat(item.amount)
              const Name = CREDIT?item.category.name:item.name
              const Category = CREDIT && " ("+item.category.category+")"

              return (
                <View key={key} style={{flexDirection:'row',marginBottom:5}}>
                  <View style={[{flex:1}]}><Text style={styles.text}>{key+1}</Text></View>
                  <View style={[{flex:1}]}><Text style={styles.text}>{item.id}</Text></View>
                  <View style={[{width:70}]}><Text style={styles.text}>{ReformatTime(item.date)}</Text></View>
                  <View style={{flex:6,flexDirection:'row'}}><Text style={styles.text}>{Name+' '}</Text><Text style={styles.ltext}>{Category}</Text></View>
                  <View style={[styles.end,{flex:2}]}><Text style={styles.text}>RM{Amount.toFixed(2)}</Text></View>
                </View>
              ); 
            })}
            {Remark(details.remark)}
        </View>
    )
}

const Remark = (data) => {
  if(!data) return null
  return(
    <View style={{flexDirection:'row',marginTop:10}}>
          <View style={{borderWidth:1,padding:5,borderRadius:5,borderStyle:'dashed'}}>
      <Text style={{fontSize:10}}>Remark : {details.remark}</Text>
    </View>

    </View>
  )
}


const BillSum = (Amount) => {

  return(
    <View style={{flexDirection:'row',marginLeft:20}}>
      <View style={{marginRight:15}}>
        <Text style={[styles.btext,{marginTop:10,paddingVertical:5,}]}>SUBTOTAL</Text>
      </View>
      <View>
        <View style={styles.pricebx}>
            <Text style={[styles.btext]}> RM{Amount.toFixed(2)}</Text>
        </View>
      </View>
    </View>
  )
}



const ReformatTime = (data) => {
  if(!data) return null
  return moment(data.toDate()).format('DD-MM-YYYY');
}




const TotalSum = details.reduce((n, {amount}) => n + parseFloat(amount), 0);

  return (
    <Document>
      <Page size="A4" style={styles.body}>

          <View style={styles.header}>
            <Image style={styles.image}  source={{ uri: user.logo}} />
            <View style={{flex:1}}/>
            {Address()}
          </View>

          <View style={[{marginBottom:20}]}><Text style={{fontSize:12,fontWeight:700}}>{type.toUpperCase()+' '+month['from']+' - '+month['to']}</Text></View>

          {TableTh()}
          <View style={{borderBottomWidth:0.5,paddingBottom:10,minHeight:250}}>
              {ListItem(details)}
          </View>

          <View style={{flexDirection:'row',marginTop:15}}>
            <View style={{flex:1}}/>
            {BillSum(TotalSum)}
          </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width:'50%'
  },
  address:{
    alignItems:'flex-start',
  },
  text: {
    fontSize: 10,
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:3
  },
  ltext: {
    fontSize: 10,
    fontFamily: "Helvetica",
    color:'#aaa',
    marginBottom:3
  },
  stext:{
    fontSize: 9,
    fontWeight:'200',
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:2
  },
  btext: {
    fontSize: 12,
    fontWeight:'200',
    fontFamily: "Helvetica",
    color:'#111',
  },
  pricebx:{
    borderTopWidth:0.5,
    borderBottomWidth:0.5,
    paddingVertical:5,
    marginTop:10
  },
  image: {
    width:100,
    height:100,
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center'
  },
  company:{
    flexDirection:'row',
    alignItems:'baseline'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#777",
  },
  end:{
    alignItems:'flex-end',
    flexDirection:'row',
    justifyContent:'flex-end',
    paddingHorizontal:10
  }
});
