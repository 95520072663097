import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,FlatList,TouchableOpacity } from 'react-native';
import TextInput from "../../../assets/component/textinput";
import Ionicons from '@expo/vector-icons/Ionicons';


export default function SearchInput(props) {

  const {set,data,value} = props

  const [credittype, setCreditType] = useState(null);
  const [suggest, setSuggest] = useState(false);

  useEffect(() => { 
    setCreditType(data)
  }, [data]);


  function Search(text){
    set(text)
    setSuggest(false)
    if(text){
      setSuggest(true)
      const newData = credittype['base'].filter(item => item.name.toLowerCase().includes(text.toLowerCase()))
      setCreditType({...credittype, ['active']:newData});
    }else{
      setCreditType({...credittype, ['active']:credittype['base']});
    }
  }

  const RenderItem = ({item,index}) => {
    item['paid'] = item.paid?item.paid:0
    return(
      <TouchableOpacity style={{flex:1,borderBottomWidth:1,padding:5,borderColor:'#ddd'}}  onPress={()=>[set({'name':item.name,'id':item.id,'category':item.category,'sub_category':item.sub_category}),setSuggest(false)]}>
            <Text style={{fontsize:16}}>{item.name}</Text>
            <View style={styles.catrow}>
              <Text style={styles.cat}>{item.category}</Text>
              <Ionicons name='chevron-forward' size={12} color={'#aaa'} />
              <Text style={styles.sub}>{item.sub_category}</Text>
            </View>
      </TouchableOpacity>
    )
}

  const CreditTypeList = (data) => {
    if(!suggest || !credittype['active'].length){return null}
    
    return (
        <FlatList
        style={{borderWidth:1,maxHeight:200,borderColor:'#ccc',backgroundColor:'#eee'}}
        showsHorizontalScrollIndicator={false}
        data={data['active']}
        renderItem={RenderItem}
        onEndReachedThreshold={0}
    />
    )
  }

  const SelectedDetails = (data) => {
    if(!data.name) return (
            <TextInput
                title={'Name'}
                required={true}
                placeholder='Search .....'
                placeholderTextColor="#ddd"
                onChangeText={(text)=>Search(text)}
                value={value.name?value.name:value} 
                style={styles.input} 
                />
    )
    
    return (
      <View style={styles.selected}>
        <View style={{flex:1}}>
            <Text style={{fontWeight:500}}>{data.name}</Text>
            <Text style={{fontSize:12}}>{data.category}</Text>
            <Text style={{fontSize:12,color:'#aaa'}}>{data.sub_category}</Text>
        </View>
        <TouchableOpacity style={{padding:15}} onPress={()=>{set('')}}>
          <Text>X</Text>
        </TouchableOpacity>
      </View>
    )

  }

  if(!credittype){
    return null
  }


    return(
      <View>
        {SelectedDetails(value)}
        {CreditTypeList(credittype)}
      </View>
    )

}

const styles = StyleSheet.create({

  selected:{
    flexDirection:'row',
    padding:5,
    borderWidth:1,
    borderColor:'#eee',
    marginBottom:5,
    borderRadius:5
  },
  cat:{
    fontSize:12,
    color:'#0076a8',
    marginRight:3
  },
  sub:{
    fontSize:12,
    color:'#aaa',
    marginLeft:3
  },
  catrow:{
    flexDirection:'row',
    alignItems:'center',
    alignContent:'center'
  }

});
