import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,Dimensions, ActivityIndicator} from 'react-native';
import {GetReportList,GetReports,setReport,GetBackupFromServer} from "../../../assets/js/firebase";
import { FontAwesome } from "@expo/vector-icons";
import moment from 'moment';
import {YearSelector} from '../../../assets/component/momentselect';
import Table from './table';
import {LineChart} from "react-native-chart-kit";
import { TouchableOpacity } from 'react-native-gesture-handler';
import COLORS from '../../../assets/component/colors';

export default function AccountScreen() {

  const screenWidth = Dimensions.get("window").width;
  const [list, setList] = useState([]);

  const [year, setYear] = useState(moment().format("YYYY"));
  const [isLoading, setIsloading] = useState(false);

  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);


  useEffect(() => {
    if(year){
      setIsloading(true)
      GetReport(year)
    }

  }, [year]);


  async function GetReport(year){
    const GetData = await CheckUptodate(year)

    if(GetData){
            var reportas = {}
            const Types = ['credits','debits']
            Types.forEach(async(obj)=>{
              if(GetData[obj])
                var SumMonth = GetData[obj].reduce((object, item) => {
                  var category = moment.unix(item.date.seconds).format("MMM");
                  var amount = parseFloat(item.amount);
                  if (!object.hasOwnProperty(category)) {
                    object[category] = 0;
                  }
                  object[category] += amount;
                  return object;
                }, {});
                reportas[obj] = SumMonth
            })

            
            var reports = []
            moment.monthsShort('es').map((i) => {
              const credit = reportas.credits[i]?reportas.credits[i]:0
              const debit = reportas.debits[i]?reportas.debits[i]:0

              reports.push({month:i,'credits':credit,'debits':debit})
            })

            setList(reports);
            setTotalDebit(reports.reduce((sum, { debits }) => sum + debits, 0))
            setTotalCredit(reports.reduce((sum, { credits }) => sum + credits, 0))
    }

  };
  
  const CheckUptodate = async (data) => {

    const Reports = await GetReports(data)
    if(!Reports) return null

    const CreditReport = await CheckLatest(Reports,'credits')
    const DebitReport = await CheckLatest(Reports,'debits')
    const FullReport = {credits:CreditReport,debits:DebitReport}
    return FullReport

};

const CheckLatest = async (Reports,a) => {
    setIsloading(true)
    if((Reports['last_generated_'+a] && Reports['last_generated_'+a].seconds) > (Reports['last_updated_'+a] ? Reports['last_updated_'+a].seconds : 0)){
      const URL = await GetBackupFromServer(year,a)
      const Downloaded = URL?await getArticlesFromApi(URL): GetReportList(year,a);
      return Downloaded
    }else{
      const ReportGen = await  GetReportList(year,a)
      setIsloading(false)
      return ReportGen
    }
}


  const getArticlesFromApi = async (url) => {
    try {
      let response = await fetch(url)
      let json = await response.json();
      setIsloading(false)
      return json;
    } catch (error) {
      setIsloading(false)
       console.error(error);
    }
  };
  

  function kFormatter(num) {

    return Math.trunc((num)/1000)

  }


  const MyLineChart = () => {

    if(!list) return null

    var credit = []
    var debit = []
    list.map((i) => {
      credit.push(kFormatter(i.credits))
      debit.push(kFormatter(i.debits))
    })

    const data33 = [
      {
             data: credit,
             strokeWidth: 1,
             color: (opacity = 0.8) => 'red',
     },
     {
       data: debit,
       strokeWidth: 1,
       color: (opacity = 0.7) => 'blue',
     }
    ]

    const SizeMobile = screenWidth < 762 ? 20 : 200

   const BoxWidth = Dimensions.get('window').width - SizeMobile
   const BoxHeight = 160
    
    return (
      <View>
        {isLoading && <View style={[styles.graphLoader,{width:BoxWidth,height:BoxHeight}]}><ActivityIndicator size={'large'}/></View>}
        <LineChart
        withShadow={false}
          data={{
            labels: moment.monthsShort(),
            datasets: data33,
          }}
          width={BoxWidth}
          height={BoxHeight}
          yAxisLabel={'RM'}
          yAxisSuffix={"K"}
          chartConfig={{
            backgroundColor: '#1cc910',
            backgroundGradientFrom: '#eff3ff',
            backgroundGradientTo: '#efefef',
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            style: {
              borderRadius: 16,
            },
            decimalPlaces : 0,
            propsForDots: {
              r: "3",
              strokeWidth: "1",
              stroke: "#fff"
          }
            
          }}
          style={{
            marginVertical: 8,
            borderRadius: 5,
          }}
        />
        </View>

    );
  };


 const ChartDetails = (color,name) => {
  return(
    <View style={{flexDirection:'row',flex:1,alignContent:'center',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
      <FontAwesome name="circle" size={12} color={color} /><Text style={{marginLeft:5}}>{name}</Text>
    </View>
  )
 }

 const ReGenarate = () => {

  async function Gen() {
    setIsloading(true)
    await GetReportList(year,'credits')
    await GetReportList(year,'debits')
    setIsloading(false)
  }

  return (
    <TouchableOpacity style={[styles.flexcenter,styles.regen]} onPress={()=> Gen()}>
       <FontAwesome name="refresh" size={14} color={COLORS.Link} />
      <Text style={{color:COLORS.Link,marginLeft:5}}>Re-Generate</Text>
    </TouchableOpacity>
  )
 }




  return (
    <View style={styles.container}>
      <View style={{flexDirection:'row',zIndex:23}}>
          <View style={{flex:1}}/>
          <YearSelector setYear={setYear} year={year}/>
      </View>
      <View style={{flexDirection:'row'}}>
        {ChartDetails('red','Credit')}
        {ChartDetails('blue','Debit')}
      </View>
      {<MyLineChart/>}
      <Text>total Debit : RM{totalDebit.toFixed(2)}</Text>
      <Text>total Credit : RM{totalCredit.toFixed(2)}</Text>
      <ReGenarate/>
      <Table 
        list={list} 
        loading={isLoading}
      />




    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap',
    zIndex:10
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  graphLoader:{
    zIndex:22,
    position:'absolute',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    backgroundColor:'#aaa',
    opacity:0.4,
    borderRadius:16,
    marginTop:8
  },
  regen:{
    borderWidth:1,
    borderRadius:5,
    borderColor:'#eee',
    padding:5,
    marginHorizontal:5
  }
});
