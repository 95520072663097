import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert, ActivityIndicator,FlatList,Image} from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/Ionicons';
import COLORS from "../../../assets/component/colors";
import {GetStockList} from "../../../assets/js/firebase";
import SearchBox from "../../../assets/component/searchbox";
import Category from './category'

export default function ProductList(props) {

    const {setValue,selected,products} = props

    const [isLoading, setIsloading] = useState(false);
    const [productslist, setProductsList] = useState(null);


    useEffect(() => {
      if(products){
            getProductList()
      }
    }, [products]);


    async function getProductList(){

        const StockList = await GetStockList()

        const NewStock = []
          
        StockList.map(item => {
            const obj = products.find(o => o.id === item.product );
            if(obj){
            NewStock.push( {'stock':item.stock,'id':item.id,'pid':item.product,'amount':item.selling ,'name' :obj.name,'type' :obj.type,'createdAt':item.createdAt  })
            }
          });

        var filteredArray = products.filter((item) => item['type'] !== 'stock');

        setProductsList([...NewStock, ...filteredArray])
        setIsloading(false)
    };





    function ChooseProduct(data){
        data['qty'] = 1
        if(data.type == 'stock'){
          setValue(
            selected => selected.some(item => item.id == data.id)
              ? selected.map(item => item.id == data.id
                  ? { ...item, 'qty': (data.stock > item.qty) ? (item.qty + 1) : item.qty } 
                  : item 
                )
              : [data, ...selected]
          )
        }else{
          setValue([...selected, data]);
        }
    }

    const NostockItem = ({item,index}) => {
      return (
        <View style={[styles.products,{opacity:0.5}]} key={index}>
                <View style={{flex:1}}>
                    <Text style={[styles.item]}>{item.name}</Text>
                    <Text style={[styles.item,{color:'#4287f5',fontSize:12}]}>#{item.id}</Text>
                </View>
                <Text style={[styles.item,{width:80,fontWeight:'600'}]}>NO STOCK</Text>
          </View>
      )
    }

    const Stocks = (data) => {
      if(!data.stock) return null
      return (
        <View style={{flexDirection:'row'}}><Text style={[styles.small,{color:'#aaa'}]}>Stock : </Text><Text style={[styles.small,{color:'#4287f5'}]}>{data.stock}</Text></View>
      )
    }

    const ReformatTime = (data) => {
      if(!data) return null
      const format = moment(data.toDate()).format('DD-MM-YYYY HH:MM A');
      return format
  }


    const RenderItem = ({item,index}) => {

      if(item.type != 'service' && !item.stock || item.stock < 1) return NostockItem({item,index})

      return(
        <TouchableOpacity style={styles.products} key={index}  onPress={()=>ChooseProduct(item)}>
              <View style={styles.Icon}>{Icon(item)}</View>
              <View style={{flex:1,marginRight:5}}>
                  <Text style={[styles.item,{color:item.type == 'service'?'#009688':'#444'}]}>{item.name}</Text>
                  {Stocks(item)}
                  <Text style={{fontSize:12,color:'#aaa'}}>{ReformatTime(item.createdAt)}</Text>
              </View>
              <Text style={[styles.item,{width:80,fontWeight:'600'}]}>RM{parseFloat(item.amount).toFixed(2)}</Text>

        </TouchableOpacity>
      )
  }

  const Icon = (item) => {
    if(item.type != 'service') return ProductIcon(item)
    return (
      <View style={styles.user}><Ionicons name="construct-outline" size={28} color="#ccc" /></View>
    )
  }

  const ProductIcon = (item) => {
    const found = products.find(x => x.id === item.pid);
    if(!found) return null
    return (
      <Image style={styles.ProductImg} source={{uri: found.image}}/>
    )
  }
  

    if(!productslist || isLoading ) return (
      <ActivityIndicator size={'small'} color={COLORS.Primary}/>
    )
    


    return (
      <View>
          <SearchBox set={setProductsList} products={productslist} filters={['name', 'id']}/>
          <Category set={setProductsList} products={productslist}/>
          <FlatList
          showsHorizontalScrollIndicator={false}
          data={productslist}
          renderItem={RenderItem}
          onEndReachedThreshold={0}
            />
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
      flexDirection:'row',
      flexGrow:1,
    },
      th:{
        fontWeight:'600',
        color:COLORS.Primary
      },
      products:{
        borderBottomWidth:1,
        paddingVertical:10,
        borderColor:'#ccc',
        flexDirection:'row'
      },
      small:{
        fontSize:12,
        fontWeight:'200'
      },
      Icon:{
        width:70,
        alignContent:'center',
        alignItems:'center'
      },
      ProductImg: {
        width: 50,
        height: 50,
      },
  });
  