import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,Text,View,Linking } from 'react-native';
import {getAuth} from 'firebase/auth';
import Tab from './tab';
import Table from './table';
import Types from './types';
import Cat_List from './categorised'
import {SearchBox,Button,Link} from "../../../assets/component";
import {GetDelivers,DeliveryPriority,CURRENT_ACC} from "../../../assets/js/firebase";


export default function ShipmentScreen({route,navigation}) {

  const auth = getAuth();
  const user = auth.currentUser;

  const LimitPerload = 30

  const isMounted = useRef();


  const DafaultTab = {pending:[],pickup:[],delivered:[]}

  const Cat = ['All','State','Driver']

  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [lastVisible , setLastVisible ] = useState(null);
  const [picked, setPicked] = useState([]);
  const [statusloading, setStatusloading] = useState(false);
  const [activeType, SetActive] = useState(Cat[0]);
  const [account, setAccount] = useState(null);

  useEffect(() => {
      isMounted.current = true;
      if (isMounted.current) {
        if(list[tabactive].length == 0){
          setIsloading(true)
          getList(tabactive);
        }
      }
      return () => {
        isMounted.current = false;
      };
    }, [tabactive]);

    useEffect(() => {
      GetAccount()
      }, []);


    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }



  async function getList(active){

    var ListOrders = await GetDelivers('delivery',active,LimitPerload)

    if(isMounted.current){
      setLastVisible({...lastVisible, [active]:ListOrders.last});
      setList({...list, [active]:ListOrders.list});
      setIsloading(false)

    }

  };




  async function nextList(active,last){

      if(!last[active]) return null

      const ListOrders = await GetDelivers('delivery',active,LimitPerload,last[active])

      if(ListOrders){
        console.log("load More")
        setLastVisible({...lastVisible, [active]:ListOrders.last});

        const dataArray = [ ...list[active], ...ListOrders.list]
  
        setList({...list, [active]:dataArray});
        setIsloading(false)
      }
    
  };

  async function Priority(data,priority){
    setStatusloading(true)
    await DeliveryPriority(data,priority)
    UpdateStatus(data,priority)
  }

  function UpdateStatus(data,priority){
    const New = list['pending'].map(function(x){ 
      var result=data.filter(a1=> a1.id==x.id);
      if(result.length>0) { x.priority=priority;}
      return x })

      

      const dataArray = [ ...New]
      
      var sorted = dataArray.sort((a,b) => {
          if(b.priority === undefined) return -1;
          if (a.priority < b.priority) return 1;
          if (a.priority > b.priority) return -1;
          return 0;
      })
      setList({...list, ['pending']:sorted});
      setPicked([])
  }


  

  const Footer = () => {
    if(!picked.length) return null

    const prior = picked[0].priority == 1 ? 0 : 1
  
    return (
      <View style={{flexDirection:'row',padding:10,backgroundColor:'#ffeacd',position:'absolute',width:'100%',bottom:0}}>
        <View style={{flex:1}}>
            <View><Text>TOTAL : {picked.length}</Text></View>
            <Link onPress={()=>setPicked([])}>Clear All</Link>
        </View>
        <Button title={'Whatsapp'} onPress={()=> WhatsappLink(picked)} color={'#25D366'} style={{marginRight:10}} icon={'logo-whatsapp'}/>
        <Button title={prior !== 1 ? 'Normal' : 'Priority'} onPress={()=> Priority(picked,prior)} color={'#fc3063'} style={{marginRight:10}} icon={'return-up-back'}/>
      </View>
    )
  }

  function WhatsappLink(data){

    var Whatsapp = '*Delivery Notification*'+'%0a'

    Whatsapp += 'Total : *'+data.length +'*%0a%0a'

    data.map((val,i)=>{
      console.log(val)

      const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+val.id+'/'+val.mobile.slice(2)
      Whatsapp += (i+1)+'. 📍'+val.delivery+' '+"📞 *Mobile* : "+val.customer_mobile.substring(2)+' '+" *Name* : "+val.customer_name+' *DO*  : `' +Link+'` %0a%0a'

    })
      Whatsapp += '%0a'+'> Please sign all DO with photo of proof on the link for each order'

    Linking.openURL('whatsapp://send?text='+Whatsapp+'&phone='+data[0].mobile)
  }



  const DisplayList = () => {


    if(activeType !== 'All'){
      return <Cat_List 
              data={list[tabactive]}
              category={activeType}
              setPicked={setPicked}
              picked={picked}
            />
    } 
    

    return (
      <Table 
      tab={tabactive} 
      setList={setList} 
      list={list} 
      next={()=>nextList(tabactive,lastVisible)} 
      loading={isLoading}
      setPicked={setPicked}
      statusloading={statusloading}
      picked={picked}
      />
    )

  }
  




  return (
    <View style={styles.container}>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} />
        <View style={{flex:1}}/>
        <View style={{width:150}}>{!isLoading &&<SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['id','name','delivery']}/>}</View>
        
      </View>
      <Types active={activeType} types={Cat} set={SetActive}/>
      {DisplayList()}
      <Footer/>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
});
