import React, { Component,useState } from "react";
import {StyleSheet,TouchableOpacity} from "react-native";
import COLORS from "./colors";
import {Ionicons} from '@expo/vector-icons';


export default function CheckBox(props) {

    const {value,onValueChange} = props;
    


      return (
          <TouchableOpacity style={styles.tickbox} onPress={()=>onValueChange()}>
              {value && <Ionicons name='checkmark' size={18} color={COLORS.Link} />}
          </TouchableOpacity>
      )

    }



    const styles = StyleSheet.create({
      tickbox:{
        borderWidth:1,
        borderColor:'#aaa',
        borderRadius:2,
        width:20,
        height:20,
        marginHorizontal:5,
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center'
      },
    
});

  