import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert, ActivityIndicator} from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import { Timestamp,serverTimestamp,increment,orderBy,limit,where,arrayUnion,arrayRemove } from 'firebase/firestore';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../assets/component/textinput";
import Modal from "../../assets/component/modal";
import Button from "../../assets/component/button";
import ListDown from "../../assets/component/listdown";
import COLORS from "../../assets/component/colors";
import ImagePicker from "../../assets/component/imagepicker";
import {AddProduct,updateImageLink} from "../../assets/js/firebase";
import {UploadData} from "../../assets/js/upload";


export default function AddNew(props) {

    const {add} = props


    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [name, onName] = useState("");
    const [desc, onDesc] = useState("");
    const [amount, onAmount] = useState("");
    const [type, onType] = useState("stock");
    const [error, setError] = useState("");

    const [file, setFile] = useState("");

    
    useEffect(() => {
        if(modalVisible){
            setFile("")
            onAmount("")
            onName("")
        }

    }, [modalVisible]);



    async function Validate(){
        setError('')
        var valid = true;
        if(!name || name.error){
            onName({error:"Invalid Name"});
            valid=false
        }

        if(!desc || desc.error){
            onDesc({error:"Invalid Description"});
            valid=false
        }

        if(type == 'service'){
            if(!amount || amount.error){
                onAmount({error:"Invalid Amount"});
                valid=false
            }
        }

        if(valid){
                setIsloading(true)
                const AddStatus = await AddProduct({'name':name,'desc':desc,'type':type,'amount':amount});
                if(AddStatus){

                    if(file && type == 'stock'){
                        const Route = 'products'
                        const UploadedURL  = await UploadData(AddStatus.id,file,Route)
                        await updateImageLink(Route+'/'+AddStatus.id,UploadedURL)
                        AddStatus['image'] = UploadedURL
                    }
                    

                    Success(AddStatus)
                }
        }

    }



    function Success(data){
        setIsloading(false)
        add(data)
        onName("")
        onDesc("")
        setModalVisible(false)
    }



    function ValidatePhone(Input) {
        if(Input){
          if( /^\d+$/.test(Input) &&  ( (Input.charAt(0) === '0' && Input.charAt(1) === '1' && Input.length >= 10 && Input.length <= 11) || (Input.charAt(0) === '1' && Input.length >= 9 && Input.length <= 10) )  ){
            if(Input.charAt(0) === '1'){ Input = '0' + Input;}
            return '+6'+Input;
          } 
        }
          return null;
    }

    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }



    const Popup = () => {
        return (
            <Modal title={'Add Credit'} icon={'ios-receipt'} visible={modalVisible} setVisible={setModalVisible}>
                        <View >
                                <TextInput
                                    onChangeText={onName}
                                    maxLength={60}
                                    title="Product Name"
                                    value={name}
                                    autoCapitalize="none"
                                    errclear={true}
                                />
                                <TextInput
                                    onChangeText={onDesc}
                                    maxLength={60}
                                    title="Product Description"
                                    value={desc}
                                    autoCapitalize="none"
                                    errclear={true}
                                />
                                {type == 'service'?
                                <TextInput
                                        onChangeText={onAmount}
                                        maxLength={60}
                                        title="Amount"
                                        value={amount}
                                        errclear={true}
                                />:<ImagePicker set={setFile} value={file}  resize={[{ resize: { width: 100, height: 100 } }]} accept="image/*"/>}
                                <ListDown list={['stock','service']} active={type} onPress={onType}/>
                                <Error error={error}/>
                                {!isLoading?<Button title={'ADD'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
                        </View>
                </Modal>
        )
    }
    


    return(
        <View style={styles.container}>
                <View style={{flex:1}}/>
                <Button title={'ADD PRODUCT'} icon={'md-add-circle'} onPress={() => setModalVisible(true)} color={'#00b809'}/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      }
  });
  