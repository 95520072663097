import AsyncStorage from '@react-native-async-storage/async-storage'



class Storage {
    
    getData = async (val) => {
      try {
        const value = await AsyncStorage.getItem(val)
        if(value !== null) {
          return value
        }
      } catch(e) {
        // error reading value
      }
      return null
    }

    storeData = async (key,value) => {
      try {
        await AsyncStorage.setItem(key, value)
        return true
      } catch (e) {
       console.log(e)
      }
      return false
    }

    pushData = async (key,value) => {
    
      try {
        var data = await this.getData(key)
        newdata = data?JSON.parse(data):[]
        newdata.push(value)
        return await this.storeData(key, JSON.stringify(newdata))
      } catch (e) {
       console.log(e)
      }
      return false
    }

    RemoveData = async (key) => {
      try {
        await AsyncStorage.removeItem(key);
      } catch (error) {
        console.log("Asyncstorage error clear data")
        // Error saving data
      }
    }

    RemoveAll = async () => {
      try {
        await AsyncStorage.clear();
      } catch (error) {
        console.log("Remove Data error : "+error)
        // Error saving data
      }
    }

  }


  const storage = new Storage();
  export default storage;





