import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator,Linking,Image} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {Copy,Modal,Button,TextInput} from "../../../assets/component";
import COLORS from "../../../assets/component/colors";
import {UpdateDriver,CURRENT_ACC,GetAllDocs} from "../../../assets/js/firebase";
import Searchinput from './search';

export default function DeliveryModal(props) {

    const {clear,data,update,type} = props


    const [isLoading, setIsloading] = useState(false);
    const [values,setValues] = useState({'driver_id':'','name':'','mobile':'','address':'','remark':''})
    const [account, setAccount] = useState(null);
    const [transporterList, setTransporterList] = useState([]);

    useEffect(() => {
      GetAccount()
      getTransporters()
    }, []);


    useEffect(() => {
      setValues({'driver_id':'','name':'','mobile':'','address':'','remark':''})
      if(data){
          if(data.delivery){
            changeHandler(data.delivery,'address')
          }else{
            const Address = data.address?data.address:''
            changeHandler(Address,'address')
          }
          if(data.driver){
              changeHandler(data.driver.id,'driver_id')
              changeHandler(data.driver.name,'name')
              changeHandler(data.driver.mobile,'mobile')
          }
          data.remark && changeHandler(data.remark,'remark')
      }
    }, [data]);


    async function getTransporters(){

      const DataList  = await GetAllDocs('transporter_list')


      setTransporterList(DataList);
    };

    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }

    function changeHandler (val,type){
      setValues( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }

    function ShopeeFormat(data){
      var regex = /\b['6']\d{8,12}/
      let output = data.match(regex)
      if(!output || !output.length){ return null }
      const Mobile = output && ValidatePhone(output[0],'mobile')
      const AfterMobile = data.split(output[0]).pop().trim();
      const BeforeMobile = data.split(output[0]).shift().replaceAll(',', '').trim()
      return {'name':BeforeMobile,'mobile':Mobile,'address':AfterMobile}
    }




    async function Validate(){

        const validName = Validation(values.name,'name');

        const validMobile = ValidatePhone(values.mobile,'mobile')

        const ValidAddress = Validation(values.address,'address');

        if(validName && validMobile && ValidAddress){

          setIsloading(true)

          const NewData = {}
          const AddNewDriver = data.driver?false:true;
          NewData['driver'] = {
            'name':validName,
            'mobile':validMobile,
            'id':values.driver_id?values.driver_id:null,
            'in-house':values['in-house']?true:false
          }
          NewData['remark'] = values.remark
          NewData['delivery'] = ValidAddress
          if(type == 'online_order'){
              if(ValidAddress){
                    const Formatted = ShopeeFormat(ValidAddress)

                    if(Formatted){
                      data['name'] = Formatted.name
                      NewData['name'] = Formatted.name
                      NewData['mobile'] = Formatted.mobile
                      NewData['delivery'] = Formatted.address
                      NewData['delivery_status'] = data.delivery_status?data.delivery_status:[]
                    }else{
                      NewData['delivery_status'] = data.delivery_status
                      console.log("Shopee Order ID Format Can Capture Mobile And Name Address")
                    }
              }
          }else{
            NewData['delivery_status'] = data.delivery_status?data.delivery_status:[]
          }
          const AddedDriver = await UpdateDriver(data,'pending',NewData,type,AddNewDriver)
          if(AddedDriver){
            let merged = {...data,...NewData};
            update(merged)
            UpdateTransporter(AddedDriver)
          }
          ClearState()
        }

    }

    function UpdateTransporter(data){
      const NewArray = [...transporterList]
      const found = NewArray.some(el => el.id === data.id);
      if (!found) NewArray.push(data);
      setTransporterList(NewArray)
    }

    function Validation(input,change){
        if(!input || input.error){
          changeHandler({error:"Invalid Value"},change)
          return null
        }
        return input
    }

    function ValidatePhone(data,change) {
        const Input = !data.error && data ? data.replace(/\D/g,'').replace('6','') : null;
        if(Input){
          if( /^\d+$/.test(Input) &&  ( (Input.charAt(0) === '0' && Input.charAt(1) === '1' && Input.length >= 10 && Input.length <= 11) || (Input.charAt(0) === '1' && Input.length >= 9 && Input.length <= 10) )  ){
            if(Input.charAt(0) === '1'){ Input = '0' + Input;}
            return '+6'+Input;
          } 
        }
        changeHandler({error:"Invalid Value"},change)
        return null;
    }


    function ClearState(){
      setIsloading(false)
      clear(null)
    }




    const DOLink = () => {
      if(data.proof || !values.mobile || values.mobile.error) return null

      if(!values.address || !data.mobile || !data.delivery) return null
      
      var NewData = {...data}
      NewData['driver'] = {
        'name':values.name,
        'mobile':values.mobile,
      }
      NewData['address'] = values.address

      const MobileStr = data.mobile.substring(data.mobile.indexOf("1"));
     
      const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+NewData.id+'/'+NewData.driver.mobile.slice(2)
      const Whatsapptxt = NewData.address+'%0a'+'Mobile:0'+MobileStr+'%0a%0a'+Link

      return (
        <View style={{flexDirection:'row'}}>
              <Copy link={Link} title={'Copy'}/>
              <TouchableOpacity style={styles.btn} onPress={()=>Linking.openURL('whatsapp://send?text='+Whatsapptxt+'&phone='+NewData.driver.mobile)}>
                    <Ionicons name='logo-whatsapp' size={16} color={COLORS.Link} />
                    <Text style={styles.link}>Whatsapp</Text>
              </TouchableOpacity>
        </View>
      )
    }

    const ProofDelivery = () => {
      if(!data.proof) return null
      return (
        <View style={{flexDirection:'row'}}>
            <TouchableOpacity onPress={()=>Linking.openURL(data.proof.image, '_blank')} style={{flex:1}}>
              <Image style={{width:150,height:120}} source={{uri: data.proof.image}} />
            </TouchableOpacity>
            <View style={{flex:1}}>
              <Image style={{width:150,height:120}} source={{uri: data.proof.signed}} />
            </View>
        </View>
      )
    }

    const ButtonSubmit = () => {
      if(data.proof) return null
      return (
        <Button title={'UPDATE'} isloading={isLoading} icon={'md-add-circle'} onPress={() => Validate()}/>
      )

    }


    if(!data || !account) return null


    const Proof = data.proof?true:false

        return (
                <Modal title={Proof?'Completed':'Add Driver'} icon={'truck'}  Iconfont={'MaterialCommunityIcons'} visible={data?true:false} setVisible={()=>ClearState()}>
                        <Text style={{fontSize:18}}>Order ID : {data.id}</Text>
                        <Searchinput set={(val)=>setValues(val)} value={values} data={transporterList} uneditable={Proof}/>
                        {!values.driver_id && <TextInput onChangeText={(val)=>changeHandler(val,'mobile')} uneditable={Proof} maxLength={60} title="Mobile" value={values.mobile} autoCapitalize="true"errclear={true}/>}
                        <View style={{paddingVertical:10}}>
                        <TextInput onChangeText={(val)=>changeHandler(val,'address')} uneditable={Proof} maxLength={560} title="Delivery Address" value={values.address} autoCapitalize="true"errclear={true}/>
                        <TextInput onChangeText={(val)=>changeHandler(val,'remark')} uneditable={Proof} maxLength={560} title="Remark" value={values.remark} autoCapitalize="true" errclear={true}/>
                        </View>
                        <DOLink/>
                        <ProofDelivery/>
                        <ButtonSubmit/>
                </Modal>
        )

    

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    btn:{
        flexDirection:'row',
        alignContent:'center',
        padding:2,
        marginHorizontal:5,
        alignItems:'center',
        justifyContent:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      link:{
        color:COLORS.Link,
        fontSize:16,
        marginLeft:2
      },
  });
  