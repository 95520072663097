import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity} from 'react-native';
import Modal from "react-native-modal";
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import COLORS from "../../assets/component/colors";

export default function DatePicker(props) {
    
    const {set,value,txtcolor,title} = props

    const [current, onCurrent] = useState(moment());
    const [modal, onModal] = useState(false);
    const [option, onOption] = useState(null);

    useEffect(() => {
        onCurrent(moment())
        set(moment().format('DD-MM-YYYY'))
      }, []);


    const Header = () => {
        return (
            <View style={styles.header}>
                <View style={styles.center}>
                <Ionicons name={'calendar-sharp'} size={24} color='#444' style={{marginRight:5}}/>
                <View style={{flexDirection:'row',flex:1}}>
                    {BtnClick('minus','chevron-back')}
                    {DateBtn(current.format("MMMM"),()=>onOption('months'))}
                    {DateBtn(current.format("YYYY"),()=>onOption('years'))}
                    {BtnClick('add','chevron-forward')}
                </View>
                    <TouchableOpacity onPress={() => onModal(false)}>
                        <Ionicons name='close' size={22} color='#777' />
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    const DateBtn = (txt,exc) => {
        return (
            <TouchableOpacity onPress={exc}>
                <Text style={styles.title}>{txt }</Text>
            </TouchableOpacity>
        )
    }

    function BtnClick(type,icon){
        var upda = null;
        if(type == 'add'){
            upda = moment(current).add(1, 'M')
        }else{
            upda =  moment(current).add(-1, 'M')
        }
        return(
            <TouchableOpacity onPress={() => onCurrent(upda)}>
                        <Ionicons name={icon} size={22} color='#777' />
            </TouchableOpacity>
        )
    }

    function SetMth(mth){
        onCurrent(moment(current).month(mth))
        onOption(null)
    }

    function SetYear(year){
        onCurrent(moment(current).year(year))
        onOption(null)
    }

    const loopDays = () => {

        if(option == 'months') return <LoopMonths/>
        if(option == 'years') return <LoopYears/>


        if(current || value){
            var space = moment(current).set('date', 1).isoWeekday();
            const Days = current.daysInMonth()
            return <View  style={{flexWrap:'wrap',flexDirection:'row'}}>
                {[...Array(space)].map((e, i) => {
                        return (
                            <View style={[styles.box,{width:'14%'}]}  key={i} />
                        )

                })}
                {[...Array(Days)].map((e, i) => {
                        var bg = null

                        i = i+1
                        if(moment(value,'DD-MM-YYYY').format('L') == moment(current,'DD-MM-YYYY').set('date', i).format('L')){var bg = COLORS.Primary}
                        return (
                            <TouchableOpacity style={[styles.box,{width:'14%'}]}  key={i} onPress={()=> {set(moment(current).set('date', i).format('DD-MM-YYYY'));onModal(false)}}>
                                <View style={{padding:10,borderRadius:20,backgroundColor:bg}}>
                                    <Text style={{color:bg?'#fff':null}}>{i}</Text>
                                </View>
                            </TouchableOpacity>
                        )

                })}
            </View>
        }
    }
    

    const LoopMonths = () => {
        const Months = moment.monthsShort()
        const views = [];

        Months.map((e, i) => {
            i = i+1
            views.push(
                <TouchableOpacity style={[styles.box,{width:'14%'}]} key={i} onPress={()=> SetMth(e)}>
                    <View style={{padding:10,borderRadius:20}}>
                        <Text>{e}</Text>
                    </View>
                </TouchableOpacity>
            )
        })

        return <View style={{flexWrap:'wrap',flexDirection:'row'}}>{views}</View>
    }

    const LoopYears = () => {

        const [prev, OnPrev] = useState(moment().year());
        const views = [];

        for (var i = prev - 30; i <= prev; i++) {
            const cyear = i
            views.push(
                <TouchableOpacity style={[styles.box,{width:'14%'}]} key={i} onPress={()=> SetYear(cyear)}>
                    <View style={{padding:10,borderRadius:20}}>
                        <Text>{cyear}</Text>
                    </View>
                </TouchableOpacity>
            )
        }


        return <View style={{flexWrap:'wrap',flexDirection:'row'}}>
            {NxtBtn('Prev',()=>OnPrev(prev-20))}
            {views}
            {NxtBtn('Next',()=>OnPrev(prev+20))}
        </View>
    }

    const NxtBtn = (txt,exc) => {
        return <TouchableOpacity style={[styles.box,{width:'14%'}]} onPress={exc}><Text style={{color:COLORS.Link}}>{txt}</Text></TouchableOpacity>
    }
 
    const loopDaysWeeks = () => {

        if(option) return null 

        const data = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
            return <View  style={{flexWrap:'wrap',flexDirection:'row'}}>
                {data.map((e, i) => {
                        return (
                            <View style={[styles.box,{width:'14%'}]}  key={i}>
                                <Text style={{color:'#ccc'}}>{e}</Text>
                            </View>
                        )

                })}
            </View>


    }

    const DateBox = () => {
        return (
            <View>
                <Text style={styles.title2}>{title?title:'Date'}</Text>
                <TouchableOpacity style={styles.dbox} onPress={() => onModal(true)}>
                    <Text style={{fontSize:16,color:txtcolor?txtcolor:COLORS.Text}}>{value?value:current.format("DD-MM-YYYY")}</Text>
                </TouchableOpacity>
            </View>
        )

    }

    return(
        <View>
            {DateBox()}
      <Modal isVisible={modal} onBackdropPress={() => onModal(false)}>
        <View style={styles.container}>
          {Header()}
          <View style={styles.content}>
                {loopDaysWeeks()}
                {loopDays()}
          </View>
        </View>
      </Modal>
      </View>

    )

}

const styles = StyleSheet.create({
    container:{
        backgroundColor:'#fff',
        borderRadius:10
    },
    content:{
        padding:'1%',
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#ccc',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        fontWeight:'bold',
        paddingHorizontal:5,
    },
    box:{
        paddingVertical:10,
        alignContent:'center',
        justifyContent:'center',
        alignItems:'center'
    },
    dbox:{
        paddingVertical:10,
        paddingHorizontal:10,
        borderWidth:1,
        backgroundColor:'#fff',
        borderColor:'#aaa',
        borderRadius:5,
        outlineColor: COLORS.Primary ,
        marginBottom:5,
        flex:1
    },
    title2:{
        marginBottom:5,
        color:COLORS.Text,
        paddingHorizontal:5
      },
  });
  