import React, { useState,useEffect,useMemo } from 'react';
import { StyleSheet ,View,Text, ActivityIndicator,Dimensions } from 'react-native';
import {GetExspensesList} from "../../../assets/js/firebase";
import moment from 'moment';
import {YearSelector} from '../../../assets/component/momentselect';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import COLORS from '../../../assets/component/colors';
import CustomPieChart from "./customchart";


export default function ExspenseScreen({navigation}) {

  const [year, setYear] = useState(moment().format("YYYY"));
  const [total, setTotal] = useState(0);
  const [report, Setreport] = useState(null);
  const [isLoading, setIsloading] = useState(false);


  useEffect(() => {
    if(year){
      setIsloading(true)
      GenerateReport(year)
    }

  }, [year]);




  async function GenerateReport(){
    const List = await GetExspensesList(year)
    if(List){
      const sum = List.reduce(function (s, a) { return s + a.amount;}, 0);
      setTotal(sum)
      Setreport(List)
    }else{
      setTotal(0)
      Setreport([])
    }
    setIsloading(false)
  }





  const Chart = (data) => {
    if(isLoading) return <ActivityIndicator size={'small'}/>
    if(!data) return null


    return(
      <View style={{flex:1}}>
          <Text style={{fontSize:20}}>TOTAL CREDITED : RM{total.toFixed(2)}</Text>
         <CustomPieChart data={data} accessor="amount" absolute={false} nav={navigation} year={year}/>
        
      </View>
    )
  }
  



  const RefreshBtn = () => {
    return (
      <View style={{flexDirection:'row',justifyContent:'flex-end'}}>

          <Ionicons name='refresh' size={18} color={COLORS.Link} />
          <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>GenerateReport()}><Text style={{color:COLORS.Link}}>Refresh</Text></TouchableOpacity>

      </View>
    )
  }





  return (
    <View style={styles.container}>
      <View style={{flexDirection:'row',zIndex:23,alignContent:'flex-end'}}>
          <YearSelector setYear={setYear} year={year}/>
      </View>
      <RefreshBtn/>
      {Chart(report)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap',
    zIndex:10
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
