import React, { useState,useEffect } from 'react';
import { ActivityIndicator, StyleSheet ,View} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import {Button,Text} from "../../../assets/component";
import COLORS from "../../../assets/component/colors";
import {GETACC} from '../../../assets/js/getacc';
import {AddProduct,GetStockList,GetProductsStock} from "../../../assets/js/firebase";
import { PDFDownloadLink,PDFViewer} from "@react-pdf/renderer";
import {Ionicons} from '@expo/vector-icons';
import moment from 'moment';
import PDFile from './pdf_statement';


export default function DownloadStock(props) {

    const {data} = props

    const [isLoading, setIsloading] = useState(false);
    const [products, SetProducts] = useState([]);
    const [stocks, SetStocks] = useState(null);
    const [user, setUser] = useState(null);
    const [date, setDate] = useState(null);
    
    useEffect(() => {

        if(data.length){
            const result = data.filter(obj => obj.type === 'stock');
            SetProducts(result)
        }


    }, [data]);

    const DownloadLink = () => {
        if(stocks) return DownloadAll()
        return (
            <TouchableOpacity onPress={()=>GetStockProduct()} style={styles.link}>
                {isLoading?<ActivityIndicator size={'small'}/>:
                        <View style={{alignContent:'center',alignItems:'center'}}>
                                <Text style={{color:COLORS.Link,marginLeft:5}}>Generate Stocks</Text>
                                <Text style={{fontSize:10,fontWeight:'200',color:'#aaa'}}>{moment().format('DD/MM/YY HH:MM:SS A')}</Text>
                        </View>
                }
            </TouchableOpacity>
        )
    }

    const DownloadAll = () => {
        if(!stocks) return null
        return (
            <PDFDownloadLink document={<PDFile details={stocks} user={user} date={date} />} fileName={user.display_name+'-stock'}>
                        <View onPress={()=>GetStockProduct()} style={[styles.link,{backgroundColor:'#eee'}]}>
                            <Ionicons name="download-outline" size={22} color={COLORS.Link} />
                            <View style={{alignContent:'center',alignItems:'center'}}>
                                            <Text style={{color:COLORS.Link,marginLeft:5}}>Download Stocks</Text>
                                            <Text style={{fontSize:10,fontWeight:'200',color:'#aaa'}}>{moment().format('DD/MM/YY HH:MM:SS A')}</Text>
                            </View>
                        </View>
             </PDFDownloadLink>
        )
      }
      

    async function GetStockProduct(){
        setIsloading(true)
        const user = await GETACC();
        setUser(user)
        const InstockList = await GetStockList()
        let res = InstockList.reduce((ac,a) => {
            let ind = ac.findIndex(x => x.product === a.product);
            if(ind === -1){
                ac.push(a)
            }else{
                ac[ind].product_amount = a.product_amount
                ac[ind].stock += a.stock;
            }
            return ac;
          },[])

   
        const merged = res.map(item => ({
            ...products.find(({ id }) => id === item.product),
            ...item
        }));
        merged.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
        setDate(moment().format('DD/MM/YY HH:MM:SS A'))
        SetStocks(merged)
        setIsloading(false)

    }





    if(!products.length) return null


    return(
        <View style={styles.container}>
                <View style={{flex:1}}/>
                <DownloadLink/>
                
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        flexDirection:'row',
        alignContent:'flex-end',
        alignItems:'flex-end'
    },
    link:{
       flexDirection:'row',
       justifyContent:'center',
       alignContent:'center',
       alignItems:'center',
       borderWidth:0.5,
       borderColor:'#ddd',
       padding:5,
       borderRadius:5,
       backgroundColor:'#fafafa'
    },
  });
  