import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Update from './update';

export default function Table(props) {

  const {tab,setList,list,loading} = props

  const [option, setOption] = useState(null);
  const [name, onName] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [sortasc, setSortAsc] = useState(true);



  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  


const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('Staff',3)}
          {Th('Amount',2)}
          {Th('Total',1)}
          {Th('Created',2,true)}
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const RemoveLoader = (id) => {
      if(id != isLoading) return null
      return (
        <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
          {Loader(true)}
        </View>
      )
    }

    const RenderItem = ({item,index}) => {

      const TxtColor  = item.type == 'service'?'#009688':'#444'

      const BgColor = index % 2 == 0  ? "#FFFFFF" : "#ffefef"

      return(
        <View>
          {RemoveLoader(item.id)}
            <TouchableOpacity onPress={()=>setOption(item)} style={{padding:5,paddingVertical:15,backgroundColor: BgColor }}>
                  <View style={{flexDirection:'row'}}>
                      <View style={{flex:3}}>
                        <Text style={[styles.item]}>{item.staff}</Text>
                        <Text style={[styles.id]}>{item.id}</Text>
                      </View>
                      <View style={{flex:2}}><Text style={[styles.item]}>RM{item.amount.toFixed(2)}</Text></View>
                      <View style={{flex:1}}><Text style={[styles.item]}>{item.claim_id.length}</Text></View>
                      <View style={{flex:2}}><Text style={[styles.qty]}>{ReformatTime(item.createdAt)}</Text>
                      </View>
                  </View>
            </TouchableOpacity>
        </View>
      )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY HH:mm A');
    return format
  }
  

  const StatusBtn = (item) => {
    return (
      <TouchableOpacity style={styles.qtyc} onPress={()=>{option && item.id == option.id ? setOption(null): setOption(item)}}>
        <Ionicons name="ellipsis-vertical" size={24} color="#777" />
      </TouchableOpacity>
    )
  }






  function UpdateStatus(data){
    console.log(data)
    const filteredData = list[tab].filter(item => item.id !== data.id);
    const dataArray = [ ...list[data.status]]
    dataArray.unshift(data)
    setList({...list, [tab]:filteredData,[data.status]:dataArray});
    setOption(null)
  }




    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
          <Update update={UpdateStatus} data={option} set={(val)=>setOption(val)}/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  id:{
    marginRight:5,
    color:'#aaa',
    fontSize:12
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    color:'#aaa'
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  optionbtn:{
    padding:5
  }
});
