import React, { useState,useEffect } from 'react';
import { getFirestore,query,getDocs,collection,getDoc,doc} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import Storage from './storage';


export const GETLIST = async () => {

  return await GetAccLIST()

};

export const GETACC = async () => {

  return Storage.getData('account').then((data)=> {
    if(data){
        const pData = JSON.parse(data)
        return pData
    }else{
        return null
    }
  });

};

export const SETACC = async (data) => {

  Storage.storeData('account',JSON.stringify(data))

};


async function GetAccLIST(){

  return Storage.getData('accountlist').then((data)=> {
    if(data){
        const pData = JSON.parse(data)
        return pData
    }else{
        const GetID = getAccDetailsCloud()
        return GetID
    }
  });

};


async function getAccDetailsCloud(){

    const db = getFirestore();
    const q = query(collection(db, "account"));
    const querySnapshot = await getDocs(q);

    const AccList = []
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      data['id'] = doc.id
      AccList.push(data)
    });

    if(AccList.length){
      Storage.storeData('accountlist',JSON.stringify(AccList))
    }

    return AccList

};

export const UPDATELIST = (data) => {

  Storage.storeData('accountlist',JSON.stringify(data))

  return true
};
