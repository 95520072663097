import React, { useState,useEffect } from 'react';
import { StyleSheet ,View} from 'react-native';
import ListDown from './listdownoverlay';
import moment from 'moment';


  export const YearSelector = (props) => {

    const {year,setYear} = props

    const [data, setData] = useState([]);
    useEffect(() => {
      var datelist = []
      for (let i = 0; i < 5; i++) {
        datelist.push(moment().subtract(i, "years").format("YYYY"))
      }
      setData(datelist)
    }, []);
  
  
      return(
          <View style={styles.container}>
                    <ListDown name={'Date'} list={data} onPress={setYear} active={year}/>
          </View>
      )

  }

  export const AdvYearSelector = (props) => {

    const {year,setYear,adv,prv} = props

    const [data, setData] = useState([]);
    useEffect(() => {
      var datelist = []
      if(prv){
        for (let i = 1; i < prv+1; i++) {
          datelist.unshift(moment().subtract(i, "years").format("YYYY"))
        }
      }

      for (let i = 0; i < adv + 1; i++) {
        datelist.push(moment().add(i, "years").format("YYYY"))
      }
      setData(datelist)
    }, []);
  
  
      return(
          <View style={styles.container}>
                    <ListDown name={'Date'} list={data} onPress={setYear} active={year}/>
          </View>
      )

  }

  export const MonthSelector = (props) => {

    const {value,set} = props

    const [data, setData] = useState([]);

    
    useEffect(() => {
      if(!value) set(moment().subtract(1, 'months').format("MM-YYYY"))
      var datelist = []
      for (let i = -1; i > -5; i--) {
        datelist.push(moment().add(i, "months").format("MM-YYYY"))
      }
      setData(datelist)
    }, []);
    if(!value) return null
  
      return(
          <View style={styles.container}>
                    <ListDown name={'Date'} list={data} onPress={set} active={value}/>
          </View>
      )

  }


const styles = StyleSheet.create({
    container:{
        zIndex:22,
        marginLeft:5
    },

  });
  