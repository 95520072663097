import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView, FlatList,Image} from 'react-native';
import {Button,Modal,Table} from "../../../../assets/component";
import { TouchableOpacity } from 'react-native-gesture-handler';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";



export default function AddNewClaim(props) {

    const {details,type,month} = props

    const [modalVisible, setModalVisible] = useState(false);

    const csvData = [
        ["ID Type", "lastname", "email"],
        ["Ahmed", "Tomi", "ah@smthing.co.com"],
        ["Raed", "Labes", "rl@smthing.co.com"],
        ["Yezzi", "Min l3b", "ymin@cocococo.com"]
      ];

    useEffect(() => {
        if(modalVisible){

        }

    }, [modalVisible]);

    const Footer = () => {
        return (
            <Button title={'CLOSE'} icon={'md-add-circle'} onPress={() =>setModalVisible(false)}/>
        )
    }


     const KwspContribute = () => {
        const result = details.map(({kwsp,kwsp2,name,epf_no,mykad,...rest}) => {
            const MYKAD = mykad.charAt(0) == '0'?"'"+mykad:mykad
            const EPF =  epf_no?(epf_no.charAt(0) == '0'?"'"+epf_no:epf_no):''
            return ({'idtype':'IN','mykad':MYKAD,'epf':EPF,name,kwsp,kwsp2})
        });
        const header = [
            {label: " ID Type" ,key: "idtype"},
            {label: "Employee MyKad No.",key: "mykad"},
            {label: "Employee EPF No.",key: "epf"},
            {label: "Employee Name",key: "name"}
          ];

        return (
            <View>
                <CSVLink data={result} headers={header} filename={"kwsp_"+month}>Download me</CSVLink>
                <Image style={styles.ProductImg} source={require('./kwsp.png')}/>
                <Table 
                        data={result} 
                        colSettings={[
                            { name: 'mykad',flex: 1 }, 
                            { name: 'name', flex: 1 }, 
                            { name: 'kwsp',label:'employer', flex: 1 },
                            { name: 'kwsp2',label:'employee', flex: 1 },
                        ]}
                />
            </View>
        )
     }

     const PerkesoContribute = () => {
        const Forma = moment(month,'MM-YYYY').format("MMYYYY")
        const result = details.map(({socso,socso2,name,mykad,basic,...rest}) => {
            const basicF = '3'
            return {code:'B3202119436P',name,mykad,Forma,basicF,socso,socso2}
            });
        return (
            <View>
                <CSVLink data={result} filename={"perkeso_"+month}>Download Text File</CSVLink>
                <Image style={styles.ProductImg} source={require('./perkeso.png')}/>
                <Table 
                        data={result} 
                        colSettings={[
                            { name: 'mykad', flex: 1 }, 
                            { name: 'name', flex: 1 }, 
                            { name: 'socso',label:'employer', flex: 1 },
                            { name: 'socso2',label:'employee', flex: 1 },
                        ]}
                />
            </View>
        )
     }
    

    const MthPreview = () => {
        return (
            <View style={{flexDirection:'row',justifyContent:'flex-end'}}>
                <Text style={{fontWeight:'500',fontSize:18}}>{month}</Text>
            </View>
        )
    }
     



    const Popup = () => {

        return (
                <Modal title={'Details'} icon={'md-list'} visible={modalVisible} setVisible={setModalVisible} footer={Footer()}>
                        <ScrollView>
                        <MthPreview/>
                        {type == 'KWSP'?<KwspContribute/>:<PerkesoContribute/>}
                        </ScrollView>
                </Modal>
        )
    }

    const Btn = () => {
        const BtnI = type == 'KWSP'?require('./kwsp.png'):require('./perkeso.png')
        return (
            <TouchableOpacity style={[styles.center,styles.btn]} onPress={() => setModalVisible(true)}>
                <Image style={styles.small} source={BtnI}/>
                <Text>{type}</Text>
            </TouchableOpacity>
        )
    }
    


    return(
        <View style={styles.container}>
            <Btn/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        flexDirection:'row',
        marginRight:10
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
    },
    btn:{
        backgroundColor:'#fff',
        borderRadius:5,
        padding:15,
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      item:{
        marginRight:5,
        color:'#444',
      },
      ProductImg: {
        width: 50,
        height: 50,
      },
      small:{
        width: 25,
        height: 25,
        marginRight:4
      }
  });
  