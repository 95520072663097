import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import UpdateModal from './updateModal';
import UpdateDriverModal from '../Order/deliveryorder';
import moment from 'moment'
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';
import COLORS from "../../assets/component/colors";
import {Button,CheckBox} from '../../assets/component';


export default function Table(props) {

  const {tab,setList,list,next,statusloading,loading,setPicked,picked} = props


  const [modified, setModified] = useState(null);
  const [sumtotal, setSumTotal] = useState(0);
  const [driver, setDriver] = useState(null);

  useEffect(() => {

    const sum = list[tab].reduce((acc, cv) => {
      return acc + parseFloat(cv.income);
    }, 0).toFixed(2);
    setSumTotal(sum)
    
  }, [list,tab]);

  useEffect(() => {

    setPicked([])
    
  }, [tab]);


  const Th = (title,size) => {
    return(
        <View style={{flex:size}}>
            <Text style={styles.item}>{title}</Text>
        </View>

    )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {tab == 'pending' && <View style={{width:40}}></View>}
          {Th('Order Id',3)}
          {Th('Created At',2)}
          {Th('Income',3)}
          <View style={{width:30}}><MaterialCommunityIcons name='truck' size={18} color={'#999'} /></View>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }



    const Thickbox = (data) => {
      if(tab != 'pending') return null
      var containsId = picked.find(x => x.id === data.id);
      return (
          <CheckBox value={containsId} onValueChange={()=>SelectId(data,containsId)} />
      )
    }

    function SelectId(data,found){
      if(found){
        const filteredData = picked.filter(item => item.id !== data.id);
        setPicked(filteredData);
      }else{
        setPicked([...picked, data])
      }
    }

    const RemoveLoader = (id) => {
      if(picked.find(x => x.id == id) && statusloading){
        return (
          <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
            {Loader(true)}
          </View>
        )
      }

      return null
    }


    const ReformatTime = (data) => {
      if(!data) return null
      const format = moment(data.toDate()).format('DD/MM/YY');
      return format
    }
    

    const DriverIcon = (item) => {
      const Icon = item.driver?'progress-clock':'truck'
      if(item.proof) return <Button onPress={()=>setDriver(item)} icon={'checkmark'} color={'#00ba38'} />
      return <Button onPress={()=>setDriver(item)} icon={Icon} color={'#ddd'} Iconfont={'MaterialCommunityIcons'}/>
    }




    const RenderItem = ({item,index}) => {

      var containsId = picked.find(x => x.id === item.id);
      return(
        <View>
        {RemoveLoader(item.id)}
          <View style={{padding:5,paddingVertical:15,opacity:containsId?0.2:1, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
            <View style={{flexDirection:'row'}}>
                    {Thickbox(item)}
                    <TouchableOpacity onPress={() => {item.status == 'pending' && setModified(item)}} style={{flex:3}}>
                      <Text style={styles.id}>{item.orderid}</Text>
                      <Text style={styles.title}>{item.company}</Text>
                    </TouchableOpacity>
                    <View style={{flex:2}}>
                          <Text style={[styles.item,{fontSize:13}]}>{ReformatTime(item.createdAt)}</Text>
                          <Text style={styles.item}>{item.products.length}</Text>
                    </View>
                    <View style={{flex:3}}><Text style={styles.item}>RM{parseFloat(item.income).toFixed(2)}</Text></View>
                    <View style={{width:30}}>{DriverIcon(item)}</View>
            </View>
          </View>
        </View>
      )
  }


  function UpdateData(data){
    const dataArray = [ ...list[tab]]
    const result = dataArray.map((obj) => obj.id === data.id ? data : obj);
    setList({...list, [tab]:result})
  }

  function CancelOrder(data){
    const stat = 'cancel'
    data['status'] = stat
    const filteredData = list[tab].filter(item => item.id !== data.id);
    const dataArray = [ ...list[stat]]
    dataArray.unshift(data)
    setList({...list, [tab]:filteredData,[stat]:dataArray});
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  


const TotalSum = () => {
  return (
    <View style={styles.sumcontainer}>
      <Text style={{fontWeight:'700',fontSize:18}}>{numberWithCommas(sumtotal)}</Text>
      <Text>{'TOTAL (RM) '}</Text>
    </View>
  )
}
    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            onEndReached={next}
          />
          <UpdateModal data={modified} clear={setModified} update={(val)=>UpdateData(val)} cancel={CancelOrder}/>
          <UpdateDriverModal data={driver} clear={setDriver} update={(val)=>UpdateData(val)} type={'online_order'}/>
          <TotalSum/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  id:{
    marginRight:5,
    color:'#006ad4',
    fontSize:12
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    position:'absolute',
    backgroundColor:'#fff',
    zIndex:1,
    left:-90,
    borderRadius:5,
    padding:10,
    paddingHorizontal:20,
    shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  sumcontainer:{
    padding:10,
    alignContent:'center',
    alignItems:'center',
    backgroundColor:'#eee',
    flexDirection:'row-reverse'
  },
});
