import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View , Text} from 'react-native';
import Viewer from './viewer';



export default function ProductsScreen({navigation}) {


  const [list, setList] = useState([]);



  useEffect(() => {

  }, []);
  


  async function getList(active){

  };



  function StockNav(data){
    navigation.navigate('stock', {pid:data.id})
  }



  return (
    <View style={styles.container}>
      <Viewer/>
        <Text>dsdd</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
});
