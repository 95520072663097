import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View} from 'react-native';
import {GetAllDocs} from "../../../assets/js/firebase";
import {MonthSelector} from '../../../assets/component/momentselect';
import {SearchBox,Text} from "../../../assets/component";
import Table from './table';
import Pay from './pay';
import Summarize from './summarize';


export default function PayrollScreen({navigation}) {


  const isMounted = useRef();


  const [month, setMonth] = useState(null);
  const [list, setList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [picked, setPicked] = useState([]);
  const [statusloading, setStatusloading] = useState(false);


  useEffect(() => {
      if(month){
          setIsloading(true)
          getList(month);
          setPicked([])
      }
  }, [,month]);
  
  


  async function getList(mth){
      const Staff = await GetAllDocs('staff','active')
      const ListProduct = await GetAllDocs('salary/months/'+mth)


      var data = []
        Staff.forEach(doc => {
        var foundIndex = ListProduct.findIndex(x => x.staff == doc.id);
        if(foundIndex >-1){
          data.push(ListProduct[foundIndex])
        }else{
          doc['staff'] = doc.id
          data.push({'type':'generate','month':mth,...doc})
        }
      });

      setList(data);
      setIsloading(false)
  };




  function UpdatePaid(staff){

    const New = list.map(function(x){ 
      var result=staff.filter(a1=> a1.staff==x.staff);
      if(result.length>0) { x.status='paid';}
      return x })

    setList(New)

  }




  const Footer = () => {
    if(!picked.length) return null


    return (
      <View style={{flexDirection:'row',padding:10,backgroundColor:'#bbeeff',position:'absolute',width:'100%',bottom:0,zIndex:99}}>
        <View style={{flex:1}}>
            <View><Text>TOTAL : {picked.length}</Text></View>
        </View>
        <Summarize details={picked} type={'KWSP'} month={month}/>
        <Summarize details={picked} type={'PERKESO'}  month={month}/>
        <Pay details={picked} set={UpdatePaid}/>
      </View>
    )
  }

  function MonthSelect(mth){
    setPicked([])
    setMonth(mth)

  }




  return (
    <View style={styles.container}>
      <View style={{padding:10,flex:1,zIndex:22}}>
      <View style={{flexDirection:'row',zIndex:33}}>
          <View style={{flex:3}}><SearchBox set={(val)=>setList(val)} products={list} filters={['name', 'staff']}/></View>
          <View style={{flex:1}}><MonthSelector set={MonthSelect} value={month}/></View>
      </View>
      <Table 
        setList={setList} 
        list={list} 
        loading={isLoading}
        setPicked={setPicked}
        picked={picked}
        statusloading={statusloading}
      />
      </View>
      <Footer/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
