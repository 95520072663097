import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import moment from 'moment';
import Generate from './generate';
import {CheckBox} from "../../../assets/component";

export default function Table(props) {

  const {setList,list,loading,setPicked,picked} = props

  const [isLoading, setIsloading] = useState(false);
  const [sortasc, setSortAsc] = useState(true);
  const [generate, setGenerate] = useState(null);


  function Sortby(name){
    var name = name.toLowerCase();
    const dataArray = [ ...list]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList(sorted);
  }
  


const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<Ionicons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          <View style={{width:30}}/>
          {Th('Staff',2)}
          {Th('Income',2)}
          {Th('Deduction',2)}
          {Th('Amount',2)}
          {Th('Created',2,true)}
          <View style={{width:50}}/>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const EditBtn = (item) => {
     if(item.status !== 'pending') return <View style={{width:50}}/>

     return (
      <TouchableOpacity style={[styles.flexcenter,{width:50}]} onPress={() => setGenerate({...item})} >
        <Ionicons name="edit" size={20} color="#ccc" />
      </TouchableOpacity>
     )
    }

    const Thickbox = (data,gen) => {
      if(gen) return <View style={{width:30}}/>
      var containsId = picked.find(x => x.id === data.id);
      return (
        <CheckBox value={containsId} onValueChange={()=>SelectId(data,containsId)} />
      )
    }

    function SelectId(data,found){
      if(found){
        const filteredData = picked.filter(item => item.id !== data.id);
        setPicked(filteredData);
      }else{
        setPicked([...picked, data])
      }
    }

    const StatusBx = (status) => {

      let bg;
      switch (status) {
        case 'paid':
          bg = "green";
          break;
        case 'pending':
          bg = "red";
          break;
        default:
          bg = "#444";
      }

      return (
        <View style={{flexDirection:'row'}}>
          <View style={[styles.stat,{backgroundColor:bg}]}>
          <Text style={styles.statxt}>{status}</Text>
          </View>
        </View>
      )
    }



    const RenderItem = ({item,index}) => {
      const Gen = item.type == 'generate'?true:false
      const ItemStyle = Gen?{flex:2,opacity:0.2}:{flex:2}
      const GenArray = {...item,'staff':item.id,'status':'generate'}

      const Income = SUMObj(['basic','income_others'],item)
      const Deduction = SUMObj(['kwsp2','socso2','eis2','pcb','deduction_others'],item)

      return(
        <TouchableOpacity onPress={() => setGenerate(Gen&&GenArray)} key={item.id} style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
              <View style={{flexDirection:'row',flex:1}}>
                  {Thickbox(item,Gen)}
                  <View style={ItemStyle}>
                    <Text style={styles.item}>{item.name}</Text>
                    {StatusBx(item.status)}
                  </View>
                  <View style={ItemStyle}><Text style={styles.amt}>RM{Income}</Text></View>
                  <View style={ItemStyle}><Text style={styles.amt}>RM{Deduction}</Text></View>
                  <View style={ItemStyle}><Text style={styles.amt}>RM{item.total}</Text></View>
                  <View style={ItemStyle}><Text style={styles.item}>{ReformatTime(item.createdAt)}</Text></View>
                  {EditBtn(item)}
              </View>
        </TouchableOpacity>
      )
  }


  function SUMObj(obj,data){
    var SUM = 0
    obj.forEach((item) => {
        if(typeof data[item] === 'object' && data[item] !== null){
            data[item].forEach((item2) => {
                SUM += parseFloat(item2.amount)?parseFloat(item2.amount):0
            })
        }else{
            SUM += parseFloat(data[item])?parseFloat(data[item]):0
        }
    });
    return SUM.toFixed(2)
  }

  function UpdateData(data){
    const newdata = [...list]
    var i = newdata.findIndex(x => x.staff == data.staff);

    list[i] = data; // <-- mutation!
    setList(list);

    setGenerate(null)
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY HH:mm A');
    return format
  }
  



    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <Generate data={generate} clear={setGenerate} add={UpdateData}/>
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            keyExtractor={(item, index) => index.toString()}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  id:{
    marginRight:5,
    color:'#aaa',
    fontSize:12
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    color:'#aaa'
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  optionbtn:{
    padding:5
  },
  user:{
    borderWidth:1,
    padding:5,
    borderRadius:5,
    borderColor:'#ccc',
    width:30,
    alignContent:'center',
    alignItems:'center'
  }, 
  stat:{
    borderRadius:5,
    marginTop:3,
    padding:3
  },
  statxt:{
    color:'#fff',
    fontSize:12
  }
});
