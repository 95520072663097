import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import moment from 'moment';
import DefectProduct from './defect';
import UpdateStock from './update';

export default function Table(props) {

  const {tab,setList,list,loading,del,removeLoading} = props


  const [sortasc, setSortAsc] = useState(true);
  const [option, setOption] = useState(null);
  const [defect, setDefect] = useState(null);
  const [update, setUpdate] = useState(null);


  function Sortby(name){
    var name = name.toLowerCase();
    const dataArray = [ ...list]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList(sorted);
  }
  



const DeleteBtn = (data) => {

  if(data.load_stock !== data.stock) return null
  return (
      <TouchableOpacity style={styles.center} onPress={()=>del(data)}>
        <Ionicons name='ios-trash' size={18} color={'#de3400'} />
      </TouchableOpacity>
  )
}


const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}

function Updatedata(data,newstock){

  const dataa = list.map((item) => {
    if (item.id === data.id) {
      return { ...item, stock:newstock };
    } else {
      return item;
    }
  })
    setList(dataa);
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('Supplier',2)}
          {Th('Purchase',1)}
          {Th('Cost',1)}
          {Th('Selling',1)}
          {Th('Date',1)}
          {Th('Load_Stock',2,true)}
          {Th('Stock',1,true)}
          <View style={{width:20}}></View>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const RemoveLoader = (id) => {
      if(id != removeLoading) return null
      return (
        <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
          {Loader(true)}
        </View>
      )
    }


    const Option = (data) => {
      return (
        <View style={{flexDirection:'row',marginVertical:5}} >
            <View style={{flexDirection:'row',flex:1}}>
              <TouchableOpacity style={styles.option} onPress={()=> setDefect(data)}>
                  <Ionicons name={'close'} size={16} color='#fff' />
                  <Text style={{color:'#fff',fontSize:14,marginLeft:2}}>Defect</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.update} onPress={()=> setUpdate(data)}>
                  <Text style={{color:'#fff',fontSize:14,marginLeft:2}}>Update</Text>
              </TouchableOpacity>
            </View>
        </View>
      )
    }



    const RenderItem = ({item,index}) => {

 
      return(
        <View onPress={()=>console.log(item)}>
          {RemoveLoader(item.id)}
        <TouchableOpacity onPress={()=>{option && item.id == option.id ? setOption(null): setOption(item)}}  style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
              <View style={{flexDirection:'row',flex:1}}>
                  <View style={{flex:2}}><Text style={styles.item}>{item.supplier}</Text></View>
                  <View style={{flex:1}}><Text style={styles.item}>RM{parseFloat(item.product_amount?item.product_amount:0).toFixed(2)}</Text></View>
                  <View style={{flex:1}}><Text style={styles.item}>RM{parseFloat(item.amount?item.amount:0).toFixed(2)}</Text></View>
                  <View style={{flex:1}}><Text style={styles.item}>RM{parseFloat(item.selling?item.selling:0).toFixed(2)}</Text></View>
                  <View style={{flex:1}}><Text style={styles.item}>{ReformatTime(item.createdAt)}</Text></View>
                  <View style={{flex:2}}><Text style={styles.qty}>{item.load_stock?item.load_stock:0}</Text></View>
                  <View style={{flex:1}}><Text style={styles.qty}>{item.stock?item.stock:0}</Text></View>
                  <View style={{width:20}}>{DeleteBtn(item)}</View>
              </View>
              {option && option.id == item.id?Option(item):null}
        </TouchableOpacity>
        </View>
      )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY');
    return format
}

function UpdateData(id,data){
  setUpdate(null)
  const dataa = list.map((item) => {
    if (item.id === id) {
      return data;
    } else {
      return item;
    }
  })
    setList(dataa);
}
  






    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <DefectProduct unit={defect} clear={setDefect} update={Updatedata}/>
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
          <UpdateStock data={update} clear={()=>setUpdate(null)} update={UpdateData}/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    fontWeight:'bold',
    color:'#e07c00',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'red',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  update:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#3a5',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  optionbtn:{
    padding:5
  }
});
