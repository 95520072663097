import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../../assets/component/textinput";
import Modal from "../../../assets/component/modal";
import Button from "../../../assets/component/button";
import COLORS from "../../../assets/component/colors";
import {UpdateStock} from "../../../assets/js/firebase";


export default function StockUpdates(props) {

    const {data,update,clear} = props



    useEffect(() => {
        if(data){
            setModalVisible(true)
            setMember(data)
        }else{
            setModalVisible(false)
        }
    }, [data])



    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState("");

    const [member, setMember] = useState({});


    const onChangeHandler = (e,name) => {
        const Value = e.target?e.target.value:e
        setMember((prevState) => ({ ...prevState, [name]: Value }));
    };



    async function Validate(){

        const validNum = ValidationNum(['stock','amount','selling','product_amount']);
        const validName = ValidationName(['supplier']);

        setError('')


        if(validNum && validName){
            member['stock'] = parseInt(member.stock)
            const Amt = parseFloat(member['amount'])
            const Sell = parseFloat(member['selling'])
            if(Sell > Amt){
                Submit(member)
            }else{
                setMember(prevState => ({...prevState,['selling']: {error:"Selling Amount must be more than purchase"}}));
            }
        }

    
    }


    function ValidationNum(obj){
        var validated = true
        obj.map((name)=>{
            if(typeof member[name] == 'undefined' || isNaN(member[name]) || member[name].error || member[name] <= 0){
                setMember(prevState => ({
                    ...prevState,
                    [name]: {error:"Invalid Value"}
                }));
                validated = false
            }
        })
        return validated
    }

    function ValidationName(obj){
        var validated = true
        obj.map((name)=>{
            if(typeof member[name] == 'undefined' || !member[name] || member[name].error){
                setMember(prevState => ({
                    ...prevState,
                    [name]: {error:"Invalid Value"}
                }));
                validated = false
            }
        })
        return validated
    }

    async function Submit(data){
        setIsloading(true)
        const stock = (({ id, ...o }) => o)(data) // remove b and c
        const DocId = await UpdateStock(data.id,stock);
        if(DocId){
            Success(data.id,data)
        }
      }
      
      function Success(id,data){
        setIsloading(false)
        setMember(prevState => ({...prevState,['stock']: 0}));
        update(id,data)
      }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }

    const InputVal = (title,val,remark) => {

        const inputVal = member && member[val] ? member[val]:''

        return (
            <TextInput
                placeholder={title}
                title={title}
                remark={remark}
                keyboardType="decimal-pad" maxLength={13}
                onChange={e => onChangeHandler(e,val)}
                value={inputVal}
            />
        )
    }

    const InputText = (title,val,remark) => {

        const inputVal = member && member[val] ? member[val]:''

        return (
            <TextInput
                placeholder={title}
                title={title}
                remark={remark}
                onChange={e => onChangeHandler(e,val)}
                value={inputVal}
            />
        )
    }

    const Footer = () => {
        return (
            <Button title={'Update'} icon={'md-add-circle'} onPress={() => Validate()} isloading={isLoading}/>
        )
    }



    
        return (
            <Modal title={'Update Stock'} icon={'home'} visible={modalVisible} setVisible={clear} footer={Footer()}>
                        <ScrollView >
                        <Text style={{fontWeight:'bold',marginBottom:10,color:'#777'}}>{member.id}</Text>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{flex:3,marginRight:4}}>{InputText('Supplier','supplier')}</View>
                                    <View style={{flex:1}}>{InputVal('Stock','stock')}</View>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{flex:1,marginRight:4}}>{InputVal('Product','product_amount',"(RM)")}</View>
                                    <View style={{flex:1,marginRight:4}}>{InputVal('Cost','amount',"(RM)")}</View>
                                    <View style={{flex:1}}>{InputVal('Selling','selling',"(RM)")}</View>
                                </View>
                                <View style={{paddingVertical:5}}>
                                    <Text style={{fontSize:10,fontWeight:'300'}}>{"* Cost Price - Product Price + Other cost eg:Transportation,duties & others"}</Text>
                                </View>
                                {InputText('Stock Remark','remark')}
  
                                <Error error={error}/>
                        </ScrollView>
                </Modal>
        )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flex:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
    },
  });
  