import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator } from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {Modal} from "../../../../assets/component";
import SearchBox from "./searchbox";
import TextInput from "./minitextinput";
import {GetCreditList} from "../../../../assets/js/firebase";
import COLORS from '../../../../assets/component/colors';


export default function ShippingList(props) {

    const {set,data} = props

    const ItemArray = [ {'name':'Forwarder ( China )','amount':0,'cat':'Transport','sub':'Sea Freight'},
                    {'name':'Shipping Linear ( MY )','amount':0,'cat':'Transport','sub':'Ocean Freight'},
                    {'name':'Forwarder ( MY )','amount':0,'cat':'Shipping','sub':'clearance'},
                    {'name':'Forklift Rental  (MY)','amount':0,'cat':'Transport','sub':'forklift'},
                    {'name':'Ex-Worker (MY)','amount':0}
                  ]


    const [isLoading, setIsloading] = useState(false);
    const [kastamlist, setKastamList] = useState([]);

    const [visible, setVisible] = useState(null);



    useEffect(() => {

        set(ItemArray)

    }, []);

    


const ReformatTime = (data) => {
        if(!data) return null
        const format = moment(data.toDate()).format('DD-MM-YYYY');
        return format
}

function AddItem(item){
    set([item,...data])
    setVisible(null)
    const NewArray = kastamlist.filter(kastamlist => kastamlist.id !== item.id);
    setKastamList(NewArray)
}



const RenderItem = ({item}) => {
    item['paid'] = item.paid?item.paid:0
    return(
      <TouchableOpacity style={{borderBottomWidth:1,padding:5,borderColor:'#aaa'}}  onPress={()=>AddItem(item)}>

        <View style={{flexDirection:'row'}}>
            <View style={{flex:1}}>
                    <Text style={[styles.item,{flex:1}]}>#{item.id}</Text>
                    <Text style={styles.item}>{item.category.name}</Text>
                    <Text style={styles.item}>{item.remark}</Text>
                    <Text style={styles.rmk}>{ReformatTime(item.date)}</Text>
            </View>
            <View>
                <Text style={[styles.item,{color:'#3176ff'}]}>RM{parseFloat(item.amount).toFixed(2)}</Text>
            </View>
        </View>

      </TouchableOpacity>
    )
}

function AddItem(data){
    ChangeAmt(visible.index,data.amount,'amount')
    setVisible(null)
}



const ItemList = () => {
    if(isLoading) return <ActivityIndicator size={'small'}/>
    return (
        <FlatList
        showsHorizontalScrollIndicator={false}
        data={kastamlist}
        renderItem={RenderItem}
        onEndReachedThreshold={0}
        />
    )
    }



    function sumBy(arr){
        return arr.reduce((sum, { amount }) => sum + parseFloat(amount), 0).toFixed(2);
    }

    async function LoadData(type){
        const Year = moment().format('YYYY')
        setVisible(type)
        setIsloading(true)
        const response = await GetCreditList(Year,type.cat,type.sub)
        setKastamList(response.list);
        setIsloading(false)
    }

    const Addbtn = (item,i) => {
        return (
            <TouchableOpacity style={{paddingHorizontal:5}} onPress={()=>{LoadData({'index':i,...item})}}>
                <Text style={{color:'blue'}}>+</Text>
            </TouchableOpacity>
        )
    }

    const Col = (item,i) => {
        return (
            <View style={{flexDirection:'row'}} key={i}>
                <View style={{width:200}}>
                    <View style={{flexDirection:'row'}}>
                        {item.cat ?<Text>{item.name}</Text>: <TextInput value={item.name} onChangeText={(val)=>ChangeAmt(i,val,'name')}/>}
                        {item.cat && Addbtn(item,i)}
                    </View>
                </View>
                <TextInput currency={'RM'} value={item.amount} onChangeText={(val)=>ChangeAmt(i,val,'amount')}/>
            </View>
        )
    }
    

    function ChangeAmt(index,val,type){
        const NewOrder = [...data]
        NewOrder[index][type] = val
        set(NewOrder)
    }

    const Items = (data) => {
        return (
            <View style={styles.head}>
                {data.map((i,k)=>{
                     return Col(i,k)
                })}
            </View>
        )
    }

    function AddRow(){
        set([{'name':'','amount':0},...data])
    }

    async function GetPrev(){
        const Prv =  moment().subtract(1, 'year').format('YYYY')
        const response = await GetCreditList(Prv,visible.cat,visible.sub)
        setKastamList([ ...kastamlist, ...response.list ])
    }



    return (
        <View style={{paddingVertical:10}}>
            <View style={{flexDirection:'row'}}>
                <Text style={styles.h1}>SHIPPING</Text>
                <TouchableOpacity style={styles.morebtn} onPress={()=>AddRow()}>
                        <Ionicons name='add' size={18} color={'blue'} />
                        <Text style={{color:COLORS.Link}}>Add</Text>
                </TouchableOpacity>
            </View>
            {Items(data)}
            <View style={{alignItems:'flex-end'}}><Text style={styles.sum}>RM{sumBy(data)}</Text></View>
            <Modal title={'Choose'} icon={'ios-receipt'} visible={visible?true:false} setVisible={setVisible} headColor={'#428ff5'}>
                <SearchBox set={setKastamList} products={kastamlist} filters={['name', 'id']}/>
                <ItemList/>
                <TouchableOpacity onPress={()=>GetPrev()}><Text style={{color:'blue'}}>Previous Year</Text></TouchableOpacity>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    item:{
        fontSize:14
    },
    rmk:{
        fontSize:12,
        color:'#aaa'
    },
    sum:{
        color:'#a52',
        fontWeight:'500'
    },
    h1:{
        fontSize:22,
        fontWeight:'500'
      },
      morebtn:{
        marginVertical:4,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        padding:4,
        flexDirection:'row'
    },
    sbox:{
        borderWidth:1,
        padding:4,
        borderRadius:5,
        backgroundColor:'#fafafa',
        borderColor:'#ccc',
        flexDirection:'row',
        marginBottom:4
    },
          morebtn:{
        marginVertical:4,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        padding:4,
        flexDirection:'row'
    },
});
