import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import Modal from "../../../assets/component/modal";
import TextInput from "../../../assets/component/textinput";
import Button from "../../../assets/component/button";
import COLORS from "../../../assets/component/colors";
import {AddStaff,defectStock} from "../../../assets/js/firebase";


export default function DefectStockModal(props) {

    const {unit,clear,update} = props


    const [isLoading, setIsloading] = useState(false);
    const [data, onData] = useState({});
    const [error, setError] = useState("");
    const [stock, onStock] = useState(0);

    useEffect(() => {
        if(unit){
            onStock(0)
            onData(unit)
        }
    }, [unit]);




    async function Validate(){
        setError('')
        if(stock > 0 && stock <= data.stock){
            setIsloading(true)

            if(unit){
                const status = await defectStock(data,stock);
                if(status){
                    const newstock = data.stock-stock
                    update(data,newstock)
                    setIsloading(false)
                    clear(null)
                }
            }
        }else{
            setError("More than stock")
        }
    }


    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


    const Footer = () => {
        return (
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1}}><Error error={error}/></View>
                <Button title={'DEFECTS'} icon={'close'} onPress={() => Validate()} isloading={isLoading}/>
            </View>
        )
    }



    if(unit){
        return (
                <Modal title={'DEFECT STOCK'} icon={'person'} visible={true} setVisible={clear} footer={Footer()}>
                    <Text>BALANCE STOCK : {data.stock}</Text>
                    <TextInput title={'Defect Unit'} onChangeText={onStock} value={stock} keyboardType="decimal-pad" maxLength={13} />
                </Modal>
        )
    }
    
    

    return null


}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      dualL:{
        flex:1,
        marginRight:5
      },
      dualR:{
        flex:1,
        marginLeft:5
      },
      head:{
        fontWeight:'400',
        color:COLORS.Primary
      },
      coltitle:{
        borderBottomWidth:1,
        padding:5,
        borderColor:'#eee',
        marginVertical:5
      }
  });
  