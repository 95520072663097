import React, { useState, useEffect } from 'react';
import {Text, View, StyleSheet } from 'react-native';
import COLORS from './colors';
import TextInput from "./textinput";


export default function SearchBox(props) {

    const {set,products,filters } = props

    // eg : const filters = ['id','name'] // default value name


    const [List, setList] = useState([]);
    const [search, onChangeSearch] = useState('');
    const [error, setError] = useState(null);

    
    useEffect(() => {

      if(!List.length || products.length > List.length ){
        setList(products)

        if(filters && products.length){
          const result = filters.every(key => products[0].hasOwnProperty(key));
          if(!result){
              setError(JSON.stringify(filters) + " Filters and not available on the list")
          }
        }
      }

    }, [products]);


    function Search(text){
      onChangeSearch(text)
      if(text){
        const newData = List.filter((item) => 
          filters ? filters.some(val => item[val].toLowerCase().includes(text.toLowerCase())) : item.name.toLowerCase().includes(text.toLowerCase()) 
        )
        set(newData)
      }else{
        set(List)
      }
    }

    const ObjList = () => {
      var lisss = ''
      filters.map((o,i)=> {
        if(i == 0){
          lisss += o 
        }else{
          lisss += ','+o 
        }
      })

      return lisss
    }

    if(!List) return null

    if(error) return <Text style={{color:COLORS.Error}}>{error}</Text>
    
    
    return (
        <View>
          <TextInput
            placeholder={'Search... '+ObjList()}
            placeholderTextColor="#ddd"
            value={search} 
            onChangeText={(val) => Search(val)}
          />
          </View>

    )



}

const styles = StyleSheet.create({

});
