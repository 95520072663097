import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,Text, Image} from 'react-native';
import {GetItemDetails ,GetProductsStock,deleteStock} from "../../../assets/js/firebase";
import Table from './table';
import AddStock from './add';
import Label from './label';
import moment from 'moment';

export default function ProductsStockScreen({route,navigation}) {
  const {pid} = route.params;


  const [products, setProducts] = useState(null);
  const [list, setList] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [removeLoading, setRemoveLoading] = useState(null);
  const [lastSupplier, SetLastSupplier] = useState(null);


  useEffect(() => {
    getList()
  }, [pid])

  

  async function getList(){

      const ProductsDetail = await GetItemDetails(pid,'products')
      setProducts(ProductsDetail)

      const ListStock = await GetProductsStock(pid)
      if(ListStock.length){
        SetLastSupplier(ListStock[0])
      }
      setIsloading(false)
      setList(ListStock)

  };


  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY HH:MM A');
    return format
}



  function StockNav(id){
    console.log(id)
  }

  function PushItem(data){
    const Newstock = parseInt(products.stock) + parseInt(data.stock)
    setProducts({...products,stock: Newstock})
    setList(oldArray => [data,...oldArray]);
  }

  function Delstock(data){
      setRemoveLoading(data.id)
      const status = deleteStock(data)
      if(status){RemoveData(data)}
  }

  function RemoveData(data){
    const newList = list.filter((item) => item.id !== data.id);
    setList(newList);

    const Newstock = parseInt(products.stock) - parseInt(data.stock)
    setProducts({...products,stock: Newstock})
    setRemoveLoading(null)

  }

  const ProductImg = (img) => {
    if(!img) return null
    return (
      <View>
        <Image style={styles.ProductImg} source={{uri: img,}}/>
      </View>
    )
  }


  if(!products) return null

  return (
    <View style={styles.container}>
      <View style={{flexDirection:'row'}}>
        <View style={{flex:1}}>
          <Text>Product : {products.name}</Text>
          <Text>Desc : {products.desc}</Text>
          <Text>current Stock : {list.reduce((n, {stock}) => n + stock, 0)}</Text>
          <Text>Created At : {ReformatTime(products.createdAt)}</Text>
        </View>
        {ProductImg(products.image)}
      </View>
      <View style={{flexDirection:'row'}}>
        <AddStock add={PushItem} pid={pid} pname={products.name} lastsupplier={lastSupplier}/>
        <Label item={products} />
      </View>
      <Table 
        setList={setList} 
        list={list} 
        loading={isLoading}
        nav={StockNav}
        del={Delstock}
        removeLoading={removeLoading}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  ProductImg: {
    width: 100,
    height: 100,
  },
});
