import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,ScrollView} from 'react-native';
import {GetDeliveryDocs} from "../../../assets/js/firebase";
import {SearchBox} from "../../../assets/component";
import Tab from './tab';
import Footer from './footer';
import Table from './table';


export default function ProductsScreen({navigation}) {


  const isMounted = useRef();

  const DafaultTab = {pending:[],paid:[]}


  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [picked, setPicked] = useState([])


  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      if(list[tabactive].length == 0){
        setIsloading(true)
        getList(tabactive);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [tabactive]);


  


  async function getList(active){

      const ListProduct = await GetDeliveryDocs(active)
      if(isMounted.current){
        setList({...list, [active]:ListProduct});
        setIsloading(false)
      }
  };

  function UpdateData(data){
    const dataArray = [ ...list[tabactive]]
    data.ids.forEach(el => {
      let index = dataArray.findIndex(d=> d.id === el)
      dataArray.splice(index, 1);
    });
    setPicked([])
    setList({...list, [tabactive]:dataArray})
    
  }
  





  return (
    <View style={{flex:1}}>
        <View style={styles.container}>
              <View style={styles.tabBx}>
                <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} list={list}/>

                {!isLoading && <SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['name', 'id']}/>}
              </View>
              <ScrollView style={{paddingBottom:100}}>
              <Table 
                tab={tabactive} 
                setList={setList} 
                list={list} 
                loading={isLoading}
                setPicked={setPicked}
                picked={picked}
              />
              </ScrollView>
        </View>
        <Footer picked={picked} update={(val)=>UpdateData(val)}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
