import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator } from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {Modal} from "../../../../assets/component";
import SearchBox from "./searchbox";
import {GetCreditList} from "../../../../assets/js/firebase";
import COLORS from "../../../../assets/component/colors";


export default function SupplierList(props) {

    const {SetSupplierData,supplier} = props

    const [isLoading, setIsloading] = useState(true);
    const [kastamlist, setKastamList] = useState([]);

    const [visible, setVisible] = useState(false);


    useEffect(() => {

        GetData()

    }, []);





    async function GetData(){


            const response = await GetCreditList('2024','Supplier')

          setKastamList(response.list);
          setIsloading(false)
    };

    async function GetPrev(){
        const Prv =  moment().subtract(1, 'year').format('YYYY')
        const response = await GetCreditList(Prv,'Supplier')
        setKastamList([ ...kastamlist, ...response.list ])
    }

const ReformatTime = (data) => {
        if(!data) return null
        const format = moment(data.toDate()).format('DD-MM-YYYY');
        return format
}

function AddItem(item){
    SetSupplierData([item,...supplier])
    setVisible(false)
    const NewArray = kastamlist.filter(kastamlist => kastamlist.id !== item.id);
    setKastamList(NewArray)
}

function clearItem(item){
    const NewOrder = [...supplier]
    const result = NewOrder.filter(e => e.id !== item.id);
    SetSupplierData(result)
}


const RenderItem = ({item}) => {
    item['paid'] = item.paid?item.paid:0
    return(
      <TouchableOpacity style={{borderBottomWidth:1,padding:5,borderColor:'#aaa'}}  onPress={()=>AddItem(item)}>

        <View style={{flexDirection:'row'}}>
            <View style={{flex:1}}>
                    <Text style={[styles.item,{flex:1}]}>#{item.id}</Text>
                    <Text style={styles.item}>{item.category.name}</Text>
                    <Text style={styles.item}>{item.remark}</Text>
                    <Text style={styles.rmk}>{ReformatTime(item.date)}</Text>
            </View>
            <View>
                <Text style={[styles.item,{color:'#3176ff'}]}>RM{parseFloat(item.amount).toFixed(2)}</Text>
            </View>
        </View>

      </TouchableOpacity>
    )
}


const RenderSelected = ({item}) => {
    return(
      <View style={styles.sbox} >

            <View style={{flex:1,marginHorizontal:5}}>
                    <Text style={styles.item}>{item.category.name}</Text>
                    <Text style={styles.rmk}>{ReformatTime(item.date)}</Text>
                </View>
            <View>
                <Text style={[styles.item,{color:'#3176ff'}]}>RM{parseFloat(item.amount).toFixed(2)}</Text>
            </View>
            <TouchableOpacity onPress={()=>clearItem(item)}>
                              <Ionicons name='close' size={18} color={COLORS.Error} />
            </TouchableOpacity>

      </View>
    )
}

const ItemList = () => {
    if(isLoading) return <ActivityIndicator size={'small'}/>
    return (
        <FlatList
        showsHorizontalScrollIndicator={false}
        data={kastamlist}
        renderItem={RenderItem}
        onEndReachedThreshold={0}
        />
    )
    }

    const ListItem = () => {
        if(isLoading) return <ActivityIndicator size={'small'}/>
        return (
            <FlatList
            showsHorizontalScrollIndicator={false}
            data={supplier}
            renderItem={RenderSelected}
            onEndReachedThreshold={0}
            />
        )
    }


    function sumBy(arr){
        return arr.reduce((sum, { amount }) => sum + parseFloat(amount), 0).toFixed(2);
      }



    return (
        <View>
            <View style={{flexDirection:'row'}}>
                <Text style={styles.h1}>SUPPLIER BILL</Text>
                <TouchableOpacity style={styles.morebtn} onPress={()=>setVisible(true)}>
                        <Ionicons name='add' size={18} color={'blue'} />
                        <Text style={{color:COLORS.Link}}>Add</Text>
                </TouchableOpacity>
            </View>
            <ListItem/>
            <View style={{flexDirection:'row',marginHorizontal:5}}><Text style={{flex:1}}>TOTAL:</Text><Text style={styles.sum}>RM{sumBy(supplier)}</Text></View>
            <Modal title={'Choose'} icon={'ios-receipt'} visible={visible} setVisible={setVisible} headColor={'#428ff5'}>
                <SearchBox set={setKastamList} products={kastamlist} filters={['name', 'id']}/>
                <ItemList/>
                <TouchableOpacity onPress={()=>GetPrev()}><Text style={{color:'blue'}}>Previous Year</Text></TouchableOpacity>
            </Modal>
        </View>
    )

sum
}

const styles = StyleSheet.create({
    item:{
        fontSize:14
    },
    rmk:{
        fontSize:12,
        color:'#aaa'
    },
    sum:{
        color:'#a52',
        fontWeight:'500'
    },
    h1:{
        fontSize:22,
        fontWeight:'500'
      },
      morebtn:{
        marginVertical:4,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        padding:4,
        flexDirection:'row'
    },
    sbox:{
        borderWidth:1,
        padding:4,
        borderRadius:5,
        backgroundColor:'#fafafa',
        borderColor:'#ccc',
        flexDirection:'row',
        marginBottom:4
    },
          morebtn:{
        marginVertical:4,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        padding:4,
        flexDirection:'row'
    },
});
