import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator} from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../../assets/component/textinput";
import Modal from "../../../assets/component/modal";
import Button from "../../../assets/component/button";
import DatePicker from "../../../assets/component/datepicker";
import FileUpload from './modal/filesupload';
import {UploadData} from "../../../assets/js/upload";
import {updateImageUrl,UpdateCredit,deleteCredit,AddCredit} from "../../../assets/js/firebase";


export default function Edit(props) {

    const {data,clear,update} = props


    const [isLoading, setIsloading] = useState(false);

    const [amount, onAmount] = useState(0);
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [dated, onDated] = useState("");
    const [error, setError] = useState("");



    useEffect(() => { 
        if(data){
            onAmount(data.amount)
            setImage(data.image)
            onDated(ReformatTime(data.date))
        }
    }, [data]);





    





    async function Validate(data){
        setError('')
        var valid = true;
        var Cat = null;


        if(!dated || dated.error){
            valid=false
        }

        if(amount <= 0 || isNaN(amount) || amount.error){
            onAmount({error:"Amount must not be 0"});
            valid=false
        }
   
        if(valid){
            setIsloading(true)

            var Increment = (parseFloat(amount) - parseFloat(data.amount))
            const YearDir = moment(data.date.toDate()).format('YYYY')
            const yearNow = moment(dated,'DD-MM-YYYY').format('YYYY')

            if(YearDir != yearNow){
                await deleteCredit(data) 
                data['amount'] = amount
                data['date'] = dated
                await AddCredit(data)
            }else{
                data['amount'] = amount
                data['date'] = dated
                await UpdateCredit(data,Increment)
            }

        
            if(file) {

                const DownloadUrl = await UploadData(data.id,file,'credits') 
                if(DownloadUrl){
                    setIsloading(false)
                    updateImageUrl('credits',yearNow,data.id,DownloadUrl)
                    data['image'] = DownloadUrl
                    Success(data)

                }
                
            }else{
                Success(data)
            }
        }

    }

 
    function Success(data){
        setIsloading(false)
        update(data)
        onAmount("")
        setFile("")
        clear()
    }



    const FileView = (name) => {
        return (
            <TouchableOpacity style={{flexDirection:'row',marginVertical:10}} onPress={()=>setImage(null)}>
                <Ionicons name='attach-file' size={22} color={'#106ac4'} />
                <View style={{flex:1,flexShrink:1,paddingHorizontal:5}}>
                    <Text>{name}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    const InputView = () => {
        return (
            <FileUpload value={file} set={setFile} />
        )
    }


    const ReformatTime = (data) => {
        if(!data) return null
        return moment(data.toDate()).format('DD-MM-YYYY');
    }



    const Popup = () => {
        if(!data) return null

        const amtEditted = amount == data.amount?null:'#1196ab'
        const dateEditted = dated == data.credited_date?null:'#1196ab'

        return (
                <Modal title={data.category.name} visible={data?true:false} setVisible={clear}>
                        <View >
                            <Text>{error}</Text>
                            <TextInput txtcolor={amtEditted} title={'Amount'} onChangeText={onAmount} value={amount} keyboardType="decimal-pad" maxLength={13} remark={"(RM)"}/>
                            <DatePicker set={onDated} value={dated} txtcolor={dateEditted}/>
                            {image?FileView(data.image):InputView()}
            
                        </View>
                        {!isLoading?<Button title={'UPDATE'} color={'#056a7a'} icon={'md-add-circle'} onPress={() => Validate(data)}/>:<ActivityIndicator size={'small'}/>}
                </Modal>
        )
    }
    


    return(
        <View>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
  });
  