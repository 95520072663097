import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView, ActivityIndicator} from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import moment from 'moment';
import {TextInput,Modal,Button,ListDownoverlay,DatePicker} from "../../../../../assets/component"
import COLORS from "../../../../../assets/component/colors";
import {AddItem,updateImageLink} from "../../../../../assets/js/firebase";
import {UploadData} from "../../../../../assets/js/upload";



export default function AddNewDocument(props) {

    const {add,pid,LeaveCat} = props

    const category = ['annual','medical']


    const [data, onData] = useState({});


    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState("");

    
    const [file, setFile] = useState("");
    const [percent, setPercent] = useState(0);

    const Days = [];
    for(let i = 1; i <= 30; i++) {
        Days.push(i);
    }


    useEffect(() => {
        if(modalVisible){
            setFile("")
            onData({ ...data, ['category']: LeaveCat,['date']:moment().format("DD-MM-YYYY"),['days']:1});
        }

    }, [modalVisible]);


    function handleChange(event) {
        setPercent(0)
        if (event.target.value.length == 0) return null
            if(event.target.files[0].type.split('/')[0] === 'image'){
                Compress(URL.createObjectURL(event.target.files[0])) 
            }else{
                setFile(event.target.files[0])
            }
    }

    const Compress = async (uri) => {
        const manipResult = await ImageManipulator.manipulateAsync(
            uri,
            [],
            { compress: 0.1 ,base64:true}
      );
      setFile(manipResult.uri)
    };


    async function Validate(){

        const valid = Validation(['category','date','days']);

        setError('')


        if(valid){
            setIsloading(true)
            const Year = moment(data.date,"DD-MM-YYYY").year()
            const Route = 'staff/'+pid+'/leave'+'/'+Year+'/'+data.category
            const DocId = await AddItem(data,Route);
            console.log(DocId)
            if(DocId){
                if(file){
                    const UploadedURL  = await UploadData(DocId.id,file,Route)
                    console.log(Route+'/'+DocId.id)
                    await updateImageLink(Route+'/'+DocId.id,UploadedURL)
                    DocId['image'] = UploadedURL
                }
                Success(DocId)
            }
        }else{
            setError("Fill All")
        }
    
    
    }

    function Validation(obj){
        var validated = true
        obj.map((name)=>{
            if(!data[name] || data[name].error){
                onData((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
                validated = false
            }
        })
        return validated
    }

      
      function Success(data){
        setIsloading(false)
        setModalVisible(false)
        add(data)
      }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }

    const onChangeHandler = (e,name) => {
        const Value = e.target?e.target.value:e
        onData((prevState) => ({ ...prevState, [name]: Value }));
    };

    const InputVal = (title,val,txt) => {
        const inputVal = data && data[val] ? data[val]:''
        return (
                        <TextInput
                            title={title}
                            placeholder={title}
                            keyboardType={!txt&&'decimal-pad'} 
                            maxLength={44}
                            onChange={e => onChangeHandler(e,val)}
                            value={inputVal}
                            />
        )
    }

    const InputDate = (title,val) => {
        const inputVal = data && data[val] ? data[val]:''
        return (

            <DatePicker set={e => onChangeHandler(e,val)} value={inputVal} title={title}/>
        )
    }



    const Popup = () => {

    
        return (
            <Modal title={'Add Leave'} icon={'calendar'} visible={modalVisible} setVisible={setModalVisible}>
                <ScrollView >
                        <ListDownoverlay title={'Category'} list={category} active={LeaveCat} onPress={(val)=>onChangeHandler(val,'category')}/>
                        <input type="file" onChange={handleChange} />
                        {InputDate('Form Date','date')}
                        <ListDownoverlay title={'Days'} list={Days} active={data['days']?data['days']:1} onPress={(val)=>onChangeHandler(val,'days')}/>
                        {InputVal('Remarks','remark',true)}
                        <Error error={error}/>
                        {!isLoading?<Button title={'ADD'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
                </ScrollView>
            </Modal>
        )
    }
    


    return(
        <View style={styles.container}>
                <Button title={'ADD '+LeaveCat} icon={'calendar'} onPress={() => setModalVisible(true)} color={LeaveCat == 'annual'?'#04b8a9':'#ff5b5b'}/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      }
  });
  