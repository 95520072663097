import React, { useState,useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { ActivityIndicator, Platform, StyleSheet,TouchableOpacity,FlatList,Text,View } from 'react-native';
import Button from "../../../assets/component/button";
import {deleteDebit,GetDebit,GetDocsByids} from "../../../assets/js/firebase";
import PDFile from './pdf_receipt';
import moment from 'moment';
import { PDFDownloadLink,PDFViewer} from "@react-pdf/renderer";
import COLORS from '../../../assets/component/colors';
import {GETACC} from '../../../assets/js/getacc';


export default function ModalScreen({route,navigation}) {

  const {year,bill} = route.params;

  const [isLoading, setIsloading] = useState(false);
  const [billdetail, setBillDetail] = useState(null);
  const [user, setUser] = useState([]);
  const [shopee, setShopee] = useState([]);



  useEffect(() => {
    getList(year.toString(),bill.toString())

  }, []);


  async function getList(year,id){
    const user = await GETACC();
    setUser(user)
    setIsloading(true)
    const datas = await GetDebit(year,id)

    // If shopee Orders
    if(datas.shopee_orders){
      const ShopeeList = await GetDocsByids('online_order',datas.shopee_orders)
      setShopee(ShopeeList)
    }

    setBillDetail(datas);
    setIsloading(false)
  };


  async function DeleteDeb(data){
    if (confirm('Are you sure you want to delete ?')) {
      setIsloading(true)
      const successs = await deleteDebit(data)
      if(successs){
        navigation.navigate('account', {delete: data.id})
      }else{
        setIsloading(false)
      }
    } else {
      console.log('Cancel Delete');
    }
  }

  if(isLoading){
    return <ActivityIndicator size={33} />
  }

  if(!billdetail){
    return null
  }





  const ReceiptDetails = (data) => {
    if(isLoading){
      return (
        <View style={{flex:1,alignContent:'center',alignItems:'center',justifyContent:'center'}}>
          <ActivityIndicator size={30}/>
        </View>
      )
    }
    return (
      <View>
          <View style={{flexDirection:'row'}}>
              <View style={{marginRight:10}}>
                  <Text>Receipt ID</Text>
                  <Text>Order ID</Text>
                  <Text>Amount</Text>
                  <Text>Payment</Text>
                  <Text>Date</Text>
              </View>
              <View style={{flex:1}}>
                  <Text> : {data.id}</Text>
                  {LinkOrder(data.orderid)}
                  <Text> : RM{parseFloat(data.amount).toFixed(2)}</Text>
                  <Text> : {data.payment_method}</Text>
                  <Text> : {ReformatTime(data.date)}</Text>
              </View>
          </View>
        {data.image?<a href={data.image} target="_blank">View slip</a>:null}
      </View>
    )
  }

  const LinkOrder = (text) => {
    if(!text) return null
    if(Array.isArray(text)){
        return (
          <View style={{flexDirection:'row'}}>
            <Text style={{color:COLORS.Link}}> :</Text>
            {text.map((item,i)=>{
              return (
                  <TouchableOpacity key={i} onPress={()=>navigation.navigate('invoice', {bill: item})}>
                  <Text style={{color:COLORS.Link}}> {item}</Text>
                  </TouchableOpacity>
              )
            })}
          </View>
        )
    }
    
    return (
    <TouchableOpacity onPress={()=>navigation.navigate('invoice', {bill: text})}>
      <Text style={{color:COLORS.Link}}> : {text}</Text>
    </TouchableOpacity>
    )
  }

  const Print = (item) => {
    return (
      <PDFDownloadLink document={<PDFile details={item} user={user}/>} fileName={'receipt_'+bill}>
         {({loading})=>
          <Button icon={'print'} disable={loading} style={{marginRight:5}} title={'Print'} color={loading?'#aaa':'#7a5'} />
        }
      </PDFDownloadLink>
    )
  }
  

  const PdfView = (item) => {
    return (
      <PDFViewer style={{height:600}}>
       <PDFile details={item} user={user}/>
      </PDFViewer>
    )
  }

  const H1 = (title) => {
    return  <View style={{paddingBottom:5,borderBottomWidth:1,marginBottom:5,borderColor:COLORS.Background}}><Text style={{fontWeight:'500',fontSize:16,color:COLORS.Link}}>{title}</Text></View>
   }


   const RenderShopee = ({item,index}) => {
    return(
        <View  style={{flexDirection:'row',padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
                <View style={{flex:2}}><Text>#{item.orderid}</Text></View>
                <View style={{flex:1}}><Text>RM{parseFloat(item.income).toFixed(2)}</Text></View>
                <View style={{flex:2}}><Text>{ReformatTime(item.createdAt)}</Text></View>
        </View>
    )
  }

  
  const Shoperorder = () => {
    if(!shopee.length) return null
      return ( 
        <View>
          {H1('SHOPEE ORDERS')}
          {Header()}
          <FlatList
                showsHorizontalScrollIndicator={false}
                data={shopee}
                renderItem={RenderShopee}
                style={{marginBottom:100}}
              />
        </View>
        )
  }

  const Header = () => {
    return(
      <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
        {Th('OrderID',2)}
        {Th('Amount',1)}
        {Th('Date',2)}
      </View>
    )
  }

  const Th = (title,size) => {
    return(
        <View style={{flex:size}}>
            <Text style={styles.item}>{title}</Text>
        </View>

    )
}
  

  
  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY');
    return format
}




  return (
    <View style={styles.container}>

      <View style={{flexDirection:'row'}}>
          <Button icon={'chevron-back'} style={{marginRight:5}} color={'#aaa'} onPress={() =>  navigation.navigate('account',{tab:'debit'})}/>
          {Print(billdetail)}
          <View style={{flex:1}}/>
          <Button icon={'trash-bin'} title={'Delete'} color={'#aaa'} onPress={() => DeleteDeb(billdetail)}/>
      </View>
          {PdfView(billdetail)}
      <Text style={{fontWeight:'500',fontSize:18}}>RECEIPT DETAILS</Text>
          {ReceiptDetails(billdetail)}
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
          {Shoperorder()}

      

     
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  paymentList:{
    padding:5,
    borderRadius:5
  }
});
