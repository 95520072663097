import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';
import Sample from './sample';
import Box from './box';
import Modal from "../../../../assets/component/modal";
import Button from "../../../../assets/component/button";


export default function LabelBtn(props) {

    const {item} = props

    const [modalVisible, setModalVisible] = useState(false);
    
  const LabelType = ['Box','Sample']

  const [type, OnType] = useState(null);



    useEffect(() => {
        OnType(null)
    
      }, [modalVisible]);
    
    const Popup = () => {
        return (
            <Modal title={'Label'} icon={'md-print'} visible={modalVisible} setVisible={setModalVisible} width={250}>
                <Container/>
            </Modal>
        )
    }

    const Container = () => {
        if(type == 'Box') return <Box item={item}/>
        if(type == 'Sample') return <Sample item={item}/>

        return (
            <View>
              {LabelType.map((v) => {
                return (
                    <TouchableOpacity onPress={()=>OnType(v)} style={styles.btn} key={v}>
                          <Text>{v}</Text>
                    </TouchableOpacity>
                )
              })}
            </View>
          ) 
    }
    

    
    


    return(
        <View style={styles.container}>
                <Button title={'Label'} icon={'md-print'} onPress={() => setModalVisible(true)} color={'#04b8a9'}/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flex:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
    },
    btn:{
        padding:10,
        borderRadius:5,
        borderColor:'#aaa',
        borderWidth:1,
        marginBottom:2
      }
  });
  