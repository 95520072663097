import React, { useState,useEffect } from 'react';
import { StyleSheet ,View} from 'react-native';
import {Table} from "../../../assets/component";


export default function PaymentTable(props) {

  const {tab,setList,list,loading,setPicked,picked,next} = props


  const [sortasc, setSortAsc] = useState(true);


  useEffect(() => {

    setPicked([])
    
  }, [tab]);


    const PaidId = tab == 'paid'?{ name: 'paid_id',label:'Credit ID', width: 80 }:{ name: 'status', width: 80 ,icon:{'pending':'progress-clock','delivered':'check'} }

    return(
      <View style={styles.container}>
        <Table 
              data={list[tab]} 
              colSettings={[
                [{ name: 'name', flex: 2 },{ name: 'id',color:'#1d8cff' },{name:'delivery',color:'#aaaa'}], 
                { name: 'mobile', flex: 1 }, 
                [PaidId,{ name: 'createdAt',style:{fontSize:10,color:'#aaa'}}]
              ]}
              excluded={['pending']}
              picked={picked}
              tab={tab}
              setPicked={setPicked}
              onEndReached={next}
              isloading={loading}
        />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  addmore:{
    borderRadius:5,
    marginVertical:5,
    padding:5,
    backgroundColor:'#75a7ff',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
  },
});
