import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View } from 'react-native';
import {Text,PopMenu} from "../../../assets/component";
import {GetDocument,UpdateItemStatus} from "../../../assets/js/firebase";
import { TouchableOpacity } from 'react-native-gesture-handler';
import Ionicons from '@expo/vector-icons/Ionicons';
import Details from './details';
import UpdateStaff from "../add";


export default function StaffPortal({route,navigation}) {

  const {pid} = route.params;

  const isMounted = useRef();

  const [item, setItem] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [update, setUpdate] = useState(null);

  const IconBtn = (name,icon,nav) => {
    return (
      <TouchableOpacity style={styles.iconbx} onPress={()=>navigation.navigate(nav, {'pid':pid})}>
        <Ionicons name={icon} size={28} color='#4a8eb5' />
        <Text>{name}</Text>
      </TouchableOpacity>

    )
  }



  useEffect(() => {
    getStaff(pid)
  }, []);
  
  async function getStaff(id){

    const datas = await GetDocument(id,'staff')
    setIsloading(true)
    if (datas) {
      setItem(datas);
      setIsloading(false)
    } else {
      console.log("No such document!");
      setIsloading(false)
    }
};



const BackBtn = () => {
  return (
    <View>
    <TouchableOpacity style={styles.backbtn} onPress={()=>navigation.goBack()}>
      <Ionicons name={'chevron-back-outline'} size={28} color='#4a8eb5' />
      <Text>BACK</Text>
    </TouchableOpacity>
    <PopMenu name={'Company'} menu={[<View><Text>dsd</Text></View>]} />
    </View>

  )
}

const MenuBar = () => {
  if(!item) return null
  const Status = item.status == 'inactive'?true:false
  return (
    <View style={{flexDirection:'row',zIndex:99}}>
          {Menu('chevron-back','',()=>navigation.goBack())}
        <View style={{flex:1}}/>
        <PopMenu name={'Company'} menu={[Menu('pencil','Edit',()=>setUpdate(item)),Menu('remove-circle',!Status?'Deactivate':'Activate',()=>UpdateStatus(item,Status))]} />
    </View>
  )

} 

const Menu = (icon,name,exc,loading) => {
  if(loading) return null
  return (
    <TouchableOpacity style={styles.menulist} onPress={exc}>
        <Ionicons name={icon} size={18} color={'#aaa'} /> 
        <Text style={[styles.menutext]}>{name}</Text>
    </TouchableOpacity>
  )
 }


 function UpdateStatus(data,stat){
  const status = stat?'active':'inactive'
  data['status'] = status
  UpdateItemStatus(data.id,status,'staff')
  navigation.navigate('staff')
}


  return (
    <View style={styles.container}>
          <MenuBar/>
          <Details data={item}/>
          <View style={styles.iconContainer}>
            {IconBtn('Claims','receipt','Staff_Details')}
            {IconBtn('Payroll','cash','Staff_Payroll')}
            {IconBtn('Document','document','Staff_Documents')}
            {IconBtn('Leave','calendar','Staff_Leave')}
          </View>
          <UpdateStaff updateData={update} clear={setUpdate} update={setItem}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff',
  },
  iconContainer: {
    padding:10,
    backgroundColor:'#fff',
    flexDirection:'row',
    flexWrap:'wrap'
  },
  iconbx:{
    borderWidth:1,
    borderRadius:5,
    padding:10,
    height:100,
    width:100,
    margin:10,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    borderColor:'#bbb'
  },
  backbtn:{
    borderRadius:5,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  menulist:{
    width:110,
    padding:10,
    flexDirection:'row'
  },
  menutext:{
    paddingLeft:5,
    textTransform: 'capitalize',
    fontSize:12,
    color:'#777'
  },
});
