import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,Text, ActivityIndicator,TouchableOpacity} from 'react-native';
import {GetAllDocs,GetItemDetails,DeleteStaffDoc} from "../../../../../assets/js/firebase";
import moment from 'moment';
import Ionicons from '@expo/vector-icons/Ionicons';
import SearchBox from "../../../../../assets/component/searchbox";
import Button from "../../../../../assets/component/button";
import AddDocument from './add';
import Table from './table';
import { ScrollView } from 'react-native-gesture-handler';


export default function DocumentsScreen({route,navigation}) {
  const {pid} = route.params;

  const isMounted = useRef();


  const [item, setItem] = useState(null);
  const [listBase, setListBase] = useState([]);
  const [list, setList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [statusloading, setStatusloading] = useState(false);
  const [picked, setPicked] = useState([]);


  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      if(listBase.length == 0){
        setIsloading(true)
        getList();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
   GetStaff()
  }, []);

  async function GetStaff() {
    const ProductsDetail = await GetItemDetails(pid,'staff')
    if(ProductsDetail){
      setItem(ProductsDetail)
    }
    
  }
  


  async function getList(){

      const ListProduct = await GetAllDocs('staff/'+pid+'/document')
      if(isMounted.current){
        setList(ListProduct);
        setListBase(ListProduct);
        setIsloading(false)
      }
  };


  function PushItem(data){
    const dataArray = [ ...list]
    dataArray.unshift(data)
    setList(dataArray);

  }

  function StockNav(data){
    navigation.navigate('stock', {pid:data.id})
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY HH:MM A');
    return format
}


async function Delete(data){

  if (confirm('Are you sure you want to delete ?')) {
    setStatusloading(true)
    await DeleteStaffDoc(pid,data)
    UpdateStatus(data)
  } else {
    console.log('Cancel Delete');
  }
}


function UpdateStatus(data){
    const Pending = list.filter(item => !data.some(itemToBeRemoved => itemToBeRemoved.id === item.id))
    setList(Pending);
    setPicked([])
    setStatusloading(false)
}

const BackBtn = () => {
  return (
    <TouchableOpacity style={styles.backbtn} onPress={()=>navigation.goBack()}>
      <Ionicons name={'chevron-back-outline'} size={28} color='#4a8eb5' />
      <Text>BACK</Text>
    </TouchableOpacity>

  )
}


const Footer = () => {
  if(!picked.length) return null

  return (
    <View style={{flexDirection:'row',padding:10,backgroundColor:'#eee',position:'absolute',width:'100%',bottom:0}}>
      <View style={{flex:1}}>
          <View><Text>TOTAL : {picked.length}</Text></View>
      </View>
      <Button title={'Delete'} onPress={()=> Delete(picked)} color={'#fc3063'} style={{marginRight:10}} icon={'close-outline'}/>
    </View>
  )
}

if(isLoading){
  return (
  <View style={styles.center}><ActivityIndicator size={'small'}/></View>
  )
}

if(!item){
  return <View style={styles.center}><Text>Item not found</Text></View>
}




  return (
    <View style={{flex:1}}>
    <ScrollView style={styles.container}>
      <View>
      <BackBtn/>
          <Text>Name : {item.name}</Text>
          <Text>Mykad : {item.mykad}</Text>
          <Text>Position : {item.position}</Text>
          <Text>Bank : {item.bank}</Text>
          <Text>Acc no : {item.account}</Text>
          <Text>Created At : {ReformatTime(item.createdAt)}</Text>
      </View>
      <View style={{flexDirection:'row'}}><AddDocument add={PushItem} pid={pid}/></View>
      <View style={styles.tabBx}>

        <View style={{flex:1,borderBottomWidth:0.5,borderColor:'#ccc'}}/>
        <SearchBox set={(val)=>setList(val)} products={list} filters={['name']}/>
      </View>
      <Table 
        setList={setList} 
        list={list} 
        loading={isLoading}
        statusloading={statusloading}
        nav={StockNav}
        setPicked={setPicked}
        picked={picked}
      />




    </ScrollView>
    <Footer/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  center:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    flex:1
  },
  backbtn:{
    borderRadius:5,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  }
});
