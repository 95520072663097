
const COLORS = {
    Primary:'#e05600',     
    Secondary: '#eee',
    Background:'#eee',
    Link:'#004ed4',
    Tab:"#aaa",
    Text:'#444',
    Chat:'#e3e3e3',
    dark:'#666',
    Error:'#dc3545',
}
  
export default COLORS