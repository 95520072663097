import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View , Text,TextInput} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Tab from './tab';
import Editor from './editor';
import {Header,Title,Content} from './comp';



export default function ViewerScreen({navigation}) {

  const DafaultTab = {editor:[],viewer:[]}


  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0])
  const [value, onChangeText] = useState('Useless Multiline Placeholder');

  useEffect(() => {

  }, []);





  function isJSON(str) {
    try {
        const StringFil = str.replace(/(\r\n|\n|\r)/gm, "")
        return JSON.parse(StringFil);
    } catch (e) {
        return false;
    }
}


  const ViewerBx = () => {
    const ParseData = isJSON(value)
    return (
      <ScrollView style={styles.container}>
        <Header/>
            <Title data={ParseData.title}/>
            <Content data={ParseData}/>
            <Text>dsdd</Text>
      </ScrollView>
    )
  }

  

  return (
    <View style={{flex:1}}>
      <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} />
      {tabactive == 'viewer' ? <ViewerBx/> : <Editor set={onChangeText} value={value}/> }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:45,
    borderWidth:1,
    borderColor:'#aaa'
  },
  input: {
    flex:1,
    outlineStyle: 'none'
  },
});
