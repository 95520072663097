import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text} from 'react-native';
import moment from 'moment';

export default function Details(props) {

  const {item,days,total} = props

  useEffect(() => {


  }, []);

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY HH:MM A');
    return format
}

    return(
      <View style={styles.container}>
        <View style={styles.details}>
            <Text>LEAVE : {item.name}</Text>
            <Text>Mykad : {item.mykad}</Text>
            <Text>Position : {item.position}</Text>
            <View style={{flexDirection:'row'}}>
              <Text>Total : </Text>
              <Text style={styles.days}>{total}</Text>
            </View>
        </View>
        <View style={styles.daysbx}>
          <Text>Remaining</Text>
          <Text style={styles.remain}>{total-days}</Text>
        </View>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
    marginBottom:20,
    flexDirection:'row'
  },
  details:{
    flex:1
  },
  daysbx:{
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    padding:10
  },
  days:{
    color:'blue',
    marginLeft:10,
  },
  remain:{
    fontSize:35,
    fontWeight:'500',
    color:'#07b8a9'
  }

});
