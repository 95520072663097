import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View} from 'react-native';
import {GetAllDocs} from "../../../assets/js/firebase";
import {SearchBox} from "../../../assets/component";
import Tab from './tab';
import Table from './table';


export default function ProductsScreen({navigation}) {


  const isMounted = useRef();

  const DafaultTab = {active:[],inactive:[]}


  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);


  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      if(list[tabactive].length == 0){
        setIsloading(true)
        getList(tabactive);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [tabactive]);
  


  async function getList(active){

      const ListProduct = await GetAllDocs('transporter_list',active)
      if(isMounted.current){
        setList({...list, [active]:ListProduct});
        setIsloading(false)
      }
  };





  return (
    <View style={styles.container}>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} list={list}/>

        {!isLoading && <SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['name', 'id']}/>}
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        loading={isLoading}
      />




    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
