import React, { Component } from "react";
import {View,TextInput,StyleSheet} from "react-native";
import {Text} from "../../../../assets/component";
import COLORS from "../../../../assets/component/colors";
import Ionicons from '@expo/vector-icons/MaterialIcons';


export default function TextInputCustom(props) {

    const {value,title,txtcolor,placeholder,onblur,currency,disable} = props;

    const bgcolor = disable?'#eee':'#fff'

    return (
      <View style={[styles.input,{backgroundColor:bgcolor}]}>
        {currency && <Text>{currency}</Text>}
        <TextInput onBlur={onblur} {...props} placeholderTextColor="#cccc" placeholder={placeholder?placeholder:title} value={value.toString()} style={{color:COLORS.Text,outlineStyle: 'none'}}/>
      </View>
    );

  }


    const styles = StyleSheet.create({
      input: {
        padding:5,
        fontSize:14,
        borderWidth:1,
        borderColor:'#aaa',
        borderRadius:5,
        outlineColor: COLORS.Primary ,
        margin:2,
        flexDirection:'row'
      },
      input2: {
        paddingHorizontal:7,
        fontSize:14,
        marginBottom:5,
        color:'#004ea5',
      },
      error:{
        fontSize:12,
      },
      placeView:{
        flexDirection:'row',
        alignItems:'baseline',
        alignContent:'center',
      },
      title:{
        marginBottom:5,
        color:COLORS.Text,
        paddingHorizontal:5
      },
      remark:{
        fontSize:11,
        fontWeight:'300',
        color:'#aaa'
      },
      errbox:{
        flexDirection:'row',
        textAlign:'center',
        alignItems:'center',
      }
    
});

  