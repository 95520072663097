import React, { useState,useEffect } from 'react';
import { getFirestore,onSnapshot,doc} from 'firebase/firestore';
import { StyleSheet,View, ActivityIndicator} from 'react-native';
import moment from 'moment';
import {GETACC} from '../../../assets/js/getacc';
import {GetCreditDebitsNext } from "../../../assets/js/firebase";
import Tab from './tab';
import Footer from './footer';
import Reports from './report';
import Table from './table';


export default function StatementScreen({route,navigation}) {


  const DafaultTab = {credit:[],debit:[]}


  const [year, setYear] = useState(moment().format("YYYY"));
  const [listBase, setListBase] = useState(DafaultTab);
  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [activeAcc, setActiveAcc] = useState(null);
  const [picked, setPicked] = useState([]);

  useEffect(() => {
    if(route.params){
      if(route.params.delete){
        RemoveData(route.params.delete)
      }
      if(route.params.tab){
        setTabActive(route.params.tab)
      }
    }
  }, [route.params]);
  
  useEffect(async () => {
    const getAccc = await GETACC()
    setActiveAcc(getAccc)
  }, []);





  async function RemoveData(id){
    const filteredData = listBase['debit'].filter(item => item.id !== id);
    setList({...listBase, ['debit']:filteredData});
  }






  if(!activeAcc){
    return <ActivityIndicator size={'small'}/>
  }

  function YearSelect(data){
    setIsloading(true)
    setYear(data)
    getList(tabactive,data);
  }

  const TabResult = () => {

    return(
      <View style={styles.container}>
          <View style={styles.tabBx}>
            <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab}/>
                <View style={{borderBottomWidth:1,borderColor:'#ccc'}}/>
          </View>
        <Table 
          tab={tabactive} 
          setList={setList} 
          list={list} 
          loading={isLoading}
          nav={navigation}
          setPicked={setPicked}
          picked={picked}
          />
          <Footer picked={picked} set={setPicked}/>
      </View>
    )
  }



  return (
    <View style={{flex:1}}>
      <Reports year={year} setyear={YearSelect} tabactive={tabactive} setdata={setList} data={list}/>
      <TabResult/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
