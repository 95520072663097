import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator,Image} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {TextInput,Modal,Button,ImagePicker} from "../assets/component";
import COLORS from "../assets/component/colors";
import {AddAccount,UpdateAccount} from "../assets/js/firebase";



export default function AddNewAccount(props) {

    const {add,close,update,account} = props

    const [isLoading, setIsloading] = useState(false);
    const [displayname, onDisplayName] = useState("");
    const [name, onName] = useState("");
    const [pic, onPic] = useState("");
    const [ssm, onSsm] = useState("");
    const [tel, onTel] = useState("");
    const [address, onAddress] = useState("");
    const [website, onWebsite] = useState("");
    const [bank, onBank] = useState("");
    const [accountno, onAccountno] = useState("");
    const [error, setError] = useState("");

    const [file, setFile] = useState("");

    
    useEffect(() => {

        if(account){
            setValue(onDisplayName,account.display_name)
            setValue(onName,account.name)
            setValue(onPic,account.pic)
            setValue(onSsm,account.ssm)
            setValue(onTel,account.tel)
            setValue(onAddress,account.address)
            setValue(onWebsite,account.website)
            setValue(setFile,account.logo)
            setValue(onWebsite,account.website)
            setValue(onBank,account.bank)
            setValue(onAccountno,account.acc)
            setValue(setFile,account.logo)
        }


    }, [account]);
    
    function setValue(set,val){
        if(val){set(val)}
    }


    async function Validate(){
      setError('')
      var valid = true;


      if(!name || name.error){
          onName({error:"Invalid Name"});
          valid=false
      }
      if(!ssm || ssm.error){
        onSsm({error:"Invalid Name"});
        valid=false
      }

      if(!tel || tel.error){
        onTel({error:"Invalid Name"});
        valid=false
      }
      if(!address || address.error){
        onAddress({error:"Invalid Name"});
        valid=false
      }


  
      if(valid){
        setIsloading(true)

        if(!update){
            const newid = await AddAccount({'display_name':displayname,'name':name,'pic':pic,'ssm':ssm,'tel':tel,'address':address,'website':website,'logo':file,'bank':bank,'acc':accountno})
            if(newid){
                setIsloading(false)
                add(newid)
            }
        }else{
            const status = await UpdateAccount(account.id,{'display_name':displayname,'name':name,'pic':pic,'ssm':ssm,'tel':tel,'address':address,'website':website,'logo':file,'bank':bank,'acc':accountno})
            setIsloading(false)
            add(status)
        }
      }

  }
  

  const Error = (props) => {
    if(!props.error) return null
    return (
        <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
    )
  }


  const Footer = () => {
    return (
        <View style={{flexDirection:'row-reverse'}}>
            {!isLoading?<Button title={update?'UPDATE':'CREATE'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
        </View>
    )
}

    return (
        <Modal title={update?'Update Account':'Create New'} icon={'person-add'} visible={true} setVisible={close} footer={Footer()}>
                    <View>
                            <View style={styles.row}>
                                <ImagePicker set={setFile} value={file} accept="image/*"/>
                                <View style={{width:10}} />
                                <TextInput onChangeText={onDisplayName}  title="Display Name" value={displayname} autoCapitalize="none"  errclear={true}/>
                            </View>
                            <View style={styles.row}>
                                <View style={{flex:2,marginRight:10}}><TextInput onChangeText={onName}  title="Company Name" value={name} autoCapitalize="none"  errclear={true}/></View>
                                <View style={{flex:1}}><TextInput onChangeText={onSsm} remark={' xxxxxx-a '}  title="SSM" value={ssm} autoCapitalize="none"  errclear={true}/></View>
                            </View>
                            <View style={{flexDirection:'row'}}>
                                <View style={{flex:1,marginRight:5}}><TextInput onChangeText={onPic}  remark={'person in charge'} title="PIC" value={pic} autoCapitalize="none"  errclear={true}/></View>
                                <TextInput onChangeText={onTel}  title="Tel" value={tel} autoCapitalize="none"  errclear={true}/>
                            </View>
                            <TextInput onChangeText={onAddress}  title="Address" value={address} autoCapitalize="none"  errclear={true}/>
                            <TextInput onChangeText={onWebsite}  title="Website" value={website} autoCapitalize="none"  errclear={true}/>
                            <View style={{flexDirection:'row'}}>
                                <View style={{flex:1,marginRight:5}}><TextInput onChangeText={onBank}  title="Bank" value={bank} autoCapitalize="none"  errclear={true}/></View>
                                <TextInput onChangeText={onAccountno} style={{flex:2}} title="Account" value={accountno} autoCapitalize="none"  errclear={true}/>
                            </View>
                            <Error error={error}/>
                    </View>
            </Modal>
    )

    

}

const styles = StyleSheet.create({
    row:{
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
    },
     
  });
  