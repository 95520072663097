import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator } from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../../assets/component/colors";
import {AddDebit,GetOrders,updateImageUrl } from "../../../../../assets/js/firebase";


export default function listpending(props) {

    const {changeHandler,OrderChoose} = props

    const [isLoading, setIsloading] = useState(true);
    const [orderlist, setOrderList] = useState([]);


    useEffect(() => {

            getPendingOrders()

    }, []);



    async function getPendingOrders(){

        const DATas  = await GetOrders('orders','pending',null)

          setOrderList(DATas.list);
          setIsloading(false)
    };





const RenderItem = ({item,index}) => {
    item['paid'] = item.paid?item.paid:0
    return(
      <TouchableOpacity style={{borderBottomWidth:1,padding:5,borderColor:'#aaa'}}  onPress={()=>OrderChoose(item)}>

        <View style={{flexDirection:'row'}}>
            <View style={{flex:1}}>
                    <Text style={[styles.item,{flex:1}]}>#{item.id}</Text>
                    <Text style={styles.item}>{item.name}</Text>
                    <Text style={styles.item}>{item.company}</Text>
            </View>
            <View>
                <Text style={styles.item}>TOTAL  : RM{parseFloat(item.amount).toFixed(2)}</Text>
                <Text style={[styles.item,{color:'red'}]}>PAID   : -RM{parseFloat(item.paid).toFixed(2)}</Text>
            </View>
        </View>

      </TouchableOpacity>
    )
}




    return (
        <View style={{flex:1}}>
            <TouchableOpacity style={{borderWidth:1,padding:5,borderColor:'#aaa',flexDirection:'row'}}  onPress={()=>OrderChoose('others')}>
                    <Ionicons name='add' size={18} color={COLORS.Error} />
                <Text>ADD OTHER</Text>
            </TouchableOpacity>

            <FlatList
                showsHorizontalScrollIndicator={false}
                data={orderlist}
                renderItem={RenderItem}
                onEndReachedThreshold={0}
            />
        </View>
    )




}

const styles = StyleSheet.create({
    container:{
        padding:5,
        flexDirection:'row',
        backgroundColor:'#eee'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },

    orderSelect:{
        flexDirection:'row',
        borderWidth:1,
        borderRadius:4,
        padding:5,
        borderColor:'#ddd',
        shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
    },
    item:{
        fontSize:14
    }
});
