import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert,FlatList , ActivityIndicator} from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../../assets/component/textinput";
import Modal from "../../../assets/component/modal";
import Button from "../../../assets/component/button";
import ListDown from "../../../assets/component/listdown";
import COLORS from "../../../assets/component/colors";
import {GetDocsByids,UpdateClaimStatus} from "../../../assets/js/firebase";


export default function UpdateClaim(props) {

    const {update,data,set} = props


    const [item, setItem] = useState(false);
    const [claims, setClaims] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [status, onStatus] = useState("pending");
    const [error, setError] = useState("");



    useEffect(() => {
        if(data){
            setItem(data)
            GetClaimsDetails(data)
            onStatus("pending")
        }
      }, [data]);


    async function GetClaimsDetails(data){
        const Listitem = await GetDocsByids("staff/"+data.staff+'/claims',data.claim_id)
        setClaims(Listitem)
    }


    async function Success(){
        setIsloading(true)
        item['status'] = status
        const Status = await UpdateClaimStatus(item)
        if(Status){
          update(item)
          setIsloading(false)
        }
    }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


    if(!data) return null

    const Th = (title,size) => {
        return(
            <View style={{flex:size}}>
                <Text style={styles.item}>{title}</Text>
            </View>
      
        )
      }
      
      
          const Header = () => {
            return(
              <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
                {Th('Category',1)}
                {Th('Name',1)}
                {Th('Date',1)}
                {Th('Amount',1)}
                <View style={{width:20}}/>
              </View>
            )
          }


    const RemoveLoader = (id) => {
        if(id != isLoading) return null
        return (
          <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
            {Loader(true)}
          </View>
        )
      }

      const ImageBtn = (link) => {
        if(!link) return null
        return (
          <TouchableOpacity style={{width:20}} onPress={()=>window.open(link, '_blank')}>
            <Ionicons name='image' size={22} color={'#dd5959'} />
          </TouchableOpacity>
        )
      }

    
    const RenderItem = ({item,index}) => {

        const BgColor = index % 2 == 0  ? "#FFFFFF" : "#ffefef"
  
        return(
          <View>
            {RemoveLoader(item.id)}
              <TouchableOpacity onPress={()=>console.log(item)} style={{padding:5,paddingVertical:15,backgroundColor: BgColor }}>
                    <View style={{flexDirection:'row'}}>
                        <View style={{flex:1}}><Text style={[styles.item]}>{item.category}</Text></View>
                        <View style={{flex:1}}><Text style={[styles.item]}>{item.name}</Text></View>
                        <View style={{flex:1}}><Text style={[styles.item]}>{ReformatTime(item.createdAt)}</Text></View>
                        <View style={{flex:1}}><Text style={[styles.item]}>RM{(item.amount).toFixed(2)}</Text></View>
                        {ImageBtn(item.image)}
                    </View>
              </TouchableOpacity>
          </View>
        )
    }

    
  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY');
    return format
  }

  const SumTotal = (total) => {
    return (
      <View style={{alignItems:'center',alignContent:'flex-end',flexDirection:'row',justifyContent:'flex-end'}}>
        <Text style={{fontSize:12,color:'#aaa'}}>Total (RM) </Text>
        <Text style={{fontSize:18,fontWeight:'600',color:'#39917e'}}>{total.toFixed(2)}</Text>
      </View>
    )
  }

  const SubmitBtn = (data) => {
    if(isLoading) return <ActivityIndicator size={'small'}/>
    if(data.status !== 'pending') return null
    return (
      <View>
        <ListDown list={['pending','paid','reject']} active={status} onPress={onStatus}/>
        <Error error={error}/>
        {status !== data.status && <Button title={'SUBMIT'} icon={'checkmark'} onPress={() => Success()} color={'#32a852'}/>}
      </View>
    )
  }


    const Popup = () => {
        return (
                <Modal title={'Claims'} icon={'ios-receipt'} visible={data?true:false} setVisible={set}>
                        <View >
                            <Text>ID : {data.id}</Text>
                            <Text style={styles.id}>Staff : {data.staff}</Text>
                            {Header()}
                            <FlatList
                                showsHorizontalScrollIndicator={false}
                                data={claims}
                                renderItem={RenderItem}
                                onEndReachedThreshold={0}
                            />
                                {SumTotal(data.amount)}
                                {SubmitBtn(data)}
                        </View>
                </Modal>
        )
    }
    


    return(
        <View style={styles.container}>
                <View style={{flex:1}}/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    id:{
        color:COLORS.Link
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      }
  });
  