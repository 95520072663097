import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View ,TextInput} from 'react-native';



export default function Editor(props) {

  const {value,set} = props


  useEffect(() => {

  }, []);


  const Contt = ['Merujuk perkara di atas, dengan sukacita syarikat kami ingin menawarkan jawatan sebagai <b>PEMANDU LORI</b> kepada tuan/puan. Kami percaya bahawa pengetahuan, kemahiran dan pengalaman anda akan menjadi antara aset kami yang paling berharga dan kehadiran anda ke dalam organisasi ini amat dialu-alukan. Anda dikehendaki mendaftar di syarikat kami pada <b>07/10/2024</b> dengan melengkapkan borang penerimaan tawaran yang dilampirkan.',
    'Terma dan syarat asas tawaran pekerjaan adalah seperti yang termaktub di bawah:',
    [ {title:'PEKERJAAN',inner:'Jawatan anda ialah PEMANDU di syarikat kami yang berkuat kuasa dari tarikh <b>07 Oktober 2024</b> atas terma dan syarat-syarat yang terkandung di sini dengan syarat bahawa syarikat mempunyai hak untuk mengubah tugas anda.'},
      {title:'PENGAJIAN',inner:'Gaji yang ditawarkan adalah sebanyak <b>RM1,800.00</b> sebulan. Gaji tahunan anda ditakrifkan sebagai gaji bulanan kali dua belas (12). Gaji/ Upah anda akan dikreditkan ke akaun bank anda. Untuk tujuan ini anda dikehendaki membuka akaun bank yang dipilih oleh syarikat dan menyediakan butiran akaun kepada syarikat.'},
      {title:'TEMPOH PERCUBAAN',inner:'Anda dikendaki menjalankan tempoh percubaan selama tiga (3) bulan. Jika telah menjalani tempoh percubaan dengan jayanya akan disahkan ke dalam jawatan secara bertulis. '},
      {title:'POTONGAN KWSP & SOCSO',inner:'Potongan KWSP dan SOCSO akan dilakukan selepas tamat tempoh percubaan.'},
      {title:'WAKTU BEKERJA',inner:'Waktu dan hari kerja anda adalah mengikut keperluan operasi syarikat. Anda tidak boleh menolak arahan untuk bekerja lebih masa tanpa alasan munasabah. Kerja lebih masa ditakrifkan sebagai kerja melebihi waktu kerja biasa. Berikut adalah waktu dan hari kerja yang dikenakan kepada anda (tertakluk kepada perubahan):-',
        th:[{ label:"",width: 100 },{ label:"",align: "left", width: 300}],
        td:[['Isnin – Jumaat :','9:00 pagi sehingga 6.00 petang (1 Jam Rehat)'],['Sabtu :','9:00 pagi sehingga 1.00 petang']]
      },
      {title:'CUTI TAHUNAN',
        inner:'Anda layak diberikan Cuti Tahunan bergaji seperti berikut dan permohonan cuti hendaklah dilakukan 1 minggu lebih awal. Kelulusan cuti tahunan mestilah diperolehi daripada Pengurus Bahagian Sumber Manusia atau Pengarah Urusan.',
        th:[{ label:"Tahun Perkhidmatan",width: 200 },{ label:"Bilangan Hari",align: "center", width: 100}],
        td:[['Kurang dari 2 tahun','8 Hari'],['Kurang dari 2 tahun','12 Hari'],['5 tahun ke atas','16 Hari']]
      },
      { title:'CUTI SAKIT',
        inner:'Anda layak diberikan Cuti Sakit bergaji seperti berikut:',
        th:[{ label:"Tahun Perkhidmatan",width: 200 },{ label:"Bilangan Hari",align: "center", width: 100}],
        td:[['Kurang dari 21 tahun','14 Hari'],['Kurang dari 2 tahun','18 Hari'],['5 tahun ke atas','22 Hari']]
      },
      {title:'',inner:'Di mana tiada kemasukan ke hospital diperlukan:'},
      {title:'NOTIS PENAMATAN',inner:'Anda layak diberikan Cuti Sakit bergaji seperti berikut:',subA:['Semasa tempoh percubaan, mana-mana pihak boleh memberikan notis satu (1) minggu secara bertulis atau pembayaran sebagai gantian tempoh notis untuk penamatan kontrak pekerjaan ini. Selepas tempoh percubaan, memerlukan notis satu (1) bulan atau gaji sebagai pembayaran gantian notis penamatan dengan kelulusan dari syarikat.','Syarikat boleh menamatkan pekerjaan anda tanpa notis, sekiranya berlaku salah laku atau pengabaian tugasan yang serius atau pelanggaran mana-mana peraturan atau arahan syarikat atau pelanggaran manamana terma dan syarat perjanjian. Perlu di ingati bahawa kami mempertimbangkan gaji dan faedah sebagai sulit dan persendirian. Perkongsian maklumat ini tidak dibenarkan.']},
      {title:'UMUR PERSARAAN',inner:'Umur persaraan anda adalah 60 tahun. Pihak syarikat berhak untuk melanjutkan umur persaraan bersyarat anda dengan mengeluarkan kontrak secara tahunan. '},
      {title:'PERATURAN STAF',inner:'Syarikat berhak untuk mengumumkan dan meminda peraturan kakitangan dari masa ke semasa yang anda mesti patuhi.'},
      {down:0},
    ]]



    return (
      <View style={[styles.container,{backgroundColor:'#eee'}]}>
            <TextInput
            editable
            spellCheck={false}
            multiline
            numberOfLines={50}
            onChangeText={text => set(text.replaceAll("'", '"'))}
            value={value}
            style={styles.input}
          />
    </View>
    )

  
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    borderWidth:1,
    borderColor:'#aaa'
  },
  input: {
    flex:1,
    outlineStyle: 'none'
  },
});
