import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import moment from 'moment';

export default function Table(props) {

  const {tab,setList,list,loading,nav} = props

  const [sortasc, setSortAsc] = useState(true);


  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  




const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}



    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('Supplier',2)}
          {Th('Products',2)}
          {Th('BL no',2)}
          {Th('Created',1)}
          <View style={{width:30}}></View>
        </View>
      )
    }



    const ProductList = (item) => {

      return (
            <View>
                {item.map((val,i)=> {
                    if(i < 2){
                      return <Text key={i}>{val}</Text>
                    }
                })}
              {item.length > 1 && <Text style={styles.more}>....</Text>}
            </View>
        )


    }

    const Status = (stat) => {
      const icon = stat == 'completed' ? 'checkmark-circle' : 'refresh-circle'
      const color = stat == 'completed' ? '#53c57e' : '#ddd'

      return (
          <Ionicons name={icon} size={22} color={color} />
      )
    }



    const RenderItem = ({item,index}) => {

    const resproduct = item.products.map(s=>s.name);
    const ressupplier = item.supplier.map(s=>s.category.name);

 
      return(
        <TouchableOpacity onPress={()=>nav.navigate('costing_details', {id:item.id})}  style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
              <View style={{flexDirection:'row',flex:1}}>
                  <View style={{flex:2}}>
                    <View style={styles.item2}>{ProductList(ressupplier)}</View>
                  </View>
                  <View style={{flex:2}}>
                    <View style={styles.item2}>{ProductList(resproduct)}</View>
                  </View>
                  <View style={{flex:2}}><Text style={styles.item2}>{item.bl}</Text></View>
                  <View style={{flex:1}}><Text style={styles.item}>{ReformatTime(item.createdAt)}</Text></View>
                  <View style={{width:30}}>{Status(item.status)}</View>
              </View>
        </TouchableOpacity>
      )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY');
    return format
}
  






    return(
      <View style={styles.container}>
          {Header()}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  item2:{
    marginRight:5,
    color:'#4581b3',
  },
  more:{
    padding:5,
    color:'#4581b3',
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    fontWeight:'bold',
    color:'#e07c00',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'red',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  optionbtn:{
    padding:5
  }
});
