import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,FlatList,TouchableOpacity } from 'react-native';
import TextInput from "../../../assets/component/textinput";
import Ionicons from '@expo/vector-icons/Ionicons';


export default function SearchInput(props) {

  const {set,data,value,uneditable} = props

  const [filterresult, setFilter] = useState(null);

  useEffect(() => { 

    setFilter(data)

    
  }, [data]);


  function Search(text){
      set({...value,'name':text,'mobile':value.mobile,'address':value.address})
      const newData = data.filter(item => item.name.toLowerCase().includes(text.toLowerCase()) || item.mobile.includes(text))
      setFilter(newData);
  }
  
  

  const RenderItem = ({item,index}) => {

    return(
      <TouchableOpacity style={{flex:1,borderBottomWidth:1,padding:5,borderColor:'#ddd'}}  onPress={()=>[set({...value,'name':item.name,'mobile':item.mobile,'driver_id':item.id,'in-house':item['in-house']})]}>
            <Text style={{fontsize:16}}>{item.name}</Text>
            <View style={styles.catrow}>
              {item['in-house'] && <Text style={styles.inhouse}>{'(inhouse)'}</Text>}
              <Ionicons name='chevron-forward' size={12} color={'#aaa'} />
              <Text style={styles.sub}>{item.mobile}</Text>
            </View>
      </TouchableOpacity>
    )
  }

  const TransporterList = (data) => {
    if(value.driver_id ||!value.name.length || !filterresult.length){return null}
    
    return (
        <FlatList
        style={{borderWidth:1,maxHeight:200,borderColor:'#ccc',backgroundColor:'#eee'}}
        showsHorizontalScrollIndicator={false}
        data={data}
        renderItem={RenderItem}
        onEndReachedThreshold={0}
    />
    )
  }

  const CloseBtn = () => {
    if(uneditable) return null
    return(
      <TouchableOpacity style={{padding:4}} onPress={()=>{set({'name':'','mobile':'','remark':'','address':value.address,'status':value.status})}}>
        <Text style={{fontFamily:14,color:'#999999'}}>X</Text>
      </TouchableOpacity>
    )
  }

  const SelectedDetails = (data) => {
    if(!data.driver_id) return (
            <TextInput
                title={'Name'}
                required={true}
                placeholder='Search .....'
                placeholderTextColor="#ddd"
                onChangeText={(text)=>Search(text)}
                value={value.name} 
                style={styles.input} 
                />
    )
    
    return (
      <View style={styles.selected}>
        <View style={{flex:1}}>
            <Text style={{fontWeight:500,color:'#ff5959'}}>{data.name}</Text>
            <Text style={{fontSize:12,color:'#aaa'}}>{data.mobile}</Text>
            {data['in-house'] && <Text style={styles.inhouse}>{'(inhouse)'}</Text>}
        </View>
        <CloseBtn/>
      </View>
    )

  }

  if(!filterresult){
    return null
  }


    return(
      <View>
        {SelectedDetails(value)}
        {TransporterList(filterresult)}
      </View>
    )

}

const styles = StyleSheet.create({

  selected:{
    flexDirection:'row',
    alignItems:'center',
    padding:10,
    backgroundColor:'#eeeeee',
    marginBottom:5,
    borderRadius:5
  },
  cat:{
    fontSize:12,
    color:'#0076a8',
    marginRight:3
  },
  sub:{
    fontSize:12,
    color:'#aaa',
    marginLeft:3
  },
  catrow:{
    flexDirection:'row',
    alignItems:'center',
    alignContent:'center'
  },
  inhouse:{
    color:'red',
    fontSize:12
  }

});
