import React, { Component } from "react";
import {View,TextInput,StyleSheet} from "react-native";
import COLORS from "./colors";
import Text from "./text";
import Ionicons from '@expo/vector-icons/MaterialIcons';


export default function TextInputCustom(props) {

    const {value,title, remark,hide,txtcolor,placeholder,required,onblur,uneditable} = props;

    if(uneditable){
      return (
        <View style={{flexDirection:'row'}}>
          <View style={{width:80,flexWrap:'wrap'}}>
            <Text style={styles.title}>{title}</Text>
          </View>
          <View style={{paddingHorizontal:2}}>
            <Text style={styles.title}>:</Text>
          </View>
          <View style={{flex:1,flexWrap:'wrap'}}>
            <Text style={[styles.input2]}>{value.toString()}</Text>
          </View>

          {value.error?<View style={styles.errbox}><Ionicons name={"error-outline"} size={18} color={COLORS.Error}/><Text style={styles.error}> {value.error}</Text></View>:null}
        </View>
      )
    }


    return (
      <View>
      <View style={styles.placeView}>
        <Text style={styles.title} numberOfLines={1}>{title}{required&&' *'}</Text>
        {remark?<Text style={styles.remark}>{remark}</Text>:null}
      </View>
      {!value.val?
        <TextInput onBlur={onblur} {...props} placeholderTextColor="#cccc" placeholder={placeholder?placeholder:title} value={value.error?'':value.toString()} style={[styles.input,{color:txtcolor?txtcolor:COLORS.Text}]}/>:
        <TextInput {...props} placeholderTextColor="#cccc" placeholder={placeholder?placeholder:title} value={value.val.toString()} style={[styles.input,{color:txtcolor?txtcolor:COLORS.Text}]}/>
      }
          {value.error?<View style={styles.errbox}><Ionicons name={"error-outline"} size={18} color={COLORS.Error}/><Text style={styles.error}> {value.error}</Text></View>:null}
      </View>
    );

  }


    const styles = StyleSheet.create({
      input: {
        paddingVertical:10,
        paddingHorizontal:10,
        fontSize:16,
        borderWidth:1,
        borderColor:'#aaa',
        backgroundColor:'#fff',
        borderRadius:5,
        outlineColor: COLORS.Primary ,
        marginBottom:5
      },
      input2: {
        paddingHorizontal:7,
        fontSize:14,
        marginBottom:5,
        color:'#004ea5',
      },
      error:{
        fontSize:12,
      },
      placeView:{
        flexDirection:'row',
        alignItems:'baseline',
        alignContent:'center',
      },
      title:{
        marginBottom:5,
        color:COLORS.Text,
        paddingHorizontal:5
      },
      remark:{
        fontSize:11,
        fontWeight:'300',
        color:'#aaa'
      },
      errbox:{
        flexDirection:'row',
        textAlign:'center',
        alignItems:'center',
      }
    
});

  