import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView,TouchableOpacity} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import TextInput from "../../../assets/component/textinput";
import Modal from "../../../assets/component/modal";
import Button from "../../../assets/component/button";
import COLORS from "../../../assets/component/colors";
import ListDown from '../../../assets/component/listdownoverlay';
import {AddItem,UpdateExspensesCat} from "../../../assets/js/firebase";


export default function EditCategory(props) {

    const {cat,catlist,year,list,nav,edititem,clear} = props


    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState("");

    const [member, setMember] = useState({});


    useEffect(() => {
        if(modalVisible && !edititem){
            setMember({'category':cat})
        }
        

    }, [modalVisible])

    useEffect(() => {
        if(edititem){
            setMember(edititem.category)
            setModalVisible(true)
        }

    }, [edititem])
    


    const onChangeHandler = (e,name) => {
        const Value = e.target?e.target.value:e
        setMember((prevState) => ({ ...prevState, [name]: Value }));
    };



    async function Validate(){

        const validName = ValidationName(['category']);

        setError('')


        if(validName){
            if(edititem){
                UpdateItem()
            }else{
                UpdateCat()   
            }
        }

    
    }


    function ValidationNum(obj){
        var validated = true
        obj.map((name)=>{
            if(typeof member[name] == 'undefined' || isNaN(member[name]) || member[name].error || member[name] <= 0){
                setMember(prevState => ({
                    ...prevState,
                    [name]: {error:"Invalid Value"}
                }));
                validated = false
            }
        })
        return validated
    }

    function ValidationName(obj){
        var validated = true
        obj.map((name)=>{
            if(typeof member[name] == 'undefined' || !member[name] || member[name].error){
                setMember(prevState => ({
                    ...prevState,
                    [name]: {error:"Invalid Value"}
                }));
                validated = false
            }
        })
        return validated
    }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }

    const InputVal = (title,val,remark) => {

        const inputVal = member && member[val] ? member[val]:''

        return (
            <TextInput
                placeholder={title}
                title={title}
                remark={remark}
                keyboardType="decimal-pad"
                onChange={e => onChangeHandler(e,val)}
                value={inputVal}
            />
        )
    }

    async function UpdateItem(){

        const Newlist =list.filter(doc => {
            if(member.id){
                return member.id == doc.category.id
            }
        });
        const updatedArray = Newlist.map((item) => ({...item, category: member}));
        await UPDATE(updatedArray)

    }


    async function UpdateCat(){
        const UpdateCatList = list.map(doc => doc && {...doc, category: {...doc.category, category: member.category}});

        await UPDATE(UpdateCatList)
    }

    async function UPDATE(list){

        const Status = await UpdateExspensesCat(list,member.category,year)
    
        if(Status){
            if(edititem){
                nav.navigate('Exspenses')
            }else{
                nav.navigate('exspense_details', {year:year,type:member.category})
                CloseModal()
            }
        }

    }

    const Footer = () => {
        return (
            <Button title={'UPDATE'} icon={'md-add-circle'}  onPress={()=>Validate()} color={'#867ec7'} isloading={isLoading}/>
        )
    }





    function CloseModal(){
        edititem ?  clear(null) : setModalVisible(false)
    }



    const Popup = () => {

        return (
            <Modal title={'Edit Category'} icon={'ios-pencil'} visible={modalVisible} setVisible={CloseModal} footer={Footer()}>
                        <View style={{minHeight:350}}>
                            {edititem && InputVal('Name','name')}   
                            <ListDown  title={'Category'} list={catlist} active={member.category?member.category:''} onPress={(val)=>onChangeHandler(val,'category')} addnew/>
                          
                            {edititem && InputVal('Sub Category','sub_category')}


                        </View>
                                <Error error={error}/>
                </Modal>
        )
    }
    


    return(
        <View>
                <TouchableOpacity onPress={()=>{setModalVisible(true)}} style={{paddingHorizontal:5}}>
                    <Ionicons name='ios-pencil' size={18} color={'#fff'} />
                </TouchableOpacity>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
  });
  