import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';



export default function Tab(props) {

    const {active ,onPress,tab } = props

    var style = null
    var txtColor = null
    

    return(
      <View style={{flexDirection:'row'}}>
        {Object.keys(tab).map(key => {
        if(active == key){style = styles.tabActive ; txtColor = '#777'}else{style = styles.tabInActive ; txtColor = '#ccc'}
          return (
            <TouchableOpacity key={key} style={[style,{padding:15}]} onPress={()=>onPress(key)}>
                <Text style={[styles.text,{color:txtColor}]}> {key} </Text>
            </TouchableOpacity>
          ); 
        })}
      </View>
    )



}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  tabActive:{
    borderWidth:1,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    borderBottomWidth:0,
    borderColor:'#ccc',
    borderBottomColor:'#fff',
    backgroundColor:'#fff'
  },
  tabInActive:{
    borderBottomWidth:1,
    borderColor:'#ccc'
  },
});
