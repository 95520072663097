import React, { useState,useEffect } from 'react';
import { Page, Text, Image, Document, StyleSheet,View ,Font} from "@react-pdf/renderer";
import COLORS from "../../../../../assets/component/colors";
import Helvetica from "../../../../../assets/fonts/Helvetica.ttf";


export default function PDFSALARY(props) {

    const {data,user,staff} = props

    Font.register({ family: 'Helvetica', src: Helvetica });

    const [sumTotal, setSumTotal] = useState(0);



    useEffect(() => {
        if(data){
            setSumTotal(SUMGross(data))
        }

    }, [data]);


    const InputVal = (title,val) => {
        const inputVal = data && data[val] ? data[val]:''
            return (
                <View style={{marginBottom:5,paddingVertical:3,flexDirection:'row'}}>
                    <View style={{flex:1}}>
                        <Text style={styles.mtext}>{title}</Text>
                    </View>
                    <View style={{width:80,flexDirection:'row',alignContent:'center',alignItems:'center'}} >
                        <Text style={{fontWeight:"500",color:COLORS.Primary,marginRight:3,fontSize:12}}>{inputVal}</Text>
                    </View>
                </View>
            )
    }


    const Address = () => {
      return  (
        <View style={styles.address}>
                <Text style={{color:'#111',fontWeight:'700',fontSize:12}}>{user.name}</Text>
                <Text style={styles.stext}>({user.ssm})</Text>
                <Text style={styles.text}>E-01-09 Jalan SM2</Text>
                <Text style={styles.text}>Taman Subang Mas 47620,</Text>
                <Text style={styles.text}>Subang Jaya,Selangor</Text>
                {Additional('website',user.website)}
                {Additional('H/P',user.tel)}
        </View>
      )
    }

    const Additional = (val,data) => {
      if(!data) return null
      return <Text style={styles.text}>{val} : {data}</Text>
    }

        


    function SUMObj(obj){
        var SUM = 0
        obj.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM += parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM += parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        return SUM.toFixed(2)
    }
    

    const IncomeCol = () => {
        const sub_array = 'income_others'
        return (
            <View style={[styles.ColBorder,{ flex:3,marginRight:5}]}>
              {TitleBx('INCOME')}
                <View style={{flex:1}}>
                    {InputVal('Basic','basic')}
                    {OtherList(data[sub_array])}
                </View>
                {SumboxView(['basic',sub_array])}
            </View>
        )
    }

    const OtherList = (data) =>  {
        if(!data) return null
        const da = data.map((data,i)=>{
        return (
            <View key={i} style={{flexDirection:'row',paddingVertical:5}}>
                <View style={{flex:1}}><Text style={styles.mtext}>{data.name}</Text></View>
                <View style={{width:80}}><Text style={styles.mtext}>{data.amount}</Text></View>
            </View>
        )})

        return da
    }
 

    const DeductionCol = () => {
        const sub_array = 'deduction_others'
        return (
            <View style={[styles.ColBorder,{ flex:2}]}>
              {TitleBx('Deduction')}
            <View>
                    {InputVal('Kwsp employee','kwsp2')}
                    {InputVal('Perkeso employee','socso2')}
                    {InputVal('EIS employee','eis2')}
                    {InputVal('PCB','pcb')}
                    {OtherList(data[sub_array])}
                </View>
                {SumboxView(['kwsp2','socso2','eis2','pcb',sub_array])}
            </View>
        )
    }

    const ContributionCol = () => {
        return (
            <View style={[styles.ColBorder]}>
               {TitleBx('Contribution')}
                    {InputVal('Kwsp','kwsp')}
                    {InputVal('Perkeso','socso')}
                    {InputVal('EIS','eis')}
                {SumboxView(['kwsp','socso','eis'])}
            </View>
        )
    }

    const SumboxView = (obj) => {
        return (
            <View style={{flexDirection:'row',paddingVertical:10,borderTopWidth:1,borderColor:'#aaa',borderStyle:'dotted'}}>
                <View style={{flex:1}}><Text style={{fontWeight:'500',fontSize:12}}>Total</Text></View>
                <View style={{width:80}}><Text style={{fontWeight:'800',fontSize:13}}>{SUMObj(obj)}</Text></View>
            </View>
        )

    }

    const TitleBx = (title) => {
        return (
            <View style={{paddingVertical:5,borderBottomWidth:1,flexDirection:'row',borderColor:'#ffe1cf'}}>
                <View style={{flex:1}}><Text style={{fontWeight:'500',fontSize:12}}>{title}</Text></View>
                <View style={{flexDirection:'row',width:80}}>
                    <Text style={{fontWeight:'400',fontSize:12}}>Amount </Text>
                    <Text style={{fontSize:8,color:'#aaa',fontWeight:'200'}}>(RM)</Text>
                </View>
            </View>
        )

    }

    


        
    function SUMGross(data){
        const SUMobj = ['basic','income_others']
        const Deduction = ['kwsp2','socso2','eis2','pcb','deduction_others']
        var SUM = 0
        SUMobj.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM += parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM += parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        Deduction.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM -= parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM -= parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        return SUM
    }

    const SumTxt = () => {
        return (
            <View style={{flexDirection:'row',marginTop:10}}>
                <Text style={{fontSize:12}}>Nett Amount (RM) : </Text>
                <Text style={{fontSize:12,fontWeight:'600'}}>{sumTotal.toFixed(2)}</Text>
            </View>
        )
    }

    const StaffDetails = () => {
        return (
            <View style={{flexDirection:'row'}}>
                <View style={{marginRight:10}}>
                    <Text style={{fontSize:10,marginBottom:4}}>NAME</Text>
                    <Text style={{fontSize:10,marginBottom:4}}>MYKAD</Text>
                    <Text style={{fontSize:10,marginBottom:4}}>POSITION</Text>
                </View>
                <View>
                    <Text style={{fontSize:10,marginBottom:4}}>:  {staff.name}</Text>
                    <Text style={{fontSize:10,marginBottom:4}}>:  {staff.mykad}</Text>
                    <Text style={{fontSize:10,marginBottom:4}}>:  {staff.position}</Text>
                </View>

            </View>
        )
    }




if(!data) return null

    
        return (
            <Document>
              <Page size="A4" style={styles.body}>
                  <View style={styles.header} fixed>
                    <Image style={styles.image}  source={{ uri: user.logo}} />
                    <View style={{flex:1}}/>
                    {Address()}
                  </View>
                        {StaffDetails()}
                      <View style={{flexDirection:'row',justifyContent:'flex-end'}}><Text style={styles.mthtxt}>{data.month}</Text></View>
                      <View style={{flexDirection:'row'}}>
                          {IncomeCol()}
                          {DeductionCol()}
                      </View>
                      {ContributionCol()}
                  {SumTxt()}
              </Page>
            </Document>
        )

    




}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:12,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      mthtxt:{
        fontSize:14,
        fontWeight:'500',
      },
      addother:{
        padding:3,
        alignContent:'center',
        alignItems:'center',
      },
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
      ColBorder:{
        borderWidth:1,
        padding:5,
        borderColor:'#aaa',
        marginTop:5
      },
      image: {
        width:100,
        height:100,
      },
      text: {
        fontSize: 9,
        fontFamily: "Helvetica",
        color:'#111',
        marginBottom:3
      },
      mtext:{
        fontSize: 11,
        color:'#111',
      },
      stext:{
        fontSize: 8,
        fontWeight:'200',
        fontFamily: "Helvetica",
        color:'#111',
        marginBottom:2
      },
      header: {
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
  });
  