import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View,Text, ActivityIndicator} from 'react-native';
import {getAuth} from 'firebase/auth';
import Tab from './tab';
import ADDORDER from './add';
import Table from './table';
import {GetOrdersOnline,PaidShopee} from "../../assets/js/firebase";
import Button from "../../assets/component/button";
import SearchBox from "../../assets/component/searchbox";
import Listdown from "../../assets/component/listdownoverlay";


export default function OrderScreen({route,navigation}) {

  const auth = getAuth();
  const user = auth.currentUser;

  const LimitPerload = 22

  const isMounted = useRef();


  const DafaultTab = {pending:[],paid:[],cancel:[]}

  const Platform = ['Shopee','Lazada']

  const [listBase, setListBase] = useState(DafaultTab);
  const [list, setList] = useState(DafaultTab);
  const [company, onCompany] = useState(Platform[0]);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [lastVisible , setLastVisible ] = useState(null);
  const [picked, setPicked] = useState([]);
  const [statusloading, setStatusloading] = useState(false);




  useEffect(() => {
      isMounted.current = true;
      if (isMounted.current || company) {
        if(listBase[tabactive].length == 0 || company){
          setIsloading(true)
          getList(tabactive,company);
        }
      }
      return () => {
        isMounted.current = false;
      };
    }, [tabactive,company]);
  

  async function getList(active,company){

    const Limit = active == 'paid'?LimitPerload:null

    const ListOrders = await GetOrdersOnline('online_order',company,active,Limit)
    if(isMounted.current){
      setLastVisible({...lastVisible, [active]:ListOrders.last});
      setList({...list, [active]:ListOrders.list});
      setListBase({...listBase, [active]:ListOrders.list});
      setIsloading(false)
    }

  };

  async function nextList(active,last){

      if(active != 'paid') return null

      if(!last[active]) return null

      const ListOrders = await GetOrdersOnline('online_order',company,active,LimitPerload,last[active])

      if(ListOrders){
        console.log("load More")
        setLastVisible({...lastVisible, [active]:ListOrders.last});

        const dataArray = [ ...list[active], ...ListOrders.list]
  
        setList({...list, [active]:dataArray});
        setListBase({...listBase, [active]:dataArray});
        setIsloading(false)
      }
    
  };
  

  function PushItem(data){
    onCompany(data.company)
    const dataArray = [ ...list[data.status]]
    dataArray.unshift(data)
    setList({...list, [data.status]:dataArray});
  }

  async function Approve(data,sum){
    setStatusloading(true)
    await PaidShopee(data,sum,company)
    UpdateStatus(data)
  }

  function UpdateStatus(data){
    const Pending = list[tabactive].filter(item => !data.some(itemToBeRemoved => itemToBeRemoved.id === item.id))
    setList({...list,['pending']:Pending});
    setPicked([])
    setStatusloading(false)
}


  const Footer = () => {
    if(!picked.length) return null

    const Sum = picked.reduce((n, {income}) => n + parseFloat(income), 0).toFixed(2)
    return (
      <View style={{flexDirection:'row',padding:10,backgroundColor:'#bbeeff',position:'absolute',width:'100%',bottom:0,zIndex:99}}>
        <View style={{flex:1}}>
            <View><Text>TOTAL : {picked.length}</Text></View>
            <View><Text>SUM : RM{Sum}</Text></View>
        </View>
        {!statusloading?<Button title={'Paid'} onPress={()=> Approve(picked,Sum)} color={'#4a90ff'} icon={'checkmark'}/>:<ActivityIndicator size={'small'}/>}
      </View>
    )
  }




  return (
    <View style={styles.container}>
    <View style={{padding:10,flex:1,zIndex:22}}>
    <Listdown name={'Company'} onPress={onCompany} list={Platform} active={company}/>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} />
        <ADDORDER add={PushItem} platformList={Platform} platform={company}/>
        {!isLoading && <SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['orderid']}/>}
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        user={user}
        next={()=>nextList(tabactive,lastVisible)} 
        loading={isLoading}
        statusloading={statusloading}
        setPicked={setPicked}
        picked={picked}
        nav={navigation}
        addorder={(data)=><ADDORDER add={PushItem} data={data}/>}
        />
    </View>
    <Footer/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
