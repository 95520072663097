import React, { Component } from "react";
import {View,TextInput,StyleSheet,TouchableOpacity, ActivityIndicator} from "react-native";
import COLORS from "./colors";
import Text from "./text";
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';


export default function ButtonComponent(props) {

    const {title,icon,color,isloading,Iconfont,disable} = props;
    
    

    if(isloading){
      return(
        <View style={styles.container} {...props}>
          <View style={[styles.BtnLoading]}>
            <View><ActivityIndicator size={'small'}/></View>
          </View>
        </View>
      )
    }

    const Icon = () => {
      if(!icon) return null
      
      if(Iconfont == 'MaterialCommunityIcons'){
        return <MaterialCommunityIcons name={icon} size={20} color='#fff' style={{marginRight:title?5:0}}/>
      } 
      return <Ionicons name={icon} size={20} color='#fff' style={{marginRight:title?5:0}}/>
    }

    const Color = () => {
      if(disable) return '#eee';
      if(color) return color
      return COLORS.Primary
    }


    return (
        <View style={styles.container} {...props}>
            <TouchableOpacity {...props} style={[styles.btnAdd,{backgroundColor:Color()}]} disabled={disable} >
              <Icon/>
              <Text style={{color:'#fff'}}>{title}</Text>
            </TouchableOpacity>
        </View>
    );

  }


    const styles = StyleSheet.create({
      container:{
        flexDirection:'row',
        justifyContent:'flex-end',
      },
      btnAdd:{
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
      },
      BtnLoading:{
        width:100,
        padding:10,
        backgroundColor:'#eee',
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center'
      }
    
});

  