import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,ActivityIndicator ,Text} from 'react-native';
import moment from 'moment';
import { Button,Modal,Table,TextInput} from '../../../assets/component';
import {UpdateDeliveryDocs} from "../../../assets/js/firebase";

export default function Footer(props) {

    const {picked,update} = props

    const [statusloading, setIsloading] = useState(false);
    const [display, setDisplay] = useState(false);
    const [total, setTotal] = useState(0);


    if(!picked.length) return null


    async function Validate(){

      const validAmt = ValidationAmt(total);


 
      if(validAmt){
        setIsloading(true)
        const Updated = await UpdateDeliveryDocs(picked,validAmt)

        if(Updated){
          update(Updated)
          setDisplay(false)
          setIsloading(false)
        }else{
          console.log("Unable to update")
        }
      }

    }


  function ValidationAmt(input){
    if(input <= 0 || isNaN(input) || input.error){
      setTotal({error:'Invalid Amount'})
      return false
    }
    return input
  }

    const ButtonRow = () => {
      return (
        <View style={styles.btnrow}>
            <Text style={{marginRight:5}}>Total (RM)</Text>
            <View style={{flex:1}}><TextInput onChangeText={setTotal} maxLength={60}  placeholder={'Total'} value={total} errclear={true}/></View>
            <View style={{marginHorizontal:5}}>
                <Button title={'PAY'} onPress={()=> Validate()} color={'#4a90ff'} icon={'checkmark'} isloading={statusloading}/>
            </View>
        </View>
      )
    }




    const UpdateModal = () => {
      const filteredClasses = picked.filter(cls => [...cls.delivery_status].reverse()[0].status === 'processing');
      return (
        <Modal title={'Make Payment'} icon={'truck'} Iconfont={'MaterialCommunityIcons'} visible={display} setVisible={()=>setDisplay(false)}>
            <Text style={styles.head}>{picked[0].name}</Text>
            <Table 
              data={picked} 
              colSettings={[
                { name: 'delivery', flex: 1 ,style:{fontWeight:'600'}}, 
                { name: 'status', width: '30%' }, 
                { name: 'createdAt', width: '30%'}
              ]}
            />
            {ButtonRow()}
            {filteredClasses.length > 0 && <Text style={{color:'red'}}>Some of the deliveries not pickup</Text>}
        </Modal>
      )
    }
    

    return(
          <View style={{flexDirection:'row',padding:10,backgroundColor:'#bbeeff',position:'absolute',width:'100%',bottom:0,zIndex:99}}>
            <View style={{flex:1}}>
                <View><Text>TOTAL : {picked.length}</Text></View>
            </View>
            <Button title={'Paid'} onPress={()=> setDisplay(true)} color={'#4a90ff'} icon={'checkmark'}/>
            {UpdateModal()}
          </View>
    )



}

const styles = StyleSheet.create({
  head:{
    fontSize:20,
    fontWeight:'500'
  },
  btnrow:{
    borderTopWidth:1,
    borderColor:'#eee',
    flexDirection:'row',
    marginVertical:5,
    alignContent:'center',
    alignItems:'center',
    paddingVertical:5
  }
});
