import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';
import Button from '../../assets/component/button';
import COLORS from "../../assets/component/colors";
import {WhatsappInvoice} from "../../assets/js/api";
import UpdateDriverModal from './deliveryorder';
import UpdateModal from './updateModal';


export default function Table(props) {

  const {tab,setList,list,next,loading,user,nav,reorder} = props


  const [isLoading, setIsloading] = useState(null);
  const [option, setOption] = useState(null);
  const [modified, setModified] = useState(null);
  const [driver, setDriver] = useState(null);



  const Th = (title,size) => {
    return(
        <View style={{flex:size}}>
            <Text style={styles.item}>{title}</Text>
        </View>

    )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('ID',1)}
          {Th('Name',3)}
          {Th('Amount',2)}
          <View style={{width:30}}></View>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const ReorderBtn = (data) => {
      return(
        <TouchableOpacity onPress={() => reorder(data)} style={styles.reorder}>
          <Ionicons name='refresh' size={18} color={COLORS.Link} />
          <Text style={{color:COLORS.Link,marginLeft:2}}>Reorder</Text>
        </TouchableOpacity>
      )
    }


    const ExtraDetails = (data) => {
       
       return (
            <View style={{flexDirection:'row'}}>
                  <Button onPress={()=>nav.navigate('invoice', {bill: data.id})} icon={'print'} style={{marginRight:10}}/>
                  <Button onPress={()=>setModified(data)} icon={'ios-pencil'} style={{marginRight:10}}/>
                  <Button onPress={()=>SendWhatsapp(data)} color={'#05d305'} icon={'logo-whatsapp'} style={{marginRight:10}}/>
                  {ReorderBtn(data)}
                  {Itemised(data.products)}
            </View>
       )
     }


     async function SendWhatsapp(data){
        if (confirm('Whatsapp this invoice ?')) {
          const result = await WhatsappInvoice(data)
          if(result.Response.messages[0].message_status == "accepted"){
            alert("Sent")
          }
        }
     }



    const DriverIcon = (item) => {
      const Color = item.driver?'#6398e0':'#c9c9c9'
      return <TouchableOpacity  onPress={()=>setDriver(item)}><MaterialCommunityIcons name='truck' size={18} color={Color} /></TouchableOpacity>
    }

    const Itemised = (item) => {
      
      return(
        <View>
          {item.map((val,i)=> {
              return (
          <View key={i}>
            <Text>{val.name}</Text>
          </View>
              )
          })}
        </View>
      )
    
    }



    const RenderItem = ({item,index}) => {
      return(
        <TouchableOpacity onPress={() => setOption(option == item.id?null:item.id)}  style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
          <View style={{flexDirection:'row'}}>
                  <View style={{flex:1}}><Text style={styles.id}>#{item.id}</Text></View>
                  <View style={{flex:3}}>
                        <Text style={styles.title}>{item.company?item.company:item.name}</Text>
                        <Text style={[styles.item,{fontWeight:'200',fontSize:12}]}>{item.date}</Text>
                  </View>
                  <View style={{flex:2}}><Text style={styles.item}>RM{parseFloat(item.amount).toFixed(2)}</Text></View>
                  <View style={{width:30}}>{DriverIcon(item)}</View>
          </View>
                {option == item.id?ExtraDetails(item):null}
        </TouchableOpacity>
      )
  }


  function UpdateData(data){
    const dataArray = [ ...list[tab]]
    const result = dataArray.map((obj) => obj.id === data.id ? data : obj);
    setList({...list, [tab]:result})


  }



    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            onEndReached={next}
          />
          <UpdateModal data={modified} clear={setModified} update={(val)=>UpdateData(val)}/>
          <UpdateDriverModal data={driver} clear={setDriver} update={(val)=>UpdateData(val)} type={'orders'}/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  id:{
    marginRight:5,
    color:'#006ad4',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    position:'absolute',
    backgroundColor:'#fff',
    zIndex:1,
    left:-90,
    borderRadius:5,
    padding:10,
    paddingHorizontal:20,
    shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  reorder:{
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
    margin:10
  }
});
