import React, { useState,useEffect } from 'react';
import {View,StyleSheet,TouchableOpacity} from "react-native";
import TextInput from "./textinput";
import ListDown from "./listdownoverlay";
import COLORS from "./colors";
import Button from "./button";
import Text from "./text";
import Ionicons from '@expo/vector-icons/MaterialIcons';


export default function MultipleINput(props) {

    const {data,add,category} = props;

    const [extras, setExtras] = useState(false);
  



    const OtherList = (obj) =>  {
      if(!obj) return null
      const da = obj.map((data,i)=>{
      return (
          <View key={i} style={{flexDirection:'row',paddingVertical:5}}>
              <View style={{flex:1}}><Text style={{color:COLORS.Primary}}>{data.name}</Text></View>
              <View style={{width:80}}><Text>{data.amount}</Text></View>
          </View>
      )})

      return da
    }


    const AddInput = () => {
      const [input, onInput] = useState(category?category[0]:'');
      const [amt, SetAmt] = useState(0);
      const [other, SetOther] = useState(false);
      
      useEffect(() => {
        if(input == 'Others'){
          SetOther(true)
        }
      }, [input]);

      function PushOther(arr) {
        if(arr.name){
            const arraYnew = data?[...data,arr]:[arr]
            add(arraYnew)
            setExtras(false)
        }
        SetAmt(0)
      }

      return (
      <View style={{flexDirection:'row'}}> 
          <View style={{flex:1,marginRight:5}}>
              {category && !other?
              <ListDown list={category} active={input} onPress={onInput}/>
              :<TextInput placeholder={'Name'}  onChangeText={onInput} value={input} />}
          </View>
          <View style={{width:60,marginRight:5}}>
              <TextInput placeholder={'Amount'} keyboardType="decimal-pad" onChangeText={SetAmt} value={amt} />
          </View>
           <Button icon={'add'} onPress={()=>PushOther({'name':input,'amount':amt})}></Button>
      </View>
      )
    }

    const BtnAdd = () => {
      if(!add) return null
      return (
        <View style={styles.container}>
          <TouchableOpacity onPress={()=>setExtras(true)} style={styles.addother}>
              <Text style={{color:COLORS.Link}}>+ Add Other</Text>
          </TouchableOpacity>
        </View>
      )
    }


    return (
      <View style={{zIndex:33}}>
        {OtherList(data)}
        {extras?<AddInput/>:<BtnAdd/>}
      </View>
    );

  }


    const styles = StyleSheet.create({
      center:{
        alignContent:'center',
        alignItems:'center',
      },
      addother:{
        padding:3,
        alignContent:'center',
        alignItems:'center',
        marginVertical:5
      }
    
});

  