import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';



export default function Tab(props) {

    const {active ,onPress,tab,list } = props

    var style = null
    var txtColor = null

    const Count = (nos) => {
      if(nos <= 0) return null
      return(
      <View style={[styles.circle,styles.flexcenter]}>
          <Text style={{fontSize:10,color:'#777',fontWeight:'bold'}}>{nos}</Text>
      </View>
      )
    }
    

    return(
      <View style={{flexDirection:'row'}}>
        {Object.keys(tab).map(key => {
        if(active == key){style = styles.tabActive ; txtColor = '#777'}else{style = styles.tabInActive ; txtColor = '#2172ff'}
          return (
            <TouchableOpacity key={key} style={[style,{padding:15}]} onPress={()=>onPress(key)}>
              <View style={styles.flexcenter}>
                <Text style={[styles.text,{color:txtColor}]}> {key} </Text>
                {Count(list[key].length)}
              </View>
            </TouchableOpacity>
          ); 
        })}
      </View>
    )



}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  tabActive:{
    borderWidth:1,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    borderBottomWidth:0,
    borderColor:'#ccc',
    borderBottomColor:'#fff',
    backgroundColor:'#fff'
  },
  tabInActive:{
    borderBottomWidth:1,
    borderColor:'#ccc'
  },
  flexcenter:{
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  circle:{
    padding:5,
    borderRadius:15,
    backgroundColor:'#eee',
    justifyContent:'center'
  }
});
