import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';



export default function Tab(props) {

    const {active ,onPress,tab,list } = props

    var style = null
    var txtColor = null



    return(
      <View style={{flexDirection:'row'}}>
        {Object.keys(tab).map(key => {
        if(active == key){style = styles.tabActive ; txtColor = '#444'}else{style = styles.tabInActive ; txtColor = '#aaa'}
          return (
            <TouchableOpacity key={key} style={[style,{padding:5}]} onPress={()=>onPress(key)}>
              <View style={styles.flexcenter}>
                <Text style={[styles.text,{color:txtColor}]}> {key} </Text>
              </View>
            </TouchableOpacity>
          ); 
        })}
      </View>
    )



}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  tabActive:{
    borderWidth:1,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    borderColor:'#ccc',
  },
  tabInActive:{

  },
  flexcenter:{
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  circle:{
    padding:5,
    borderRadius:15,
    backgroundColor:'#eee',
    justifyContent:'center'
  }
});
