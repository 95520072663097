import React, { Component,useState } from "react";
import {View,FlatList,StyleSheet,TouchableOpacity,ActivityIndicator  } from "react-native";
import moment from 'moment';
import COLORS from "./colors";
import Text from "./text";
import CheckBox from "./checkbox";
import {Ionicons,MaterialCommunityIcons,MaterialIcons} from '@expo/vector-icons';


export default function Table(props) {

    const {data,colSettings,picked,tab,setPicked,excluded,isloading,next} = props;

    const [sortasc, setSortAsc] = useState(true);


    const Thickbox = (data) => {
      if(excluded && !excluded.includes(tab)) return null
      const disable = picked && picked[0] && picked[0].driver_id != data.driver_id
      
      if(disable) return null

      var containsId = picked.find(x => x.id === data.id);
      return <CheckBox value={containsId} onValueChange={()=>SelectId(data,containsId)}/>

    }

    function SelectId(data,found){
      if(found){
        const filteredData = picked.filter(item => item.id !== data.id);
        setPicked(filteredData);
      }else{
        setPicked([...picked, data])
      }
    }


    const Header = () => {
      if(!colSettings.length) return null
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
           {picked && <View style={{width:40}}/>}
           {
                colSettings.map((val,i)=> {
                  const {name,width,label,flex} = val.length?val[0]:val
                  if(!name) return null
                  var Size = {flex:1}
                  if(width){Size = {width:width}}
                  if(flex){Size = {flex:flex}}
                  return <Th title={label?label:name} size={Size} key={i}/>
                })
          }
        </View>
      )
    }

    const Th = (props) => {
      const {title,size,sort} = props
      return(
          <View style={size} >
            <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
              <Text style={styles.title}>{title}</Text>
              <View>
                  {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
              </View>
            </TouchableOpacity>
          </View>
    
      )
    }


    const RenderItem = ({item,index}) => {
      return(
          <View style={{flexDirection:'row',padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
          {picked && <View style={{width:40}}>{Thickbox(item)}</View>}
          {
                colSettings.map((val,i)=> {
                    const {name,width,flex,style,icon} = val.length?val[0]:val
                    if(!name) return null
                    var Size = {flex:1}
                    if(width){Size = {width:width}}
                    if(flex){Size = {flex:flex}}

                    const TextTitle = (name) => {
                      if(icon && icon[name]){
                        return <MaterialCommunityIcons name={icon[name]} size={24} color="#ccc" />
                      }
                      return <Text style={[styles.item,style]}>{Sanitize(name)}</Text>
                    }

                    return (
                          <View key={i} style={Size}>
                            {TextTitle(item[name])}
                            {val.length && val.slice(1).map((hh,i)=> {
                              const {name,color,style} = hh
                              return <Text key={i}  numberOfLines={1} style={[{fontSize:12,color:color},style]}>{Sanitize(item[name])}</Text>
                            }
                            )}
                          </View>
                    )
                })
          }
          </View>
      )
    }

    function Sanitize(data){
      if(!data) return null

      if(data.seconds){
        return ReformatTime(data)
      }
      return data
    }

    const ReformatTime = (data) => {
      if(!data) return null
      const format = moment(data.toDate()).format('DD/MM/YY HH:MM A');
      return format
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }
    


      return (
        <View>
          <Header/>
          {Loader(isloading)}
          <FlatList
                showsHorizontalScrollIndicator={false}
                data={data}
                renderItem={RenderItem}
                onEndReachedThreshold={0}
                onEndReached={next}
              />
        </View>
      )

    }



    const styles = StyleSheet.create({
      flexcenter:{
        alignContent:'center',
        flexDirection:'row',
        alignItems:'center',
      },
      item:{
        marginRight:5,
        color:'#777',
      },
      title:{
        color:'#338994',
        marginRight:5,
        textTransform: 'capitalize'
      },
});

  