import React, { useState,useEffect } from 'react';
import { View,StyleSheet,Text,TouchableOpacity,Image} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Ionicons from '@expo/vector-icons/Ionicons';
import {GETLIST,SETACC,GETACC,UPDATELIST} from '../assets/js/getacc';
import ListDown from './listdown';
import AddModal from "./add";
import { SafeAreaView } from 'react-native-safe-area-context';



export default function Header(props) {

  const {refresh,user,LargeScreen} = props
  const [Acclist, onAccList] = useState([]);
  const [Account, onAcc] = useState('');
  const [addModal, setAddModal] = useState(false);

    const navigation = useNavigation();
    const Title = props.route.name.replace(/_/g, ' / ');


    useEffect(() => {
      getAccList()

    }, []);


    async function getAccList(){
      const getacc = await GETACC()
      const accid = await GETLIST()
      if(accid.length){
        onAccList(accid)
        if(!getacc){
          SETACC(accid[0])
          onAcc(accid[0])
          refresh()
        }else{
          onAcc(getacc)
        }
      }else{
        onAccList(null)
      }
    }

    function pushNewAcc(data){
      const Append = Acclist ? [...Acclist, data] : [data]
      onAccList(Append);
      UPDATELIST(Append)
      SETACC(data)
      onAcc(data)
      refresh()
    }

    function UpdateAcc(data){
      var foundIndex = Acclist.findIndex(x => x.id == data.id);
      Acclist[foundIndex] = data;
      onAccList(Acclist);
      UPDATELIST(Acclist)
      SETACC(data)
      onAcc(data)
      setAddModal(false)
    }



    function setACCC(acc){
      SETACC(acc)
      onAcc(acc)
      refresh()
    }



    const MenuBtn = () => {
      if(LargeScreen) return null
      return (
        <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
          <Ionicons name="ios-menu-outline" size={34} color="black" />
        </TouchableOpacity>
      )
    }

    
    const Editbtn  = () => {
      return (
        <TouchableOpacity onPress={()=>setAddModal(true)}>
          <Ionicons name='settings-outline' size={16} color={'#008ab0'} />
        </TouchableOpacity>
      )
    }

    const TitleHeader = () => {
      if(!Account) return null
      const Name = Account.display_name ? Account.display_name : Account.name
      return (
        <View style={{flex:1,marginLeft:10}}>
            {!LargeScreen && <Text style={{color:'#3e567c',fontWeight:'400'}}>{Name}</Text>}
            <Text style={{fontSize:!LargeScreen?14:18,fontWeight:'200',color:'#888',textTransform: 'capitalize'}}>{Title}</Text>
        </View>
      )
    }

    const BgHeader = Account.name == 'Test Account' ? 'red':'#eee'

    return (


                  <View style={[styles.container,{backgroundColor:BgHeader}]}>
                    {MenuBtn()}
                    <View style={{flexDirection:'row',alignContent:'center',flex:1,alignItems:'center'}}>
                        <Image style={styles.tinyLogo} source={{ uri: Account.logo}}/>
                        <TitleHeader/>
                    </View>
                    <Editbtn/>
                    {addModal && <AddModal add={UpdateAcc} close={()=>setAddModal(false)} update={true} account={Account}/>}
                    <ListDown name={'Choose Agent'} list={Acclist} set={setACCC} active={Account} push={pushNewAcc} Large={LargeScreen}/>
                  </View>


    );
}

const styles = StyleSheet.create({
 
  container: {
    flexDirection:'row',
    padding:10,
    alignContent:'center',
    alignItems:'center',
   borderBottomWidth:0.5,
   borderColor:'#ddd',
   backgroundColor:'#eee'
  },
  tinyLogo:{
    width:40,
    height:40,
  }



  });