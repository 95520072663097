import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,Text, Image} from 'react-native';
import {deleteStock,GetAllDocs} from "../../../assets/js/firebase";
import Button from "../../../assets/component/button";
import Table from './table';


export default function CostList({route,navigation}) {


  const [list, setList] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [removeLoading, setRemoveLoading] = useState(null);

  useEffect(() => {
    getList()
  }, [])

  

  async function getList(){

      const ListProduct = await GetAllDocs('costing')
      setList(ListProduct)
      setIsloading(false)

  };



  if(!list) return null

  return (
    <View style={styles.container}>
      <Button title={'Add New'} onPress={()=>navigation.navigate('costing_details', {id:'new'})} icon={'add'}/>
      <Table 
        setList={setList} 
        list={list} 
        loading={isLoading}
        nav={navigation}
        removeLoading={removeLoading}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  ProductImg: {
    width: 100,
    height: 100,
  },
});
