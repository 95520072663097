import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text} from 'react-native';
import {GetCreditList} from "../../../assets/js/firebase";
import Table from './table';
import Cat_List from './cat_list';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import EditCategory from './edit';
import Types from './types';
import COLORS from '../../../assets/component/colors';
import LText from '../../../assets/component/lightText';
import {UpdateExspensesCat,GetExspensesList} from "../../../assets/js/firebase";




export default function ExspensesDetails({route,navigation}) {
  const {year,type,sub} = route.params;

  const Cat = ['All','Category']

  const [list, setList] = useState([]);
  const [catList, SetCatList] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [edititem, setEditItem] = useState(null);
  const [activeType, SetActive] = useState(Cat[0]);


  useEffect(() => {
    async function GetList() {
      const response = await GetCreditList(year,type,sub)
      setIsloading(false)
      setList(response.list)
      setTotal(response.group.reduce((n, {amount}) => n + amount, 0))
    }
    GetExspenseGroup()
    GetList()
  }, [type])


  async function GetExspenseGroup(){
    const List = await GetExspensesList(year)
    let result = List.map(a => a.name);
    SetCatList(result)
  }

  const BackBtn = () => {
    return (
      <View style={{flexDirection:'row'}}>
          <Ionicons name='chevron-back' size={18} color={COLORS.Link} />
          <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>navigation.navigate('Exspenses')}><Text style={{color:COLORS.Link}}>BACK</Text></TouchableOpacity>
      </View>
    )
  }


  const Summarize = () => {
    return (
      <View style={{flexDirection:'row',padding:10,borderRadius:5,backgroundColor:sub?'#867ec7':'#7ec783'}}>
        <View >
            <LText>{sub?'Sub Category':'Category'}</LText>
            <LText>Total</LText>
        </View>
        <View style={{flex:1}}>
            <View style={{flexDirection:'row'}}><LText style={{fontWeight:600}}> : {sub?sub:type}</LText>
              <EditCategory list={list} catlist={catList} cat={type} year={year} nav={navigation} edititem={edititem} clear={setEditItem}/>
            </View>
            <LText style={{fontWeight:600}}> : RM{total.toFixed(2)}</LText>
        </View>
      </View>

    )
  }

  function NavigateSubCat(subcat) {
    navigation.navigate('exspense_details_sub', {year:year,type:type,sub:subcat})
  }

  const DisplayList = () => {

    const unique = [...new Set(list.map(item => item.category.sub_category))];
    console.log(unique)

    if(activeType !== 'All'){
      return <Cat_List 
              list={list}
              loading={isLoading}
              navSubcat={NavigateSubCat}
              sub={sub}
              cat={unique}
              edit={setEditItem}
            />
    } 
    

    return (
      <Table 
              list={list}
              loading={isLoading}
              navSubcat={NavigateSubCat}
              sub={sub}
              edit={setEditItem}
            />
    )

  }
  



  return (
    <View style={styles.container}>
      <BackBtn/>
      <Summarize/>
      <Types active={activeType} types={Cat} set={SetActive}/>
      <DisplayList/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap',
    zIndex:10
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
