import React, { useState,useEffect,useRef } from 'react';
import { View,Text,StyleSheet} from 'react-native';
import "./styles.css";
import Logo from '../logo64';
import { useReactToPrint } from "react-to-print";
import QR from "qrcode-base64";
import { TouchableOpacity } from 'react-native-gesture-handler';

export default function LabelPrint(props) {

  const {item} = props

  const LabelType = ['Box','Sample']

  const [type, OnType] = useState(null);


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  useEffect(() => {


  }, []);

  const QrID = QR.drawImg(item.id,{
      typeNumber: 5,
      errorCorrectLevel: "M",
      size: 70
    }
  );



      return (
        <>
          <div className="App" ref={componentRef}>
          <div class="parent">
          <style>{`@media print {@page {size: 50mm 50mm;}}`}</style>
              <div class="head">
                  <div class="qr"><img class="qrimage" src={QrID} alt="Logo" /></div>
                  <div class="logo"><img class="img2" src={Logo} alt="Logo" /></div>  
              </div>
              <div class="main">
                <div class="small">CODE</div>
                <div class="code">{item.code}</div>  
                <div class="small">{item.id}</div>
              </div>
            </div>
          </div>
          <button onClick={handlePrint}>Print</button>
        </>
      );

}

const styles = StyleSheet.create({
  image:{
      width:100,
      height:100
  },
  btn:{
    padding:10,
    borderRadius:5,
    borderColor:'#aaa',
    borderWidth:1,
    marginBottom:2
  }
});

