import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView, ActivityIndicator} from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../../../../assets/component/textinput"
import Modal from "../../../../../assets/component/modal";
import Button from "../../../../../assets/component/button";
import ListDown from "../../../../../assets/component/listdown";
import DatePicker from "../../../../../assets/component/datepicker";
import COLORS from "../../../../../assets/component/colors";
import {AddItem,updateImageLink} from "../../../../../assets/js/firebase";
import {UploadData} from "../../../../../assets/js/upload";



export default function AddNewClaim(props) {

    const {add,pid} = props

    const ClaimCat = ['Petrol','Parking','Food','Utilities','Mobile','Stationery','Vehicle','Travel','Worker']


    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [name, onName] = useState("");
    const [dated, onDated] = useState("");
    const [amount, onAmount] = useState(0);
    const [category, onCategory] = useState(ClaimCat[0]);
    const [error, setError] = useState("");

    
    const [file, setFile] = useState("");
    const [percent, setPercent] = useState(0);


    useEffect(() => {
        if(modalVisible){
            setFile("")
            onName("")
            onAmount("")
            onCategory("")
        }

    }, [modalVisible]);


    function handleChange(event) {
        setPercent(0)
        if (event.target.value.length == 0) return null
            if(event.target.files[0].type.split('/')[0] === 'image'){
                Compress(URL.createObjectURL(event.target.files[0])) 
            }else{
                setFile(event.target.files[0])
            }
    }

    const Compress = async (uri) => {
        const manipResult = await ImageManipulator.manipulateAsync(
            uri,
            [],
            { compress: 0.1 ,base64:true}
      );
      setFile(manipResult.uri)
    };


    async function Validate(){

        setError('')
        var valid = true;
        if(!name || name.error){
            onName({error:"Invalid Name"});
            valid=false
        }

        if(!file){
            setError("Invalid File");
            valid=false
        }

        if(!category || category.error){
            onCategory({error:"Invalid Category"});
            valid=false
        }
    
        if(amount <= 0 || isNaN(amount) || amount.error){
            onAmount({error:"Amount must not be 0"});
            valid=false
        }
    
    
    
        if(valid){
                setIsloading(true)
                const Route = 'staff/'+pid+'/claims'
                const DocId = await AddItem({'name':name,'date':dated,'amount':parseFloat(amount),'category':category,'status':'pending'},Route);
                console.log(DocId)
                if(DocId){
                    const Route = 'staff/'+pid+'/claims'
                    const UploadedURL  = await UploadData(DocId.id,file,Route)
                    await updateImageLink(Route+'/'+DocId.id,UploadedURL)
                    DocId['image'] = UploadedURL
                    Success(DocId)
                }
        }
    
    }

      
      function Success(data){
        setIsloading(false)
        setModalVisible(false)
        onAmount(0)
        add(data)
      }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }



    const Popup = () => {

    
        return (
            <Modal title={'Add Claim'} icon={'receipt'} visible={modalVisible} setVisible={setModalVisible}>
                        <ScrollView >
                                <TextInput
                                    onChangeText={onName}
                                    maxLength={60}
                                    title="Company Name"
                                    value={name}
                                    autoCapitalize="none"
                                    errclear={true}
                                />
                                <ListDown list={ClaimCat} active={category} onPress={onCategory}/>
                                <input type="file" onChange={handleChange} />
                                <DatePicker set={onDated} value={dated}/>
                                <TextInput title={'Amount'} onChangeText={onAmount} value={amount} keyboardType="decimal-pad" maxLength={13} remark={"(RM)"}/>
                                <Error error={error}/>
                                {!isLoading?<Button title={'ADD'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
                        </ScrollView>
                </Modal>
        )
    }
    


    return(
        <View style={styles.container}>
                <Button title={'ADD CLAIMS'} icon={'receipt'} onPress={() => setModalVisible(true)} color={'#04b8a9'}/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      }
  });
  