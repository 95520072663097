import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';



export const Header = () => {
  return (
    <View style={{padding:10,borderBottomWidth:0.5,borderColor:'#eee',marginBottom:10}}>
         <Text>Header</Text>
      </View>
  )
}

export const Title = (props) => {
  const {data} = props
  if(!data) return null
  return (
    <View style={{marginBottom:15}}>
         <Text style={{fontWeight:'500',textDecorationLine:'underline'}}>{data}</Text>
    </View>
  )
}


export function Content(props) {
  const {data} = props
  if(data.content){
    var inx = 0

    return data.content.map((obj,ix) => {
      if(typeof(obj) == "object"){
        return obj.map((obj2,i) => {
          const views = [];
          if(obj2.title){ 
            inx++
            views.push(cTitle(obj2,inx,i))
          }

          if(obj2.down){return <Text key={i}></Text>}
          if(obj2.subA){return SubA(obj2.subA)}
          if(obj2.subN){return SubN(obj2.subN)}
          if(obj2.td){views.push(Subtable(obj2))}
          
          return views

        })
      }else{
        return <View key={ix} style={{flexDirection:"row",flexWrap:'wrap',marginBottom:10}}>{formatBold(obj)}</View>
      }
  });

  }else{
    return null
  }
}


function formatBold(text){
  var array = [], c = 0;
  const views = [];
  if(typeof(text) !== "object"){
        text.split(/([<>])/).filter(Boolean).forEach(e =>
        e == '<' ? c++ : e == '>' ? c-- : c > 0 ? array.push('<' + e + '>') : array.push(e)
        );
        var FntSize = '300'
        array.map((obj,i) => {
          if(obj == '<b>'){
            FntSize = '700'
          } else if (obj == '</b>') {
            FntSize = '300'
          } else {
            views.push(<Text style={{fontWeight:FntSize}} key={i}>{obj}</Text>)            }
        })

        return <Text>{views}</Text>
  }

}

function cTitle(obj,ix,i){
  return (
    <View style={{marginTop:15}} key={i}>
      <View style={{flexDirection:'row',flexWrap:'wrap'}}>
          <Text>
            <Text style={{fontWeight:'600'}}>{ix+'. '}</Text>
            <Text style={{fontWeight:'600'}}>{obj.title}</Text>
          </Text>
      </View>
        {formatBold(obj.inner)}
    </View>
  )
}


function SubN(obj){
  return obj.map((obj3,i) => {
    return (<Text key={i}>{obj3}</Text>)
  })
}

function SubA(obj){
  return obj.map((obj3,i) => {
    return (<Text key={i}>{obj3}</Text>)
  })
}

function Subtable(obj){
  
 const Th = () => {

  if(!obj.th[0].label) return null
    return (
      <View style={{flexDirection:'row',padding:10,flex:1}}>
        <View style={{borderWidth:1,flex:1}}><Text>{obj.th[0].label}</Text></View>
        <View style={{borderWidth:1,flex:1,alignContent:'center',alignItems:'center'}}><Text>{obj.th[1].label}</Text></View>
      </View>
    )
 }

 return <Th/>
 
  
}



const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  th:{
    flex:1,
    alignContent:'center',
    alignItems:'center'
  }
});

