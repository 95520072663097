import React, { useState,useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth';
import { Platform, StyleSheet , Text,View} from 'react-native';
import TextInput from "../../assets/component/textinput";
import Button from "../../assets/component/button";
import COLORS from "../../assets/component/colors";
import Ionicons from '@expo/vector-icons/MaterialIcons';



export default function ModalScreen() {

  const [password, onChangePassword] = useState("");
  const [email, onChangeEmail] = useState('');
  const [error, setError] = useState("");

  const auth = getAuth();

  useEffect(() => {

  }, []);

  function Login(){
    setError("")
    signInWithEmailAndPassword (auth, email, password)
    .then((userCredential) => {
      const token = userCredential.getIdToken();
    })
    .catch((error) => {
      setError("Invalid Login")
      console.log(error)
    });  
  }

  const Error = (props) => {
    if(!props.error) return null
    return (
        <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
    )
}

  return (
    <View style={styles.container}>
        <View style={styles.loginBox}>

            <Text style={styles.title}>LOGIN ADMIN</Text>

            <TextInput
              style={styles.input}
              onChangeText={onChangeEmail}
              value={email}
              placeholder={"Email"}
              keyboardType='email-address'
            />
            <TextInput
              secureTextEntry={true}
              style={styles.input}
              onChangeText={onChangePassword}
              value={password}
              placeholder="Password"
            />
            <Error error={error}/>
            <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center',flex:1,marginTop:20}}>
              <Button title="SIGN IN" onPress={() => Login()} color={'#0099ff'} Horizontal={50}/>
            </View>


        </View>

      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  center:{
    flexDirection:'row',
    alignContent:'center',
    padding:15,
    alignItems:'center',
    justifyContent:'center'
},
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom:10,
    color:'#777'
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  loginBox:{
    borderRadius:5,
    backgroundColor:'#fff',
    padding:20,
    shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 8,
},
shadowOpacity: 0.46,
shadowRadius: 11.14,

elevation: 17,
  },
});
