import React, { useState,useEffect } from 'react';
import { View, Text , useWindowDimensions , Button , ActivityIndicator,SafeAreaView} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { initializeApp } from 'firebase/app';
import {getAuth,onAuthStateChanged,} from 'firebase/auth';
import Header from './header';
import DebitScreen from '../screens/Account/accounts/debit_details'
import InvoiceScreen from '../screens/Order/invoice'
import LoginScreen from '../screens/login'
import Accounts from '../screens/Account/accounts'
import Statements from '../screens/Account/statement'
import Orders from '../screens/Order'
import Ordersonline from '../screens/Orderonline'
import Ordersquotation from '../screens/Quotation'
import Products from '../screens/Product'
import Costing from '../screens/Product/costing'
import CostingDetails from '../screens/Product/costing/details'
import Stock from '../screens/Product/stock'
import TransporterList from '../screens/Transporter/list'
import TransporterShipment from '../screens/Transporter/shipment'
import TransporterPayments from '../screens/Transporter/payments'
import Staff from '../screens/Staff'
import StaffPortal from '../screens/Staff/portal'
import StaffDetails from '../screens/Staff/portal/details/claims'
import Letter from '../screens/Staff/letter'
import Claims from '../screens/Staff/claims'
import CreditClaimsDetails from '../screens/Staff/claims/details'
import StaffSalary from '../screens/Staff/portal/details/salary'
import StaffDocument from '../screens/Staff/portal/details/documents'
import StaffLeave from '../screens/Staff/portal/details/leave'
import Payroll from '../screens/Staff/payroll'
import Ledger from '../screens/Report/ledger'
import Exspenses from '../screens/Report/exspenses'
import Exspenses_details from '../screens/Report/exspenses/details'
import CustomDrawer from './CustomDrawer'
import { Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';



const linking = {
  prefixes: ["http://localhost*", "https://mywebsite/app"],
  config: {
    screens: {
      Home: '',
      Profile: 'profile',
      Updates: 'blog/:id',
      account: 'account',
      statement: 'statement',
      debit: 'debit/:year/:bill',
      orders: 'orders',
      invoice: 'order/:bill',
      order_ecommerce: 'order/e-commerce',
      order_quotation: 'order/quotation',
      product: 'product',
      costing: 'product/costing',
      costing_details: 'product/costing/:id',
      transporters: 'transporters',
      transporter_shipment: 'transporters/shipment',
      transporter_payments: 'transporters/payment',
      stock:'product/:pid',
      staff: 'staff',
      Staff_portal: 'staff/:pid',
      Staff_Details:'staff/:pid/claim',
      Staff_Payroll:'staff/:pid/payroll',
      Staff_Documents:'staff/:pid/documents',
      Staff_Leave:'staff/:pid/leave',
      letter: 'letter',
      claims: 'claims',
      Claims_details:'credit/claims/:cid',
      payroll: 'payroll',
      Ledger: 'Ledger',
      Exspenses: 'Exspenses',
      exspense_details: 'Exspenses/:year/:type',
      exspense_details_sub: 'Exspenses/:year/:type/:sub',
      //billing:'billing',
      NotFound: '*',
    },
  },
};




const LargeScreen = () => {
  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 768;
  return isLargeScreen
}


const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();





export default function App() {


  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();


  const config = {
    apiKey: "AIzaSyCPYw1lvp71s75Ar7Xm-M3NnMKaP11aRgY",
    authDomain: "donkeezacc-edb84.firebaseapp.com",
    projectId: "donkeezacc-edb84",
    storageBucket: "donkeezacc-edb84.appspot.com",
    messagingSenderId: "1005534989803",
    appId: "1:1005534989803:web:15c932dbdf015b5b6dddae",
    measurementId: "G-1C9HCLNVRK",
    databaseURL: "https://donkeezacc-edb84-default-rtdb.asia-southeast1.firebasedatabase.app"
  };

  const Icon = (name) => {
    return(
      <MaterialCommunityIcons name={name} size={20} color={'#aaa'}/>
    )
  }

  const DrawerScreen = (name,component,label,icon) => {
    return <Drawer.Screen name={name} component={component} options={{ drawerLabel: label, drawerIcon: () => Icon(icon) }}/>
  }

  const DrawerNonScreen = (name,component) => {
    return <Drawer.Screen name={name}  component={component} options={{drawerItemStyle: { display: 'none' }}}/>
  }



  function MyDrawer(props) {
    const {user} = props
  const Largescreen =LargeScreen()
  
    return (
      <Drawer.Navigator
      useLegacyImplementation initialRouteName="account"
        screenOptions={{
          unmountOnBlur: true,
          drawerActiveTintColor:"#4581b3",
          drawerInactiveTintColor:"#aaa",
          drawerType: Largescreen ? 'permanent' : 'front',
          drawerStyle:{backgroundColor:'#444',width:180},
          overlayColor: 'transparent',
          header: props => <Header {...props} user={user} LargeScreen={Largescreen} refresh={()=>setInitializing(true)}/>,
        }}
        drawerContent={(props) => <CustomDrawer {...props} user={user}/>}
        backBehavior="history"
      >

        {DrawerNonScreen("account",Accounts)}
        {DrawerNonScreen("statement",Statements)}
        {DrawerNonScreen("debit",DebitScreen)}
      
        {DrawerNonScreen("product",Products)}
        {DrawerNonScreen("costing",Costing)}
        {DrawerNonScreen("costing_details",CostingDetails)}
        {DrawerNonScreen("stock",Stock)}

        {DrawerNonScreen("Ledger",Ledger)}
        {DrawerNonScreen("Exspenses",Exspenses)}
        {DrawerNonScreen("exspense_details",Exspenses_details)}
        {DrawerNonScreen("exspense_details_sub",Exspenses_details)}


        {DrawerNonScreen("orders",Orders)}
        {DrawerNonScreen("order_ecommerce",Ordersonline)}
        {DrawerNonScreen("order_quotation",Ordersquotation)}
        {DrawerNonScreen("invoice",InvoiceScreen)}

        {DrawerNonScreen("transporters",TransporterList)}
        {DrawerNonScreen("transporter_shipment",TransporterShipment)}
        {DrawerNonScreen("transporter_payments",TransporterPayments)}

        {DrawerNonScreen("staff",Staff)}
        {DrawerNonScreen("Staff_portal",StaffPortal)}
        {DrawerNonScreen("Staff_Details",StaffDetails)}
        {DrawerNonScreen("letter",Letter)}
        {DrawerNonScreen("claims",Claims)}
        {DrawerNonScreen("Claims_details",CreditClaimsDetails)}
        {DrawerNonScreen("Staff_Payroll",StaffSalary)}
        {DrawerNonScreen("Staff_Documents",StaffDocument)}
        {DrawerNonScreen("Staff_Leave",StaffLeave)}
        {DrawerNonScreen("payroll",Payroll)}

      </Drawer.Navigator>
    );
  }
  

  initializeApp(config);

  const auth = getAuth();
  

  onAuthStateChanged(auth, user => {
    if (user != null) {
      setUser(user)
    }else{
      setUser()
    }
    if (initializing) setInitializing(false);
  });

 if(initializing){
   return(
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator size="large" color="#00ff00" />
      <Text>Loading</Text>
    </View>
   )
 }

  if(!user){
    return (
      <NavigationContainer>
          <Stack.Navigator screenOptions={{headerShown: false}}>
            <Stack.Screen name="MainScreen" component={LoginScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    )
  }


  return (
    <SafeAreaView style={{backgroundColor:'#eee',flex:1}}>
        <NavigationContainer linking={linking}>
          <MyDrawer user={user} />
        </NavigationContainer>
    </SafeAreaView>
  );
}
