import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator, Alert } from 'react-native';
import {AddDoc,GetDocument,UpdateDoc} from "../../../../assets/js/firebase";
import { Button,TextInput,ListDownoverlay } from '../../../../assets/component';
import SupplierList from './supplier';
import ProductList from './productlist';
import KastamList from './kastam';
import ShippingList from './shipping';


export default function CostingScreen({route,navigation}) {

    const {id} = route.params;

    const [supplierData, SetSupplierData] = useState([]);
    const [kastamData, SetKastamData] = useState([]);
    const [products,setProducts] = useState([])
    const [blno, setBlno] = React.useState('');
    const [isLoading,setIsloading] = useState(false)
    const [status,setStatus] = useState('pending')



    const [shippingData, SetShippingData] = useState([]);



    useEffect(() => { 
        GetCosting()
    }, []);

    async function GetCosting(){

      const Data = await GetDocument(id,'costing')
      if(Data){
        if(Data.supplier){SetSupplierData(Data.supplier)}
        if(Data.kastam){SetKastamData(Data.kastam)}
        if(Data.products){setProducts(Data.products)}
        if(Data.shipping){ SetShippingData(Data.shipping)}
        if(Data.bl){ setBlno(Data.bl)}
        if(Data.status){ setStatus(Data.status)}
      }

    }



    function sumBy(arr){
      return arr.reduce((sum, { amount }) => sum + parseFloat(amount), 0).toFixed(2);
    }


    async function Submit(){
      setIsloading(true)
      const Data = id && id != 'new' ? 
          await UpdateDoc(id,'costing',{'supplier':supplierData,'kastam':kastamData,'products':products,'shipping':shippingData,'bl':blno,'status':status}) :
          await AddDoc('costing',{'supplier':supplierData,'kastam':kastamData,'products':products,'shipping':shippingData,'bl':blno,'status':status})
      if(id == 'new'){navigation.navigate('costing')}
      if(Data){
        setIsloading(false)
        alert('Success')
      }
    }

    const BackBtn = () => {
      return (
        <TouchableOpacity style={{padding:5}} onPress={()=>navigation.navigate('costing')}>
          <Text style={{color:'blue'}}> {'<'} Back </Text>
        </TouchableOpacity>
      )
    }

    const BLinput = () => {
      return (
        <View>
                <Text style={styles.h1}>#BL NO </Text>
                <View style={{width:100}}><TextInput value={blno} onChangeText={setBlno} placeholder="#BL no"/></View>
        </View>

      )
    }

    const Status = () => {
      return (
        <View style={{zIndex:99,width:150,paddingHorizontal:10}}>
          <Text style={styles.h1}>STATUS</Text>
           <ListDownoverlay list={['completed','pending']} active={status} onPress={setStatus}/>
        </View>
      )
    }


    return(
        <View style={styles.container}>
          <BackBtn/>
          <View style={{flexDirection:'row',justifyContent:'flex-end',alignItems:'center',zIndex:99}}>
                <Status/>
                <BLinput/>
          </View>
          <View style={{flexDirection:'row',borderBottomWidth:1,borderColor:'#aaa'}}>
            <View style={styles.box}><SupplierList SetSupplierData={SetSupplierData} supplier={supplierData}/></View>
            <View style={styles.box}><KastamList SetKastamData={SetKastamData} kastam={kastamData}/></View>
          </View>
          <ProductList shipping={sumBy(shippingData)} setProducts={setProducts} products={products} suppliersum={sumBy(supplierData)} kastamsum={sumBy(kastamData)}/>
          <ShippingList set={SetShippingData} data={shippingData}/>
          <Button isloading={isLoading} title={supplierData.length?'Update':'Save'} icon={'download-outline'} onPress={()=>Submit()} color={'#6464ff'}/>
        </View>
    )




}

const styles = StyleSheet.create({
    container:{
        padding:10,
        backgroundColor:'#eee'
    },
    h1:{
      fontSize:22,
      fontWeight:'500'
    },
    orderSelect:{
        flexDirection:'row',
        borderWidth:1,
        borderRadius:4,
        paddingHorizontal:5,
        borderColor:'#ddd',
        flex:1,
        marginBottom:4,
    },
    morebtn:{
        marginVertical:4,
        borderRadius:5,
        borderWidth:1,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        padding:4,
        flexDirection:'row'
    },
    box:{
      margin:5,
      flex:1
    }
});
