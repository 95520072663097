import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,useWindowDimensions} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useFocusEffect } from '@react-navigation/native';



export default function PopMenu(props) {

    const {menu} = props

    const [drop, setDrop] = useState(false);

    const dimensions = useWindowDimensions();


    useFocusEffect(
      React.useCallback(() => {
        setDrop(false)
      }, [])
    );



    const ListItem = () => {

      const Pos = dimensions.width <= 768 ? {right:40} : {top:40,right:10} 


      if(!drop) return null
        return(
          <View style={[styles.card, styles.shadowProp,Pos,{zIndex:33,position:'absolute'}]}>
                 {menu.map((key,i) => {
                    return <View key={i}>{key}</View>
                    })}
          </View>
        )
    }


    const DropBox = () => {
      const Icon = dimensions.width <= 768 ? 'ellipsis-vertical':'ellipsis-horizontal'
      return (
          <TouchableOpacity style={styles.listbox} onPress={()=>setDrop(!drop)}>
            <Ionicons name={Icon} size={18} color={'#777'} /> 
          </TouchableOpacity>
      )
    }






        return (
            <View style={{marginVertical:5,zIndex:33}}>
                  <DropBox/>
                  {ListItem()}
            </View>
        )




}

const styles = StyleSheet.create({
  text:{
    paddingLeft:5,
    textTransform: 'capitalize',
    fontSize:12,
    color:'#777'
  },
  list:{
    width:110,
    padding:10,
    flexDirection:'row'
  },
  card: {
    backgroundColor: 'white',
    borderWidth:1,
    borderColor:'#ccc',
    zIndex:33,
    borderRadius:5,
  },
  shadowProp: {
    shadowColor: '#171717',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  listbox:{
    zIndex:1,
    padding:10,
  }
});
