import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';
import {Button} from "../../../assets/component";



export default function Footer(props) {

    const {picked,set } = props

      if(!picked.length) return null
  
      const Sum = picked.reduce((n, {amount}) => n + parseFloat(amount), 0).toFixed(2)
      return (
        <View style={{flexDirection:'row',padding:10,backgroundColor:'#bbeeff',position:'absolute',width:'100%',bottom:0,zIndex:99}}>
          <View style={{flex:1}}>
              <View><Text>TOTAL : {picked.length}</Text></View>
              <View><Text>SUM : RM{Sum}</Text></View>
          </View>
          <Button title={'Clear'} onPress={()=> set([])} color={'#4a90ff'} icon={'clear'}/>
        </View>
      )


}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  tabActive:{
    borderWidth:1,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    borderBottomWidth:0,
    borderColor:'#ccc',
    borderBottomColor:'#fff',
    backgroundColor:'#fff'
  },
  tabInActive:{
    borderBottomWidth:1,
    borderColor:'#ccc'
  },
});
