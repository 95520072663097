import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';


export default function Table(props) {

  const {list,loading} = props





const Th = (title,size,color) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} >
          <Text style={[styles.th,{color:color?color:'#5700a9'}]}>{title}</Text>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('MONTH',1)}
          {Th('CREDIT',1,'red')}
          {Th('DEBIT',1,'blue')}
          {Th('PROFIT',1)}
        </View>
      )
    }


    const RenderItem = ({item,index}) => {

      const Item = (data,string) => {
        return <View style={{flex:1}}><Text style={styles.item}>{string?data:'RM'+data.toFixed(2)}</Text></View>
      }

      if(!item.credits && !item.debits) return null

      return(
        <View>
        <View style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#eedcff" }}>
              <View style={{flexDirection:'row',flex:1}}>
                {Item(item.month,true)}
                {Item(item.credits)}
                {Item(item.debits)}
                {Item(item.debits-item.credits)}
              </View>
        </View>
        </View>
      )
  }

  const Loader = () => {
    return (
      <View style={{padding:10}}>
        <ActivityIndicator size={'small'}/>
      </View>
    )
  }




    return(
      <View style={styles.container}>
          {Header()}
          {loading?<Loader/>:
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
          }     
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  th:{
    marginRight:5,
    fontWeight:'600',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    fontWeight:'bold',
    color:'#e07c00',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  }
});
