import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity} from 'react-native';
import COLORS from './colors';
import Text from "./text";
import Ionicons from '@expo/vector-icons/Ionicons';



export default function ListDown(props) {

    const {active ,onPress,list } = props

    const [drop, setDrop] = useState(false);




    const ListItem = () => {
        
        return(
            <View style={[styles.card, styles.shadowProp]}>
            {list.map(key => {
                return (
                    <TouchableOpacity key={key} style={[styles.list,{backgroundColor:key == active && COLORS.Primary}]} onPress={()=>{onPress(key),setDrop(false)}}>
                        <Text style={[styles.text,{color:key == active && '#fff'}]}> {key} </Text>
                    </TouchableOpacity>
                ); 
                })}
            </View>
        )
    }
        const Error = active.error

        return (
            <View style={{marginVertical:5}}>
                        <TouchableOpacity style={{borderWidth:1,padding:10,borderColor:'#ccc',borderRadius:5,flexDirection:'row'}} onPress={()=>setDrop(!drop)}>

                            <Text numberOfLines={1} style={[styles.text,{flex:1,color:!active || active.error && '#ccc'}]}> {active && !active.error?active:'Choose Category'} </Text>
                            <Ionicons name='chevron-down' size={18} color={'#777'} />
                        </TouchableOpacity>
                {drop?ListItem():null}
                {active.error?<View style={styles.errbox}><Ionicons name={"alert-circle-outline"} size={18} color={COLORS.Error}/><Text style={styles.error}> {active.error}</Text></View>:null}
             </View>
        )





}

const styles = StyleSheet.create({
    text:{
        textTransform: 'capitalize',
        fontSize:16,
      },
      tabActive:{
        borderWidth:1,
        borderTopLeftRadius:10,
        borderTopRightRadius:10,
        borderBottomWidth:0,
        borderColor:'#ccc',
        borderBottomColor:'#fff',
        backgroundColor:'#fff'
      },
      tabInActive:{
        borderBottomWidth:1,
        borderColor:'#ccc'
      },
      errbox:{
        flexDirection:'row',
        textAlign:'center',
        alignItems:'center',
      },
      list:{
        padding:10,
        borderColor:'#eee',
        borderBottomWidth:0.5
      },
      card: {
        backgroundColor: 'white',
        width: '100%',
        borderLeftWidth:1,
        borderRightWidth:1,
        borderColor:'#ccc',
        marginTop:-3,
        zIndex:33,
        borderBottomLeftRadius:10,
        borderBottomRightRadius:10,
      },
      shadowProp: {
        shadowColor: '#171717',
        shadowOffset: {width: 2, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 3,
      },
});
