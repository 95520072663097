import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert,FlatList , ActivityIndicator} from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../assets/component/colors";
import {GetDocument,GetDocsByids} from "../../../../assets/js/firebase";


export default function UpdateClaim({route,navigation}) {

    const {cid} = route.params;


    const [item, setItem] = useState(false);
    const [claims, setClaims] = useState(false);
    const [isLoading, setIsloading] = useState(false);



    useEffect(() => {
        if(cid){
            setItem(cid)
            GetClaimsDetails()
        }
      }, []);


    async function GetClaimsDetails(){
        const data = await GetDocument(cid,'claims')
        setItem(data)
        const Listitem = await GetDocsByids("staff/"+data.staff+'/claims',data.claim_id)
        setClaims(Listitem)
    }


    if(!cid) return null

    const Th = (title,size) => {
        return(
            <View style={{flex:size}}>
                <Text style={styles.item}>{title}</Text>
            </View>
      
        )
      }
      
      
          const Header = () => {
            return(
              <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
                {Th('Category',1)}
                {Th('Name',1)}
                {Th('Date',1)}
                {Th('Amount',1)}
                <View style={{width:20}}/>
              </View>
            )
          }


    const RemoveLoader = (id) => {
        if(id != isLoading) return null
        return (
          <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
            {Loader(true)}
          </View>
        )
      }

      const ImageBtn = (link) => {
        if(!link) return null
        return (
          <TouchableOpacity style={{width:20}} onPress={()=>window.open(link, '_blank')}>
            <Ionicons name='image' size={22} color={'#dd5959'} />
          </TouchableOpacity>
        )
      }

    
    const RenderItem = ({item,index}) => {

        const BgColor = index % 2 == 0  ? "#FFFFFF" : "#ffefef"
  
        return(
          <View>
            {RemoveLoader(item.id)}
              <TouchableOpacity onPress={()=>console.log(item)} style={{padding:5,paddingVertical:15,backgroundColor: BgColor }}>
                    <View style={{flexDirection:'row'}}>
                        <View style={{flex:1}}><Text style={[styles.item]}>{item.category}</Text></View>
                        <View style={{flex:1}}><Text style={[styles.item]}>{item.name}</Text></View>
                        <View style={{flex:1}}><Text style={[styles.item]}>{ReformatTime(item.createdAt)}</Text></View>
                        <View style={{flex:1}}><Text style={[styles.item]}>RM{(item.amount).toFixed(2)}</Text></View>
                        {ImageBtn(item.image)}
                    </View>
              </TouchableOpacity>
          </View>
        )
    }

    
  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY');
    return format
  }

  const SumTotal = (total) => {
    if(!total) return null
    return (
      <View style={{alignItems:'center',flexDirection:'row'}}>
        <Text style={{fontSize:12,color:'#aaa'}}>Total (RM) </Text>
        <Text style={{fontSize:18,fontWeight:'600',color:'#39917e'}}>{total.toFixed(2)}</Text>
      </View>
    )
  }

  const Details = () => {
    if(!item) return null
    return (
        <View>
            <Text>ID : {cid}</Text>
            <Text style={styles.id}>Staff : {item.staff}</Text>
            <Text style={styles.id}>Status : {item.status}</Text>
            {SumTotal(item.amount)}
        </View>
    )
  }


        return (
            <View style={styles.container}>
                    <View style={{flex:1}}>
                            <Details/>
                            {Header()}
                            <FlatList
                                showsHorizontalScrollIndicator={false}
                                data={claims}
                                renderItem={RenderItem}
                                onEndReachedThreshold={0}
                            />
                        </View>
                </View>
        )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    id:{
        color:COLORS.Link
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      }
  });
  