import React, { useState,useEffect } from 'react';
import { StyleSheet ,View, ActivityIndicator,Switch,TouchableOpacity } from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import Clipboard from '@react-native-clipboard/clipboard';
import {ListDownoverlay,Button,Modal,TextInput,Copy,Text} from "../../../../assets/component";
import COLORS from "../../../../assets/component/colors";
import {UpdateDoc,CURRENT_ACC,AddDoc} from "../../../../assets/js/firebase";

export default function Update(props) {

    const {clear,data,update,tab} = props

    const [values,setValues] = useState({'name':'','mobile':'','bank':'','acc':''})
    const [transporterList, setTransporterList] = useState([]);

    const [isLoading, setIsloading] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [inHouse, setinHouse] = useState(false);
    const [account, setAccount] = useState(null);
    const [newacc, setNewacc] = useState(false);



    const BankLIST = ['MAYBANK','CIMB','BANK ISLAM','AFFIN','ALLIANCE','AMBANK','BANK RAKYAT','HLB','HSBC','MUAMALAT','OCBC','PBB','RHB','UOB','BSN']


    useEffect(() => {
      GetAccount()
    }, []);




    useEffect(() => {
      if(data && data.name){
              setNewacc(false)
              setIsEnabled(tab=='active'?true:false)
              changeHandler(data.name,'name')
              changeHandler(data.mobile,'mobile')
              if(data.bank){changeHandler(data.bank,'bank')}
              if(data.acc){changeHandler(data.acc,'acc')}
              if(data['in-house']){setinHouse(data['in-house'])}
      }else{
        setIsEnabled(true)
        setinHouse(false)
        setValues({'name':'','mobile':'',"bank":'','acc':''})
        setNewacc(true)
      }
    }, [data]);


    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }


    function changeHandler (val,type){
      setValues( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }




    async function Validate(){

      const validName = Validation(values.name,'name');

      const validMobile = ValidatePhone(values.mobile,'mobile')


 
      if(validName && validMobile){
        setIsloading(true)
        var Updated ;
        const NewData = {...values} 
        NewData['status'] = isEnabled?'active':'inactive'
        NewData['in-house'] = inHouse
        if(data.id){
          Updated = await UpdateDoc(data.id,'transporter_list',NewData)
        }else{
          Updated = await AddDoc('transporter_list',{...NewData,'status':'active'})
        }
        if(Updated){
          update(Updated)
          ClearState()
        }else{
          console.log("Unable to update")
        }
      }

  }


  
  function Validation(input,change){
    if(!input || input.error){
      changeHandler({error:"Invalid Value"},change)
      return null
    }
    return input
}


    function ValidatePhone(data,change) {
        const Input = !data.error && data ? data.replace(/\D/g,'').replace('6','') : null;
        if(Input){
          if( /^\d+$/.test(Input) &&  ( (Input.charAt(0) === '0' && Input.charAt(1) === '1' && Input.length >= 10 && Input.length <= 11) || (Input.charAt(0) === '1' && Input.length >= 9 && Input.length <= 10) )  ){
            if(Input.charAt(0) === '1'){ Input = '0' + Input;}
            return '+6'+Input;
          } 
        }
        changeHandler({error:"Invalid Value"},change)
        return null;
    }

    function ClearState(){
      setIsloading(false)
      clear(null)
    }

    const AccountLink = () => {

      if(newacc) return  null

      const Link = 'http://deliverydkz.web.app/account/'+account+'/'+data.id

      return (
        <Copy link={Link} title={'Account Link'}/>
      )
    }



    const ButtonRow = () => {
      if(isLoading) return <ActivityIndicator size={'small'}/>
      return (
        <View style={{flexDirection:'row'}}>
            <View style={{flex:1}}/>
            <Button title={newacc?'ADD':'Update'} color={newacc && 'red'}  icon={newacc?'add-circle':'md-checkmark'} onPress={() => Validate()}/>
        </View>
      )
    }

    const Toggle = (props) => {
      const {toggle,title,value,details} = props
      if(newacc && title == 'Status') return null
      return (
        <View style={{padding:5}}>
            <View style={{flexDirection:'row',flex:1}}>
                <Text style={{flex:1,fontWeight:'400'}}>{title}</Text>
                <Switch
                    trackColor={{false: '#767577', true: '#81b0ff'}}
                    thumbColor={value ? '#4971a4' : '#f4f3f4'}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={()=>toggle(previousState => !previousState)}
                    value={value}
                />

            </View>
            <Text style={{fontSize:12,color:'#aaa'}}>* {details}</Text>
        </View>
      )
    }


    if(!data || !account) return null



        return (
                <Modal title={newacc?'ADD NEW':'Update'} icon={'truck'}  Iconfont={'MaterialCommunityIcons'} visible={data?true:false} setVisible={()=>ClearState()}>
                          <Toggle toggle={setIsEnabled} value={isEnabled} title={'Status'} details={'Disable to inactivate'}/>
                          <TextInput onChangeText={(val)=>changeHandler(val,'name')} uneditable={false} maxLength={560} title="Name" value={values.name} autoCapitalize="true"  errclear={true}/>
                          <TextInput onChangeText={(val)=>changeHandler(val,'mobile')} uneditable={false} maxLength={560} title="Mobile" value={values.mobile} autoCapitalize="true"  errclear={true}/>
                          <Toggle toggle={setinHouse} value={inHouse} title={'Company Transporter'} details={'Enable this if transporter dont have to be billed'}/>
                          <ListDownoverlay height={150} name={'Choose Bank'} list={BankLIST.sort()} onPress={(val)=>changeHandler(val,'bank')} active={values.bank}/>
                          <TextInput onChangeText={(val)=>changeHandler(val,'acc')} uneditable={false} maxLength={560} title="Account" value={values.acc} autoCapitalize="true"  errclear={true}/>
                          <AccountLink/>
                          <ButtonRow/>
                </Modal>
        )

    

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      bullet:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1,
        backgroundColor:'#428876'
      },
      bullet2:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1
      }
  });
  