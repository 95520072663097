import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator } from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../assets/component/colors";
import TextInput from "./minitextinput";



export default function ProductList(props) {

    const {setProducts,products,suppliersum,kastamsum,shipping} = props

    const [SumMyr, setSumMyr] = useState(0);


    useEffect(() => { 

    }, []);

    const Exchg = suppliersum / sumMultiply(products)
    
    const SumKastam = sumBy(products,'duty')

    const Table =    [{name:'NAME',size:3},
                        {name:'QTY',size:1},
                        {name:'U/PRICE(USD)',size:2},
                        {name:'TOTAL(USD)',size:2},
                        {name:'DUTY + SST TOTAL',size:3},
                        {name:'DUTY(PCS)',size:2},
                        {name:'CBM',size:1},
                        {name:'CBM COST COURIER(PCS)',size:2},
                        {name:'COST',size:2}]



function ChangeAmt(index,val,type){
        const NewOrder = [...products]
        NewOrder[index][type] = val
        setProducts(NewOrder)
}

function clearOrder(key){
  const NewOrder = [...products]
  NewOrder.splice(key, 1);
  setProducts(NewOrder)
}

function AddMore(){
    const newval = {'name':'','qty':0,'unit':0,'duty':0,'amount':0,'value':0,'cbm':0}
    setProducts([newval,...products])
} 


const ItemList = (val) => {
        if(!val) return null
        return (
            <View>
                {val.map((i,key)=>{
                    const Total = i.qty*i.unit
                    const MyrSum = Exchg * Total
                    const Convert = (Exchg * Total)/i.qty
                    const dutyPcs = parseFloat(i.duty/i.qty)
                    const CostCourier = (i.cbm * shipping/sumBy(products,'cbm')) / i.qty
                    const Costing = Convert+dutyPcs+CostCourier
                    return (
                    <View style={styles.orderSelect} key={key}>
                        {InputBx('name',key,i.name,Table[0].size)}
                        {InputBx('qty',key,i.qty,Table[1].size)}
                        <View style={{flex:Table[2].size}}>
                            {InputBx('unit',key,i.unit,1,'$')}
                            <Text style={styles.sml}>RM{Convert.toFixed(2)}</Text>
                        </View>
                        <View style={{flex:Table[3].size}}>
                                {DisableBx(Total.toFixed(2),'$')}   
                                <Text style={styles.sml}>RM{MyrSum.toFixed(2)}</Text>
                        </View>
                        {InputBx('duty',key,i.duty,Table[4].size,'RM')}
                        {DisableBx(dutyPcs.toFixed(2),'RM',Table[5].size)}   
                        {InputBx('cbm',key,i.cbm,Table[6].size)}
                        {DisableBx(CostCourier.toFixed(2),'RM',Table[7].size)}
                        {CostBx(Costing.toFixed(2),Table[8].size)}
                        <TouchableOpacity style={{width:20}} onPress={()=>clearOrder(key)}>
                              <Ionicons name='close' size={16} color={COLORS.Error} />
                        </TouchableOpacity>
                    </View> 
                    )
                })}
            </View>
        )
    }
    

    const InputBx = (type,key,val,flex,currency) => {
        const Equal = parseFloat(kastamsum).toFixed(2) == parseFloat(SumKastam).toFixed(2)
        const BgColor =  type == 'duty' && !Equal && '#ffb0b0'
        return (
            <View style={{flex:flex,backgroundColor:BgColor}}><TextInput currency={currency} onChangeText={(val)=>ChangeAmt(key,val,type)} value={val}/></View> 
        )
    }

    const DisableBx = (val,currency,size) => {
        return (
            <View style={{flex:size}}><TextInput disable={true} currency={currency} value={val}/></View> 
        )
    }

    const CostBx = (val,size) => {
        return (
            <View style={{flex:size,alignItems:'center',marginTop:5}}><Text style={{fontWeight:'500',color:'#29b136'}}>RM{val}</Text></View> 
        )
    }



    function sumBy(arr,type){
      return arr.reduce((sum,item) => sum +  parseFloat(item[type]), 0);
    }

    function sumMultiply(arr){
        return arr.reduce((sum,item) => sum + (parseFloat(item['unit']) * parseFloat(item['qty'])), 0);
    }

    const TH = (name,flex,key) => {
        return(
            <View key={key} style={{flex:flex,alignContent:'center',flexWrap:'wrap'}}><Text style={styles.txt}>{name}</Text></View> 
        )
    }

    const TH2 = (name,flex,key,color) => {
        return(
            <View key={key} style={{flex:flex,alignContent:'center',flexWrap:'wrap'}}><Text style={[styles.txt2,{color:color}]}>{name}</Text></View> 
        )
    }
    

    const Header = () => {
        if(!products.length) return null
        return (
            <View style={styles.head}>
                {Table.map((i,k)=>{
                     return TH(i.name,i.size,k)
                })}
                <View style={{width:20}}/>
            </View>
        )
    }

    const Footer = () => {
        if(!products.length) return null
        const color = kastamsum == SumKastam.toFixed(2) ? 'blue':'red'
        const TotalSum = sumMultiply(products).toFixed(2)

        return (
            <View style={styles.footer}>
                {TH2('',Table[0].size,0)}
                {TH2(sumBy(products,'qty'),Table[1].size,1)}
                {TH2('',Table[2].size,2)}
                {TH2('$'+TotalSum,Table[3].size,3)}

                {TH2('RM'+SumKastam.toFixed(2),Table[4].size,4,color)}
                {TH2('',Table[5].size,5)}
                {TH2(sumBy(products,'cbm').toFixed(2),Table[6].size,6)}
                {TH2('',Table[7].size,7)}
                {TH2('',Table[7].size,8)}
                <View style={{width:20}}/>
            </View>
        )
    }

    const Details = (title,value) => {
        return (
        <View style={{flexDirection:'row',marginRight:10}}>
            <Text>{title} : </Text>
            <Text style={{color:'#a52',fontWeight:'500',textDecorationLine:'underline'}}>RM{value.toFixed(2)}</Text>
        </View>
        )
    }



    return(
        <View style={styles.container}>
             <View style={{flexDirection:'row'}}>
                <Text style={styles.h1}>ITEM LIST</Text>
                <TouchableOpacity style={styles.morebtn} onPress={()=>{AddMore()}}>
                        <Ionicons name='add' size={18} color={'blue'} />
                        <Text style={{color:'blue'}}>Add</Text>
                </TouchableOpacity>
            </View>
            <Header/>
                {ItemList(products)}
            <Footer/>
            <View style={{flexDirection:'row'}}>
            {Details('Exchange Rate',Exchg)}
            {Details('Per Cbm Cost',shipping/sumBy(products,'cbm'))}
            </View>
        </View>
    )




}

const styles = StyleSheet.create({
    container:{
        paddingVertical:10,
        backgroundColor:'#eee'
    },
    txt:{
        fontSize:10,
        textAlign:'center'
    },
    txt2:{
        textAlign:'center',
        fontWeight:'500',
    },
    h1:{
      fontSize:22,
      fontWeight:'500'
    },
    orderSelect:{
        flexDirection:'row',
        paddingHorizontal:5,
        borderColor:'#ddd',
        flex:1,
    },
    morebtn:{
        marginVertical:4,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        padding:4,
        flexDirection:'row'
    },
    head:{
        flexDirection:'row',
        borderBottomWidth:1,
        borderColor:'#aaa',
        padding:5,
        flex:1,
        marginBottom:5
    },
    footer:{
        flexDirection:'row',
        borderTopWidth:1,
        borderColor:'#aaa',
        padding:5,
        flex:1
    },
    sml:{
        fontSize:12,
        color:'#bbb',
        marginHorizontal:7
    }
});
