import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert, ActivityIndicator,FlatList,ScrollView} from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import TextInput from "../../../assets/component/textinput";
import Modal from "../../../assets/component/modal";
import Button from "../../../assets/component/button";
import COLORS from "../../../assets/component/colors";
import {AddQuotation,GetAllDocs,GetCustomerDataByMobile} from "../../../assets/js/firebase";
import Products from './productslist'

export default function AddNewOrder(props) {

    const {add,data,visible,setvisible} = props


    const [modalProducts, setModalProducts] = useState(true);
    const [isLoading, setIsloading] = useState(false);
    const [name, onName] = useState("");
    const [company, onCompany] = useState("");
    const [mobile, onMobile] = useState("");
    const [address, onAddress] = useState("");
    const [deliveryaddress, onDelivery] = useState("");
    const [remark, onRemark] = useState("");
    const [deliveryadd, setDelivery] = useState(false);
    const [error, setError] = useState("");
    const [selected, setSelected] = useState([]);
    const [productList, setProductList] = useState(null);



    useEffect(() => {
      if(data){
        onName(data.name)
        onCompany(data.company)
        onAddress(data.address)
        onDelivery(data.delivery)
        onRemark(data.remark)
        onMobile(data.mobile)
      }
  }, [data]);



  useEffect(() => {
    const getDatas = async () => {
      const ListProduct = await GetAllDocs('products','active')
      setProductList(ListProduct)
    }
    getDatas()
  }, []);



    const onChangePrice = (index,val) => {
        var Amount = val.replaceAll(",", "");
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                amount: Amount?Amount.replace(/^0+/, ''):'0'
              };
            } else {
              return userScore;
            }
          })
        );
      };

      const onChangeQty = (index,val,stk) => {
        const qty = val?parseInt(val):0
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                qty: parseInt(qty)
              };
            } else {
              return userScore;
            }
          })
        );
      };




    async function Validate(){
        setError('')

        var valid = Validation(name,onName);


        var validMobile = ValidatePhone(mobile)
        console.log(validMobile)

        if(!validMobile){
          onMobile({error:"Invalid Mobile Phone no"})
        }else{
          onMobile(validMobile)
        }

   
        if(valid && validMobile){
                setIsloading(true)
                selected.forEach(e => {delete e.stock,delete e.createdAt});
                const ValidateData = {
                  'name':name,
                  'company':company,
                  'mobile':validMobile,
                  'address':address,
                  'delivery':deliveryaddress,
                  'remark':remark,
                  'amount':selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2),
                  'products':selected
                }
                const Added = await AddQuotation(ValidateData)
                add(Added)
                clear()
        }

    }

    function Validation(input,change){
        if(!input || input.error){
            change({error:"Invalid Value"});
            return false
        }
        return true
    }


    function clear(){
        setIsloading(false)
        onName("")
        onAddress("")
        onCompany("")
        onRemark("")
        onMobile("")
        setSelected([])
        setvisible(false)
        setModalProducts(true)
    }




    function ValidatePhone(data) {
      const Input = !data.error && data ?  data.replace(/\D/g,'').replace(/^6/, '') : null;
      
      if(Input.length >= 10 && Input.length <= 11 && Input.charAt(0) === '0'){
          return '+6'+Input;
      }
      return null;
    }

    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><MaterialIcons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }



    function RemoveProducts(index){
        const temp = [...selected];
        temp.splice(index, 1);
        setSelected(temp);
        if(!temp.length){
            setModalProducts(true)
        }
    }






    function SelectProduct(data) {
      setSelected(data)
      setModalProducts(false)
    }




    const ProductSelected = (item) => {

        function qtyChanger(i,val,stock){
          if(val <= stock){
            onChangeQty(i,val,stock)
          }
        }

        const aaa = () => item.map((i, index) => {

            const Stock = i.type == 'stock'?true:false

            return (
                <View style={[styles.orderSelect,{ backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }]} key={"Selected"+index}>
                    <View style={{flex:4,padding:5}}>
                        <Text>{i.name}</Text>
                        {Stock &&
                          <View style={{flexDirection:'row'}}> 
                            <Text>stock : </Text>
                            <Text style={{color:COLORS.Link}}>{i.stock}</Text>
                          </View>
                        }
                    </View>
                    <View style={{width:50,marginRight:5}}>
                        {Stock && <TextInput onChangeText={(val)=>qtyChanger(index,val,i.stock)} value={i.qty}/>}
                    </View>
                    <View style={{width:100}}>
                        <TextInput onChangeText={(val)=>onChangePrice(index,val)} value={i.amount}/>
                    </View>
                    <TouchableOpacity onPress={()=>RemoveProducts(index)} style={{width:20}}>
                        <MaterialIcons name='close' size={18} color={COLORS.Error} />
                    </TouchableOpacity>
                </View>
            )
        })

        return (
            <View>
                {aaa()}
                <View style={{alignItems:'center',flexDirection:'row'}}>
                    <TouchableOpacity style={styles.additem} onPress={()=> setModalProducts(true)}>
                      <MaterialIcons name='add-box' size={22} color={COLORS.Primary} />
                      <Text style={{color:COLORS.Primary,marginLeft:2}}>ADD ITEM</Text>
                    </TouchableOpacity>
                    <Text style={{fontWeight:'bold',fontSize:18}}>RM{selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)}</Text>  
                </View>
            </View>
        )
    }

    const DeliveryInput = (data) => {
      if(!data){return <TouchableOpacity onPress={()=>setDelivery(true)} style={styles.deliverybtn} ><Text style={styles.deliverytxt}>+ Add Delivery Address</Text></TouchableOpacity>}

      return <TextInput multiline={true}  onChangeText={onDelivery}  title="Delivery Address" value={deliveryaddress} returnKeyType="done" numberOfLines = {4}/>

    }


    const OrderAddPopup = (data) => {
        return(
                <View style={{flex:1}}>
                        {headerProducts()}
                        {ProductSelected(data)}
                        <View >
                                <TextInput onChangeText={onName} maxLength={60} title="Name" value={name} autoCapitalize="true" errclear={true}/>
                                <TextInput onChangeText={onCompany} maxLength={100} title="Company" value={company} autoCapitalize="true" errclear={true}/>
                                {MobileInput()}
                                <TextInput multiline={true}  onChangeText={onAddress}  value={address} returnKeyType="done" numberOfLines = {4}/>
                                {DeliveryInput(deliveryadd)}
                                <TextInput multiline={true}  onChangeText={onRemark}  title="Remark" value={remark} returnKeyType="done" numberOfLines = {2}/>
                                <Error error={error}/>
                                {!isLoading?<Button title={'ADD'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
                        </View>
                </View>
        )
    }

    const MobileInput = () => {
      return (
        <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
          <View style={{flex:1}}>
            <TextInput onChangeText={onMobile} maxLength={18} title="Mobile" value={mobile} autoCapitalize="none" errclear={true}/>
          </View>
        </View>
      )
    }



    const headerProducts = () => {
      return (
        <View style={{flexDirection:'row',padding:2}}>
          <View style={{flex:1}}><Text style={styles.th}>ID</Text></View>
          <View style={{width:50}}><Text style={styles.th}>QTY</Text></View>
          <View style={{width:100}}><Text style={styles.th}>PRICE</Text></View>
          <View style={{width:20}}></View>
        </View>
      )
    }



    return (
      <Modal title={modalProducts?'Choose Product':'Add Quotation'} icon={'ios-receipt'} visible={visible} setVisible={clear} headColor={'#428ff5'}>

            {modalProducts?<Products setValue={(val)=>SelectProduct(val)} selected={selected} products={productList}/>:OrderAddPopup(selected)}

      </Modal>
    )

}

const styles = StyleSheet.create({
    container:{
      flexDirection:'row',
      flexGrow:1,
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },

      orderSelect:{
        padding:5,
        flexDirection:'row',
        borderBottomWidth:1,
        borderStyle:'dashed',
        borderColor:'#cecece'
      },
      additem:{
        borderWidth:1,
        padding:5,
        marginVertical:5,
        flex:1,
        borderStyle:'dashed',
        marginRight:10,
        borderRadius:5,
        borderColor:COLORS.Primary,
        alignItems:'center',
        alignContent:'center',
        flexDirection:'row',
        justifyContent:'center'
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Primary,
        fontSize:14
      },
      th:{
        fontWeight:'600',
        color:COLORS.Primary
      },
  });
  