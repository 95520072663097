import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';
import Table from './table';
import { ScrollView } from 'react-native-gesture-handler';



export default function Cat_List(props) {

  const {data,sub,category,picked,setPicked} = props

  const [activeType, SetActive] = useState(null);

  
  const [list, setList] = useState([]);

  useEffect(() => {

      Categorised(data)


  }, [category,data]);

  function Categorised(data){
    const result = Object.values(data.reduce((acc, x) => {

      var GetLastIndex = x.delivery.lastIndexOf(",");
      const  State = category == 'State' ? x.delivery.substring(GetLastIndex + 1).trim() : x.name

        acc[State] = [...(acc[State] || []), x ];
        acc[State]['cat'] = State
        return acc;
    }, {}));

    setList(result)

  }




  function UpdateData(item){
    const dataArray = [ ...data]
    const result = dataArray.map((obj) => obj.id === item.id ? item : obj);
    Categorised(result)
  }



  const ListItem = (data,list) => {
    if(activeType !== data) return null
        return(
          <Table 
          list={list}
          update={UpdateData}
          setPicked={setPicked}
          picked={picked}
        /> 
      )
  }

  function Expand(key){
    if(key == activeType){
      SetActive(null)
    }else{
      SetActive(key)
    }
  }
  

      return(
         <ScrollView style={[styles.container]}>
             {
                list.map(key => {
                  const Exp = key.cat !== activeType ? '+' : '-'
                  return (
                    <View key={key.cat}>
                      <TouchableOpacity style={styles.box} onPress={()=>{Expand(key.cat)}}>
                          <View style={{flex:1}}><Text style={styles.text}> {key.cat + ' ('+key.length+')'}</Text></View>
                          <View style={{paddingHorizontal:5}}><Text style={styles.text}>{Exp}</Text></View>
                      </TouchableOpacity>
                      {ListItem(key.cat,key)}
                    </View>
                  ); 
                })
             }
         </ScrollView>
     )





}

const styles = StyleSheet.create({
  container:{
    flex: 1,
    paddingBottom:100
  },
  box:{
    marginBottom:10,
    padding:10,
    backgroundColor:'#e0e0e0',
    borderRadius:5,
    flexDirection:'row'
  },
  text:{
    fontSize:16,
    color:'#777'
  }
});
