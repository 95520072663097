import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,Image} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import COLORS from "../assets/component/colors";
import AddModal from "./add";
import { ScrollView } from 'react-native-gesture-handler';


export default function ListDown(props) {

    const {list,active,set,push,Large} = props


    const [drop, setDrop] = useState(false);
    const [addModal, setAddModal] = useState(false);
 

    const AddBtn = () => {
      return (
  
        <TouchableOpacity style={styles.addbx} onPress={()=>{[setAddModal(true)]}}>
            <Ionicons name='add' size={14} color={'#fff'} />
            <Text style={{fontSize:14,color:'#fff'}}>CREATE</Text>
        </TouchableOpacity>
  
      )
    }


    const ListItem = () => {
      if(!list.length) return null
        return(
            <View style={styles.listbx}>
                  <Header/>
                  <ScrollView style={{maxHeight:100}} showsHorizontalScrollIndicator={false} indicatorStyle='black'>
                      {list.map((key,index) => {
                        const Name = key.display_name?key.display_name:key.name
                        if(!Name) return null
                        return (
                            <TouchableOpacity key={key.id} style={[styles.list]} onPress={()=>{set(key),setDrop(false)}}>
                                <Text numberOfLines={1} style={[styles.text,{color:key.id == active.id?COLORS.Link:'#777',fontWeight:key.id != active.id && '300'}]}>{Name}</Text>
                                <Image style={styles.tinyLogo} source={{ uri: key.logo}}/>
                            </TouchableOpacity>
                        ); 
                        })}
                  </ScrollView>
                        <AddBtn/>
                        {addModal && <AddModal add={push} close={()=>setAddModal(false)}/>}
            </View>
        )
    }
    


    const Header = () => {
      return (
        <View style={{borderBottomWidth:0.5,borderColor:'#eee',paddingBottom:5,alignItems:'flex-end'}}>
          <Text style={styles.txtacc}>Choose Account</Text>
        </View>
      )
    }
    

    if(!list){
      return <AddModal add={push} close={()=>setAddModal(false)}/>
    }


        return (
            <View style={{marginVertical:5}}>
                        <TouchableOpacity style={styles.box} onPress={()=>setDrop(!drop)}>
                            {Large && <Text style={[styles.text,{flex:1,color:'#777'}]}> {active.name} </Text>}
                            <Ionicons name='chevron-down' size={18} color={'#777'} />
                        </TouchableOpacity>
                {drop?ListItem():null}
             </View>
        )




}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:14,
    textAlign:'right'
  },
  txtacc:{
    fontWeight:'400',
    color:'#777'
  },
  box:{
    padding:10,
    borderRadius:5,
    flexDirection:'row',
  },
  list:{
    flexDirection:'row',
    padding:5,
    justifyContent:'flex-end',
  },
  listbx:{
    padding:10,
    width:150,
    position:'absolute',
    top:50,
    right:10,
    borderRadius:5,
    backgroundColor:'#fff',
    shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 8,
      },
      shadowOpacity: 0.46,
      shadowRadius: 11.14,

      elevation: 17,
  },
  addbx:{
    backgroundColor:COLORS.Link,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:4,
    padding:2,
  },
  tinyLogo:{
    width:20,
    height:20,
    marginLeft:5
  }

});
