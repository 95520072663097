import React, { useState,useEffect } from 'react';
import { getFirestore,query,getDocs,collection,getDoc,doc} from 'firebase/firestore';
import * as ImageManipulator from 'expo-image-manipulator';
import {getStorage,getDownloadURL ,ref,uploadBytesResumable,uploadString  } from "firebase/storage";
import {GETACC} from './getacc';



export const UploadData = async (id,file,route) => {

  if(file.type){
      return await handleUpload(id,file,route)
  }else{
      return await handleUploadImage(id,file,route)
  }

};





async function handleUpload(id,file,route) {

  const storage = getStorage();

  const user = await GETACC();
  const storageRef = ref(storage,`/files/${user.id}/${route}/${id}`)
  const uploadTask = uploadBytesResumable(storageRef, file);
  await uploadTask;
  const FileUrl = await getDownloadURL(uploadTask.snapshot.ref);
  return FileUrl
}



async function handleUploadImage(id,file,route) {

  const storage = getStorage();
  
  const user = await GETACC();
  const storageRef = ref(storage,`/files/${user.id}/${route}/${id}`)
  

  return uploadString(storageRef, file, 'data_url').then((snapshot) => {

      return getDownloadURL(storageRef).then((url) => {
        return url
      });
  }); 
}
