import React, { useState,useEffect } from 'react';
import { getFirestore,onSnapshot,doc} from 'firebase/firestore';
import { StyleSheet,View, ActivityIndicator} from 'react-native';
import moment from 'moment';
import {GETACC} from '../../../assets/js/getacc';
import {GetCreditDebits,GetCreditDebitsNext } from "../../../assets/js/firebase";
import Tab from './tab';
import Footer from './footer';
import AccDetails from './accdetails';
import Table from './table';


export default function AccountScreen({route,navigation}) {

  const LimitPerload = 12

  const DafaultTab = {credit:[],debit:[]}


  const db = getFirestore();
  const [year, setYear] = useState(moment().format("YYYY"));
  const [current, setCurrent] = useState();
  const [listBase, setListBase] = useState(DafaultTab);
  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [lastVisible , setLastVisible ] = useState(DafaultTab);
  const [activeAcc, setActiveAcc] = useState(null);

  useEffect(() => {
    if(route.params){
      if(route.params.delete){
        RemoveData(route.params.delete)
      }
      if(route.params.tab){
        setTabActive(route.params.tab)
      }
    }
  }, [route.params]);
  
  useEffect(async () => {
    const getAccc = await GETACC()
    setActiveAcc(getAccc)
  }, []);

  useEffect(() => {
    if(listBase[tabactive].length == 0 && activeAcc && year){
      getList(tabactive,year);
      setIsloading(true)
    }
  }, [tabactive,activeAcc]);





  useEffect(() => {
    if(activeAcc && year){
      const unsub = onSnapshot(doc(db, "account/"+activeAcc.id+'/acc/'+year), (doc) => {
        setCurrent(doc.data())
      });
  
      return () => unsub()

    }

    
  }, [activeAcc,year]);




  async function RemoveData(id){
    const filteredData = listBase['debit'].filter(item => item.id !== id);
    setList({...listBase, ['debit']:filteredData});
  }


  async function getList(active,year){
    console.log("LOADDD LIST")
      const ListOrders = await GetCreditDebits(active,LimitPerload,year)
      setLastVisible({...lastVisible, [active]:ListOrders.last});

      SetListData(active,ListOrders.list)
      setIsloading(false)

  };

  function SetListData(active,data){
    setList({...list, [active]:data});
    setListBase({...listBase, [active]:data});
  } 




  async function nextList(active,last){

    console.log("load More")

    if(!last[active]) return null

    const ListOrders = await GetCreditDebitsNext(active,last[active],LimitPerload,year)

    if(ListOrders){

      setLastVisible({...lastVisible, [active]:ListOrders.last});

      const dataArray = [ ...list[active], ...ListOrders.list]

      SetListData(active,dataArray)

      setIsloading(false)
    }
  


};



  function PushItem(data){
    const dataArray = [ ...list[data.type]]
    dataArray.unshift(data)
    setList({...list, [data.type]:dataArray});

  }

  if(!activeAcc){
    return <ActivityIndicator size={'small'}/>
  }

  function YearSelect(data){
    setIsloading(true)
    setYear(data)
    getList(tabactive,data);
  }



  return (
    <View style={{flex:1}}>
      <AccDetails data={current} year={year} setyear={YearSelect} />
      <View style={styles.container}>
          <View style={styles.tabBx}>
            <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab}/>
            <View style={{flex:1,borderBottomWidth:1,borderColor:'#ccc'}}/>
          </View>
          <Table 
            tab={tabactive} 
            setList={setList} 
            list={list} 
            next={()=>nextList(tabactive,lastVisible)} 
            loading={isLoading}
            nav={navigation}
            />
      </View>
      <Footer add={PushItem} tab={tabactive} current={current}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
