import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,Text, ActivityIndicator,TouchableOpacity} from 'react-native';
import {GetAllDocs,GetItemDetails,DeleteStaffLeave} from "../../../../../assets/js/firebase";
import moment from 'moment';
import Ionicons from '@expo/vector-icons/Ionicons';
import {SearchBox,Button,ListDownoverlay} from "../../../../../assets/component";
import {AdvYearSelector} from '../../../../../assets/component/momentselect';
import Tab from './tab';
import AddDocument from './add';
import Table from './table';
import Details from './details';
import { ScrollView } from 'react-native-gesture-handler';


export default function LeaveScreen({route,navigation}) {
  const {pid} = route.params;

  const isMounted = useRef();

  const DafaultTab = {annual:[],medical:[]}




  const [year, setYear] = useState(moment().format("YYYY"));
  const [item, setItem] = useState(null);
  const [total, setTotal] = useState(0);
  const [medical, setMedical] = useState(0);
  const [listBase, setListBase] = useState(DafaultTab);
  const [list, setList] = useState(DafaultTab);
  const [isLoading, setIsloading] = useState(false);
  const [statusloading, setStatusloading] = useState(false);
  const [picked, setPicked] = useState([]);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      if(listBase[tabactive].length == 0 || year){
        setIsloading(true)
        getList(year,tabactive);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [tabactive,year]);

  useEffect(() => {
   GetStaff()
  }, []);

  async function GetStaff() {
    const ProductsDetail = await GetItemDetails(pid,'staff')
    if(ProductsDetail){
      setItem(ProductsDetail)
    }
    
  }
  


  async function getList(year,category){
      const ListItem = await GetAllDocs('staff/'+pid+'/leave/'+year+'/'+category)
      if(isMounted.current){
        const Total = ListItem.reduce((n, {days}) => n + days, 0)
        if(tabactive == 'annual'){setTotal(Total)}else{setMedical(Total)}
        setList({...list, [category]:ListItem});
        setListBase({...listBase, [category]:ListItem});
        setIsloading(false)
      }
  };
  


  function PushItem(data){
    const Year = moment(data.date,"DD-MM-YYYY").year()
    setYear(Year)
    const dataArray = [ ...list]
    dataArray.unshift(data)
    setList(dataArray);

  }

  function StockNav(data){
    navigation.navigate('stock', {pid:data.id})
  }



async function Delete(data){

  if (confirm('Are you sure you want to delete ?')) {
    setStatusloading(true)
    await DeleteStaffLeave(pid,data)
    UpdateStatus(data)
  } else {
    console.log('Cancel Delete');
  }
}


function UpdateStatus(data){

  const Pending = list[tabactive].filter(item => !data.some(itemToBeRemoved => itemToBeRemoved.id === item.id))
    setList({...list,[tabactive]:Pending});
    setTotal(Pending.reduce((n, {days}) => n + days, 0))
    setPicked([])
    setStatusloading(false)
}

const BackBtn = () => {
  return (
    <TouchableOpacity style={styles.backbtn} onPress={()=>navigation.goBack()}>
      <Ionicons name={'chevron-back-outline'} size={28} color='#4a8eb5' />
      <Text>BACK</Text>
    </TouchableOpacity>

  )
}


const Footer = () => {
  if(!picked.length) return null

  return (
    <View style={{flexDirection:'row',padding:10,backgroundColor:'#eee',position:'absolute',width:'100%',bottom:0}}>
      <View style={{flex:1}}>
          <View><Text>TOTAL : {picked.length}</Text></View>
      </View>
      <Button title={'Delete'} onPress={()=> Delete(picked)} color={'#fc3063'} style={{marginRight:10}} icon={'close-outline'}/>
    </View>
  )
}


if(!item){
  return <View style={styles.center}><Text>Item not found</Text></View>
}




  return (
    <View style={{flex:1}}>
    <ScrollView style={styles.container}>
      <View>
      <BackBtn/>
      <Details item={item} days={tabactive == 'annual'?total:medical} total={tabactive == 'annual'?item.annual_leave:item.medical_leave}/>
      </View>
      <View style={{flexDirection:'row',zIndex:99}}>
      <AdvYearSelector setYear={setYear} year={year} adv={1} prv={1} style={{marginRight:10}}/>
      </View>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} list={list}/>
        <View style={{flex:1,borderBottomWidth:0.5,borderColor:'#ccc'}}/>
        <AddDocument add={PushItem} pid={pid} LeaveCat={tabactive}/>
        <SearchBox set={(val)=>setList(val)} products={list} filters={['date']}/>
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        loading={isLoading}
        statusloading={statusloading}
        nav={StockNav}
        setPicked={setPicked}
        picked={picked}
      />




    </ScrollView>
    <Footer/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap',
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  center:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    flex:1
  },
  backbtn:{
    borderRadius:5,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  }
});
