import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView, ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../../../assets/component/textinput"
import Modal from "../../../../assets/component/modal";
import Button from "../../../../assets/component/button";
import COLORS from "../../../../assets/component/colors";
import {AddSalary,UpdateSalary} from "../../../../assets/js/firebase";
import { TouchableOpacity } from 'react-native-gesture-handler';
import COLUMNS from "./col";


export default function GenerateSalary(props) {

    const {add,data,clear} = props


    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState("");
    const [listId, setListID] = useState(null);
    const [member, setMember] = useState({});
    const [sumTotal, setSumTotal] = useState(0);

    const GenStat = data && data.status == 'generate' ? true:false
    const UpdateStat = data && data.status == 'pending'? true:false
    const PaidStat = data && data.status == 'paid' ? true:false

    const onChangeHandler = (e,name) => {
        const Value = e.target?e.target.value:e
        setMember((prevState) => ({ ...prevState, [name]: Value }));
        setSumTotal(SUMGross({...member, [name]: Value }))
    };



    useEffect(() => {
        if(data){
            setIsloading(false)
            setListID(data.id)
            delete data.id
            setMember(data)
            setSumTotal(SUMGross(data))
        }

    }, [data]);
    







     async function Validate(){

        setError('')



        if(sumTotal){
                setIsloading(true)
                
                member['status'] = 'pending'
                member['total'] = sumTotal
                delete member.type
                const Route = 'staff/'+member.staff+'/salary/'+data.month

                const DocId = GenStat?await AddSalary(member,Route):await UpdateSalary(member,Route,listId)
                if(DocId){
                    add(DocId)
                }else{
                    console.log("ERROR GENERATE SALARY")
                }
        }
    
    }


    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


        
    function SUMGross(data){
        const SUMobj = ['basic','income_others']
        const Deduction = ['kwsp2','socso2','eis2','pcb','deduction_others']
        var SUM = 0
        SUMobj.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM += parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM += parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        Deduction.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM -= parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM -= parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        return SUM
    }

    const SumTxt = () => {
        return (
            <View style={{flex:1,flexDirection:'row',alignItems:'baseline'}}>
                <Text style={{fontSize:12}}>Nett Amount (RM) : </Text>
                <Text style={{fontSize:18,fontWeight:'600'}}>{sumTotal.toFixed(2)}</Text>
            </View>
        )
    }


    const Footer = () => {
        if(PaidStat) return <SumTxt/>

        const BtnName = GenStat && 'GENERATE' ||  UpdateStat && 'UPDATE'
        return(
            <View style={{flexDirection:'row',marginTop:10}}>
                <SumTxt/>
                {!isLoading?<Button title={BtnName} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
            </View>
        )
    }


if(!member || !data) return null

    
        return (
            <Modal title={'Generate Salary'} icon={'receipt'} visible={data?true:false} setVisible={()=>clear(null)}>
                <ScrollView >
                                <View style={{flexDirection:'row',justifyContent:'flex-end'}}><Text style={styles.mthtxt}>{data.month}</Text></View>
                                <COLUMNS data={member} change={onChangeHandler} paid={PaidStat}/>
                                <Error error={error}/>
                                <Footer/>
                </ScrollView>
            </Modal>
        )

    




}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      mthtxt:{
        fontSize:18,
        color:COLORS.Primary,
        fontWeight:'500'
      },
      addother:{
        padding:3,
        alignContent:'center',
        alignItems:'center',
      }
  });
  