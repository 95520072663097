import React, { useState,useEffect,useRef } from 'react';
import { View,Text,StyleSheet} from 'react-native';
import "./styles.css";
import Logo from '../logo64';
import { useReactToPrint } from "react-to-print";

export default function LabelPrint(props) {

  const {item} = props


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



      return (
        <>
          <div className="App" ref={componentRef}>
          <div class="sample">
              <style>{`@media print {@page {size: 25mm 15mm;}}`}</style>

              <div class="head2">
                  <div class="logo2"><img class="img3" src={Logo} alt="Logo" /></div>  
                  <div class="code2">G-11</div>  
              </div>
              <div class="main2">
                <div class="small">CODE</div>
              </div>
            </div>
          </div>
          <button onClick={handlePrint}>Print</button>
        </>
      );

}

const styles = StyleSheet.create({
  image:{
      width:100,
      height:100
  },
  btn:{
    padding:10,
    borderRadius:5,
    borderColor:'#aaa',
    borderWidth:1,
    marginBottom:2
  }
});

