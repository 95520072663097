import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../assets/component/textinput";
import Modal from "../../assets/component/modal";
import Button from "../../assets/component/button";
import COLORS from "../../assets/component/colors";
import DatePicker from "../../assets/component/datepicker";
import {UpdateOrder,GetAllDocs} from "../../assets/js/firebase";
import Products from './add/productslist'

export default function addModal(props) {

    const {clear,data,update} = props


    const [modalProducts, setmodalProducts] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [name, onName] = useState("");
    const [company, onCompany] = useState("");
    const [mobile, onMobile] = useState("");
    const [address, onAddress] = useState("");
    const [deliveryaddress, onDelivery] = useState("");
    const [remark, setRemark] = useState("");
    const [deliveryadd, setDelivery] = useState(false);
    const [dated, onDated] = useState("");
    const [error, setError] = useState("");
    const [productList, setProductList] = useState(null);
    const [selectedPrev, setSelectedPrev] = useState([]);
    const [selected, setSelected] = useState([]);


    useEffect(() => {
        if(data){
            setDelivery(data.delivery?true:false)
            setSelectedPrev(data.products)
            setSelected(data.products)
            onName(data.name)
            onCompany(data.company)
            onDated(data.date)
            onAddress(data.address)
            onDelivery(data.delivery)
            setRemark(data.remark)
            onMobile(data.mobile)

        }
    }, [data]);

    useEffect(() => {
      const getDatas = async () => {
        const ListProduct = await GetAllDocs('products','active')
        setProductList(ListProduct)
      }
      getDatas()
    }, []);
    


    const onChangePrice = (index,val) => {
      var Amount = val.replaceAll(",", "");
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                amount: Amount?Amount.replace(/^0+/, ''):'0'
              };
            } else {
              return userScore;
            }
          })
        );
      };

      const onChangeQty = (index,val) => {
        const qty = val?val:0
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                qty: parseInt(qty)
              };
            } else {
              return userScore;
            }
          })
        );
      };



    async function Validate(){
        setError('')

        var valid = Validation(name,onName);

        var valid2 = Validation(dated,onDated);

        var validMobile = ValidatePhone(mobile)

        if(!validMobile){
          onMobile({error:"Invalid Mobile Phone no"})
        }

   
        if(valid && valid2 && validMobile){
          setIsloading(true)
          const Added = await UpdateOrder({
            'name':name,
            'company':company,
            'mobile':validMobile,
            'address':address,
            'delivery':deliveryaddress,
            'remark':remark,
            'amount':selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2),
            'date':dated,
            'products':selected
          },data.id,selectedPrev)
          if(Added){
            update(Added)
          }
          ClearState()
        }

    }

    function Validation(input,change){
        if(!input || input.error){
            change({error:"Invalid Value"});
            return false
        }
        return true
    }


    function ClearState(){
        setIsloading(false)
        setSelected([])
        clear(null)
    }



    function ValidatePhone(data) {
      const Input = !data.error && data ?  data.replace(/\D/g,'').replace(/^6/, '') : null;
      
      if(Input.length >= 10 && Input.length <= 11 && Input.charAt(0) === '0'){
          return '+6'+Input;
      }
      return null;
    }

    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


    function RemoveProducts(index){
        const temp = [...selected];
        temp.splice(index, 1);
        setSelected(temp);
        if(!temp.length){
            setmodalProducts(true)
        }
    }

    const headerProducts = () => {
      return (
        <View style={{flexDirection:'row',padding:2}}>
          <View style={{flex:1}}><Text style={styles.th}>ID</Text></View>
          <View style={{width:50}}><Text style={styles.th}>QTY</Text></View>
          <View style={{width:100}}><Text style={styles.th}>PRICE</Text></View>
          <View style={{width:20}}></View>
        </View>
      )
    }


    const ProductSelected = (item) => {

      if(data && data.status !== 'pending') return null

      function qtyChanger(i,val,stock){
        if(val <= stock){
          onChangeQty(i,val,stock)
        }
      }

      const aaa = () => item.map((i, index) => {

          const Stock = i.type == 'stock'?true:false

          return (
              <View style={[styles.orderSelect,{ backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }]} key={"Selected"+index}>
                  <View style={{flex:4}}>
                      <Text>{i.name}</Text>
                      {Stock &&
                        <View style={{flexDirection:'row'}}> 
                          <Text>stock : </Text>
                          <Text style={{color:COLORS.Link}}>{i.stock}</Text>
                        </View>
                      }
                  </View>
                  <View style={{width:50,marginRight:5}}>
                      {Stock && <TextInput onChangeText={(val)=>qtyChanger(index,val,i.stock)} value={i.qty}/>}
                  </View>
                  <View style={{width:100}}>
                      <TextInput onChangeText={(val)=>onChangePrice(index,val)} value={i.amount}/>
                  </View>
                  <TouchableOpacity onPress={()=>RemoveProducts(index)} style={{width:20}}>
                      <Ionicons name='close' size={18} color={COLORS.Error} />
                  </TouchableOpacity>
              </View>
          )
      })

      return (
          <View>
              {aaa()}
              <View style={{alignItems:'center',flexDirection:'row'}}>
                  <TouchableOpacity style={styles.additem} onPress={()=> setmodalProducts(true)}>
                    <Ionicons name='add-box' size={22} color={COLORS.Primary} />
                    <Text style={{color:COLORS.Primary,marginLeft:2}}>ADD ITEM</Text>
                  </TouchableOpacity>
                  <Text style={{fontWeight:'bold',fontSize:18}}>RM{selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)}</Text>  
              </View>
          </View>
      )
  }

    const DeliveryInput = (data) => {
      if(!data){return <TouchableOpacity onPress={()=>setDelivery(true)} style={styles.deliverybtn} ><Text style={styles.deliverytxt}>+ Add Delivery Address</Text></TouchableOpacity>}

      return <TextInput multiline={true}  onChangeText={onDelivery}  title="Delivery Address" value={deliveryaddress} returnKeyType="done" numberOfLines = {4}/>

    }

    const OrderAddPopup = (data) => {
        return(
                <View style={{flex:1}}>
                          {ProductSelected(data)}
                          <View>
                                <TextInput onChangeText={onName} maxLength={60} title="Name" value={name} autoCapitalize="true"errclear={true}/>
                                <TextInput onChangeText={onCompany} maxLength={100} title="Company" value={company} autoCapitalize="true"errclear={true}/>
                                <TextInput onChangeText={onMobile} maxLength={22} title="Mobile" value={mobile} autoCapitalize="none" errclear={true}/>
                                <TextInput multiline={true}  onChangeText={onAddress}  title="Address" value={address} returnKeyType="done" numberOfLines = {4}/>
                                {DeliveryInput(deliveryadd)}
                                <TextInput multiline={true}  onChangeText={setRemark}  title="Remark" value={remark} returnKeyType="done" numberOfLines = {2}/>
                                <DatePicker set={onDated} value={dated}/>
                                <Error error={error}/>
                                {!isLoading?<Button title={'UPDATE'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
                        </View>
                </View>
        )
    }

    function SelectProduct(data) {
      setSelected(data)
      setmodalProducts(false)
    }


    const Popup = () => {
        return (
                <Modal title={modalProducts?'Choose Product':'Update Order'} icon={'ios-receipt'} visible={data?true:false} setVisible={()=>ClearState()}>

                        {modalProducts?<Products setValue={(val)=>SelectProduct(val)} selected={selected} products={productList}/>:OrderAddPopup(selected)}

                </Modal>
        )
    }
    


    return(
        <View>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      additem:{
        borderWidth:1,
        padding:5,
        marginVertical:5,
        flex:1,
        borderStyle:'dashed',
        marginRight:10,
        borderRadius:5,
        borderColor:'#777',
        alignItems:'center',
        alignContent:'center'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      additem:{
        borderWidth:1,
        padding:5,
        marginVertical:5,
        flex:1,
        borderStyle:'dashed',
        marginRight:10,
        borderRadius:5,
        borderColor:COLORS.Primary,
        alignItems:'center',
        alignContent:'center',
        flexDirection:'row',
        justifyContent:'center'
      },
  });
  