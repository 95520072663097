import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import moment from 'moment';
import {CheckBox} from "../../../../../assets/component";

export default function Table(props) {

  const {tab,setList,list,loading,statusloading,setPicked,picked} = props


  const [sortasc, setSortAsc] = useState(true);


  useEffect(() => {
    setPicked([])

  }, [tab]);


  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  






const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {tab == 'pending' && <View style={{width:30}}></View>}
          {Th('Name',2,true)}
          {Th('Date',2)}
          {Th('Amount',2)}
          <View style={{width:20}}/>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const RemoveLoader = (id) => {
      if(picked.find(x => x.id == id) && statusloading){
        return (
          <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
            {Loader(true)}
          </View>
        )
      }

      return null
    }



    const Thickbox = (data) => {
      if(tab != 'pending') return null
      var containsId = picked.find(x => x.id === data.id);
      return (
          <CheckBox value={containsId} onValueChange={()=>SelectId(data,containsId)} />
      )
    }


    function SelectId(data,found){
      if(found){
        const filteredData = picked.filter(item => item.id !== data.id);
        setPicked(filteredData);
      }else{
        setPicked([...picked, data])
      }
    }

    const ImageBtn = (link) => {
      if(!link) return null
      return (
        <TouchableOpacity style={{width:20}} onPress={()=>window.open(link, '_blank')}>
          <Ionicons name='image' size={22} color={'#04b8a9'} />
        </TouchableOpacity>
      )
    }

    const RenderItem = ({item,index}) => {
      return(
        <View>
          {RemoveLoader(item.id)}
        <View style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
              <View style={{flexDirection:'row',flex:1}}>
                  {Thickbox(item)}
                  <View style={{flex:2}}>
                    <Text style={styles.item}>{item.name}</Text>
                    <Text style={styles.category}>{item.category}</Text>
                    <Text style={styles.created}>{ReformatTime(item.createdAt)}</Text>
                  </View>
                  <View style={{flex:2}}><Text style={styles.item}>{item.date}</Text></View>
                  <View style={{flex:2}}><Text style={styles.amt}>RM{item.amount.toFixed(2)}</Text></View>
                  {ImageBtn(item.image)}
              </View>
        </View>
        </View>
      )
  }





  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY HH:MM');
    return format
  }








    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
    marginBottom:100
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  category:{
    marginRight:5,
    color:'#04b8a9',
    fontWeight:'500',
    fontSize:14
  },
  created:{
    marginRight:5,
    color:'#aaa',
    fontWeight:'300',
    fontSize:10
  },
  amt:{
    marginRight:5,
    color:'#04b8a9',
    fontWeight:'500'
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    fontWeight:'bold',
    color:'#e07c00',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  optionbtn:{
    padding:5
  },

});
